import _ from 'lodash'
import { createSelector } from 'reselect'

// An index of all avatars on pending purchase orders
// keyed by packageId ... { pkgId: { ...avatar } }
// NOTE: the avatars here should have their values dereferenced
export const purchasedAvatars = createSelector(
  (state) => state.project.entities,
  (state) => state.avatars.avatars,
  (projects, avatars) => {
    if (!avatars || Object.values(avatars).length < 1) return {}

    const dereferencedAvatars = Object.values(avatars).map((avatar) => {
      if (avatar.projectId) {
        const project = projects[avatar.projectId]
        const sizeSpec = project && project.sizeSpecs[avatar.specId]

        return {
          ...avatar,
          project,
          sizeSpec,
        }
      } else {
        return avatar
      }
    })

    // index the avatars by their packageId
    return _.keyBy(dereferencedAvatars, 'packageId')
  }
)

// An index of all avatars on pending purchase orders
// keyed by packageId ... { pkgId: { ...avatar } }
// NOTE: the avatars here should have their values dereferenced
export const purchasePendingAvatars = createSelector(
  (state) => state.project.entities,
  (state) => state.avatars.orders,
  (projects, orders) => {
    if (!orders || orders.length < 1) return {}

    // an avatar is pending purchase when it's listed on an order with a status = 'NEW'
    const pendingAvatars = orders
      .filter((order) => order.status === 'NEW')
      .map((order) => order.avatars)
      .flat()
      .map((avatar) => {
        if (avatar.projectId) {
          const project = projects[avatar.projectId]
          const sizeSpec = project && project.sizeSpecs[avatar.specId]

          return {
            ...avatar,
            project,
            sizeSpec,
          }
        } else {
          return avatar
        }
      })

    // index the avatars by their packageId
    return _.keyBy(pendingAvatars, 'packageId')
  }
)
