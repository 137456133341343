import React from 'react'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'

import FitCoverageOverview from 'components/size_explorer/fit_coverage/Overview'
import SizeCoverage from 'components/size_explorer/fit_coverage/SizeCoverage'
import ScatterPlots from 'components/size_explorer/fit_coverage/ScatterPlots'
import SizeTable from 'components/size_explorer/fit_coverage/size_chart/SizeTable'

import { scatterplotSelector } from 'selectors/charts'
import {
  fitCoverageTotalsSelector,
  sizeDataSelector,
} from 'selectors/fit_coverage'

const FitCoverage = ({
  selectedSpec,
  fitCoverage,
  fitCoverageScatterplots,
  fitCoverageSizeData,
  fitCoverageTotals,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={6}>
            <Card style={{ padding: '20px 32px' }}>
              <FitCoverageOverview
                fitCoverageTotals={fitCoverageTotals}
                isLoading={fitCoverage.isFetching}
              />
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card style={{ padding: '20px 32px' }}>
              <SizeCoverage
                isLoading={fitCoverage.isFetching}
                sizeData={fitCoverageSizeData}
              />
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <ScatterPlots
          primaryMeasurements={selectedSpec.primaryMeasurements}
          scatterplots={fitCoverageScatterplots}
          sizeData={fitCoverageSizeData}
        />
      </Grid>

      <Grid item xs={12}>
        {fitCoverage.isFetching ? (
          <Paper>
            <Grid
              container
              justify="center"
              alignItems="center"
              direction="column"
              style={{ height: 200 }}
            >
              <CircularProgress size={32} color="primary" disableShrink />
            </Grid>
          </Paper>
        ) : (
          <SizeTable
            fitCoverageTotals={fitCoverageTotals}
            primaryMeasurements={selectedSpec.primaryMeasurements}
            sizeData={fitCoverageSizeData}
          />
        )}
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  project: state.project.entities[state.project.selected],
  selectedSpecId: state.project.selectedSizeSpecId,
  fitCoverage: state.fitCoverage,
  fitCoverageScatterplots: scatterplotSelector('fitCoverage')(state),
  fitCoverageSizeData: sizeDataSelector(state),
  fitCoverageTotals: fitCoverageTotalsSelector(state),
})

export default connect(mapStateToProps)(FitCoverage)
