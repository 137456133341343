import { createSelector } from 'reselect'

import { colorByMorphotype, unflattenSizeSpec } from 'lib/size_definition'

const sizeSpecSizesSelector = (state) =>
  state.project.entities[state.project.selected].sizeSpecs[
    state.project.selectedSizeSpecId
  ].sizeDefs

export const fitCoverageTotalsSelector = createSelector(
  (state) => state.fitCoverage.data,
  (data) => {
    return {
      totalCount: data.total_count,
      totalCoverage: Math.round(data.total_coverage * 1000) / 10,
      sizes: data.sizes,
    }
  }
)

// get single size spec, map through sizespec.sizes if isArray, otherwise, make it an array (w/ sorting)
export const sizeDataSelector = createSelector(
  sizeSpecSizesSelector,
  fitCoverageTotalsSelector,
  (sizeSpecSizes, fitCoverage) => {
    const { sizes } = fitCoverage

    const fitCoverageMap = {}

    if (sizes) {
      sizes.forEach(({ size, count, coverage }) => {
        fitCoverageMap[size] = { count, coverage }
      })
    }

    return colorByMorphotype(
      sizeSpecSizes.map((spec) => ({
        size: spec.size, // ex: 1, 2, 3, 4
        measurements: unflattenSizeSpec(spec), // ex: { chestBustGirth: { min: 32, max: 34 }, hipSeatGirth: { min: 30, max: 32 } }
        population: fitCoverageMap[spec.size]
          ? fitCoverageMap[spec.size].count
          : 0,
        fitCoverage: fitCoverageMap[spec.size]
          ? Math.round(fitCoverageMap[spec.size].coverage * 1000) / 10
          : 0,
      }))
    )
  }
)
