import React from 'react'
import ResponsiveOrdinalFrame from 'semiotic/lib/ResponsiveOrdinalFrame'

export default (props) => {
  const { data, oAccessor, rAccessor } = props

  const frameProps = {
    data,
    size: [null, 70],
    type: 'bar',
    projection: 'horizontal',
    oAccessor,
    rAccessor,
    style: (d) => {
      switch (d.type) {
        case 'baseline':
          return { fill: '#3033ff', stroke: 'white' }
        case 'potential':
          return { fill: '#691B99', stroke: 'white' }
        default:
          return { fill: 'black', stroke: 'white' }
      }
    },
    pieceHoverAnnotation: true,
    tooltipContent: (d) => {
      const isBaseline = d.type === 'baseline'
      return (
        <div
          style={{
            background: 'rgba(255, 255, 255, 0.8',
            whiteSpace: 'noWrap',
            padding: '4px',
            boxShadow: '0 1px 2px rgba(0, 0, 0, 0.6)',
            borderRadius: '2px',
          }}
        >
          <div
            style={
              isBaseline
                ? { color: '#691B99', fontWeight: 600 }
                : { color: '#EB4079', fontWeight: 600 }
            }
          >{`${isBaseline ? 'Baseline' : 'Comparison'}`}</div>
          <div>{`Total Coverage: ${d.coverage}%`}</div>
        </div>
      )
    },
  }

  return <ResponsiveOrdinalFrame {...frameProps} responsiveWidth />
}
