import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import AudienceIcon from '@material-ui/icons/Group'
import Divider from '@material-ui/core/Divider'
import FitCoverageIcon from '@material-ui/icons/BarChart'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ScansIcon from '@material-ui/icons/AccessibilityNew'
import SizeSpecIcon from '@material-ui/icons/Description'
import PresentationIcon from '@material-ui/icons/PresentToAll'
import SizeSpecSelector from 'components/size_explorer/SizeSpecSelector'
import { setProjectSection } from 'actions/projects'

const useStyles = makeStyles((theme) => ({
  listItemIcon: {
    minWidth: '1em', // override minWidth: 56px
    marginLeft: '1.25rem',
    marginRight: theme.spacing(2),
  },
  icon: {
    width: '0.85em',
    height: '0.85em',
  },
  root: {
    height: 'calc(100vh)',
    backgroundColor: theme.palette.common.white,
    borderRight: '1px solid #ddd',
    paddingTop: theme.spacing(2),
  },
  navSection: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}))

function NavBar({ project, section, setSection, selectedSizeSpecId }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <List disablePadding>
        <section>
          <ListItem>
            <ListItemText
              primary={project.name || 'Unknown'}
              primaryTypographyProps={{ variant: 'subtitle1' }}
              secondary={moment(project.updatedAt).format('LL')}
              secondaryTypographyProps={{
                variant: 'caption',
                component: 'div',
              }}
              style={{ overflowX: 'auto' }}
            />
          </ListItem>
        </section>

        <Divider />

        <section className={classes.navSection}>
          <ListItem
            button
            disableGutters
            selected={section === 'audience'}
            onClick={() => setSection('audience')}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <AudienceIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary="Audience" style={{ overflowX: 'auto' }} />
          </ListItem>
          <ListItem
            button
            disableGutters
            selected={section === 'specs'}
            onClick={() => setSection('specs')}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <SizeSpecIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary="Size Specs" style={{ overflowX: 'auto' }} />
          </ListItem>
        </section>

        <Divider />

        <section className={classes.navSection}>
          <ListItem>
            <SizeSpecSelector
              project={project}
              selectedSizeSpecId={selectedSizeSpecId}
            />
          </ListItem>

          <ListItem
            button
            disableGutters
            disabled={!selectedSizeSpecId}
            selected={section === 'fitcoverage'}
            onClick={() => setSection('fitcoverage')}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <FitCoverageIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              primary="Fit Coverage"
              style={{ overflowX: 'auto' }}
            />
          </ListItem>
          <ListItem
            button
            disableGutters
            disabled={!selectedSizeSpecId}
            selected={section === 'visualize'}
            onClick={() => setSection('visualize')}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <ScansIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary="Reference" style={{ overflowX: 'auto' }} />
          </ListItem>
        </section>

        <Divider />

        <section className={classes.navSection}>
          <ListItem
            button
            disableGutters
            disabled={!selectedSizeSpecId}
            selected={section === 'presentation'}
            onClick={() => setSection('presentation')}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <PresentationIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              primary="Presentation"
              style={{ overflowX: 'auto' }}
            />
          </ListItem>
        </section>
      </List>
    </div>
  )
}

const mapStateToProps = (state) => ({
  section: state.project.section,
})

const mapDispatchToProps = (dispatch) => ({
  setSection: (section) => dispatch(setProjectSection(section)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)
