import React from 'react'
import LogoutIcon from '@material-ui/icons/ExitToApp'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
// import MyAccountIcon from '@material-ui/icons/AccountBox'

import * as auth from 'lib/auth'

const AccountManagerPopover = ({ closePopover }) => {
  const handleLogout = () => {
    auth.logout()
    closePopover()
  }

  return (
    <div>
      {/* <MenuItem onClick={closePopover}>
        <ListItemIcon>
          <MyAccountIcon />
        </ListItemIcon>
        <ListItemText primary='My Account' />
      </MenuItem> */}
      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Sign Out" />
      </MenuItem>
    </div>
  )
}

export default AccountManagerPopover
