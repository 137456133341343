import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import Typography from '@material-ui/core/Typography'

import AngleSelect from 'components/lib/AngleSelect'
import AvatarCollection from 'components/avatars/AvatarCollection'
import * as AvatarsApi from 'lib/api/avatars'
import * as AvatarsUtil from 'lib/avatars'
import DownloadAvatarsDialog from 'components/avatars/library/DownloadAvatarsDialog'
import Notification from 'components/lib/Notification'
import PageContent from 'components/lib/PageContent'
import PageTitle from 'components/lib/PageTitle'
import { purchasedAvatars, purchasePendingAvatars } from 'selectors/avatars'

const styles = makeStyles((theme) => ({
  actionGroup: {
    paddingLeft: theme.spacing(2),
    '& hr': {
      margin: theme.spacing(0, 0.5),
    },
  },
}))

const AvatarLibraryIndex = ({
  avatars,
  avatarsPending,
  projects,
  status,
  user,
}) => {
  const classes = styles()

  const [angle, setAngle] = React.useState('000')
  const [downloadStatus, setDownloadStatus] = React.useState(null)
  const [organizeBy, setOrganizeBy] = React.useState('date')
  const [selectedAvatars, setSelectedAvatars] = React.useState({})
  const [snackbar, setSnackbar] = React.useState(null)

  // a list of bookmark collections represented as an object
  // each collection should have a title, subtitle (optional), and list of avatars.
  // e.g. { title: 'Amazing Avatars', subtitle: 'stuff i really really like', avatars: [pkg, pkg, pkg] }
  const organizedAvatars = React.useMemo(() => {
    if (avatars) {
      const allAvatars = [
        ...Object.values(avatars),
        ...Object.values(avatarsPending || {}),
      ]
      if (organizeBy === 'project') {
        return AvatarsUtil.organizeByProject(allAvatars)
      } else {
        // default is by date.  pull out most recent 72 hours as its own collection, otherwise by month
        return AvatarsUtil.organizeByDate(allAvatars)
      }
    } else {
      return []
    }
  }, [avatars, avatarsPending, organizeBy])

  const hasSelection = React.useMemo(() => {
    return !_.isEmpty(selectedAvatars)
  }, [selectedAvatars])

  const handleAngleSelect = (angle) => {
    setAngle(angle)
  }

  const handleAvatarSelection = (selection) => {
    setSelectedAvatars(selection)
  }

  const handleDownloadSelections = () => {
    const toDownload = Object.values(selectedAvatars).map(
      (bkmk) => bkmk.packageId
    )

    setDownloadStatus('working')
    AvatarsApi.downloadAvatars(toDownload)
      .then((result) => {
        setDownloadStatus(null)
        setSnackbar(
          `download requested for ${toDownload.length} ${
            toDownload.length > 1 ? 'avatars' : 'avatar'
          }`
        )
      })
      .catch((error) => {
        console.log(error)
        setDownloadStatus('error')
      })
  }

  return (
    <div>
      <PageTitle
        title="Avatar Library"
        adornment={
          <ToggleButtonGroup
            value={organizeBy}
            size="small"
            exclusive
            onChange={(e, val) => setOrganizeBy(val)}
          >
            <ToggleButton value="date">Date</ToggleButton>
            <ToggleButton value="project">Project</ToggleButton>
          </ToggleButtonGroup>
        }
      />

      <PageContent isLoading={status === 'working'}>
        <Grid container alignItems="center">
          <Grid item>
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="flex-start"
            >
              <Grid item>
                {!hasSelection ? (
                  <Typography variant="subtitle1">
                    No avatars selected
                  </Typography>
                ) : (
                  <Typography variant="subtitle1">
                    Selected {Object.keys(selectedAvatars).length} avatars
                  </Typography>
                )}
              </Grid>
              <Grid item style={{ paddingLeft: 8 }}>
                <Grid container spacing={2} className={classes.actionGroup}>
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={!hasSelection}
                    startIcon={<DownloadIcon />}
                    style={{ marginLeft: 8 }}
                    onClick={() => setDownloadStatus('confirm')}
                  >
                    Download
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ flex: 1, marginLeft: 40 }}>
            <span>
              <Notification />{' '}
              <Typography variant="caption">
                indicates variant avatar for size
              </Typography>
            </span>
          </Grid>
          <Grid item style={{ minWidth: 250 }}>
            <AngleSelect angle={angle} onAngleSelect={handleAngleSelect} />
          </Grid>
        </Grid>

        <AvatarCollection
          angle={angle}
          avatarLabel={(avatar) => {
            const isPending = Boolean(avatarsPending[avatar.packageId])
            if (organizeBy === 'project') {
              return (
                <Typography variant="body2" align="center">
                  {isPending ? 'Purchase pending' : avatar.size}{' '}
                  {avatar.variant && !isPending ? <Notification /> : null}
                </Typography>
              )
            } else {
              return (
                <Typography variant="body2" align="center">
                  {isPending
                    ? 'Purchase pending'
                    : moment(avatar.createdAt).format('LL')}
                </Typography>
              )
            }
          }}
          canClick={(avatar) => !avatarsPending[avatar.packageId]}
          collections={organizedAvatars}
          onSelect={handleAvatarSelection}
          selectedAvatars={selectedAvatars}
        />
      </PageContent>

      <DownloadAvatarsDialog
        count={Object.values(selectedAvatars).length}
        email={user.email}
        errorText={
          downloadStatus === 'error' ? 'Download request failed' : null
        }
        isWorking={downloadStatus === 'working'}
        onClose={() => setDownloadStatus(null)}
        onConfirm={handleDownloadSelections}
        open={downloadStatus !== null}
      />

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={Boolean(snackbar)}
        autoHideDuration={6000}
        onClose={() => setSnackbar(null)}
        message={snackbar}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setSnackbar(null)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  avatars: purchasedAvatars(state),
  avatarsPending: purchasePendingAvatars(state),
  projects: state.project.entities,
  status: state.avatars.bookmarksStatus,
  user: state.session.user,
})

export default connect(mapStateToProps)(AvatarLibraryIndex)
