import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

import { formatNumber } from 'lib/format'
import { getMeasurementLabel } from 'lib/measurements'

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.black,
    borderBottom: '2px #00000054 solid',
    fontWeight: 600,
    padding: 8,
    whiteSpace: 'nowrap',
  },
  body: {
    fontSize: 14,
    whiteSpace: 'nowrap',
  },
}))(TableCell)

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
}))

const SizeTable = ({ fitCoverageTotals, primaryMeasurements, sizeData }) => {
  const classes = useStyles()
  const { totalCoverage, totalCount: totalPopulation } = fitCoverageTotals

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <StyledTableCell
              align="center"
              style={{ borderTopLeftRadius: '2px' }}
            >
              Size
            </StyledTableCell>
            {primaryMeasurements.map((measurement) => (
              <StyledTableCell
                key={`table-header-${measurement}`}
                align="center"
              >
                {getMeasurementLabel(measurement)}
              </StyledTableCell>
            ))}
            <StyledTableCell align="center">Population</StyledTableCell>
            <StyledTableCell
              align="center"
              style={{ borderTopRightRadius: '2px' }}
            >
              Fit Coverage
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sizeData.map((spec, idx) => {
            const { size, measurements, population, fitCoverage } = spec
            return (
              <TableRow key={`tableRow-${idx}`} hover>
                <StyledTableCell align="center">{size}</StyledTableCell>
                {primaryMeasurements.map((measurement) => (
                  <StyledTableCell
                    key={`table-row-${measurement}`}
                    align="center"
                  >
                    {measurements[measurement].min &&
                      measurements[measurement].max &&
                      `${measurements[measurement].min} - ${measurements[measurement].max}`}
                  </StyledTableCell>
                ))}
                <StyledTableCell align="center">
                  {population && formatNumber(population)}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {fitCoverage && `${fitCoverage}%`}
                </StyledTableCell>
              </TableRow>
            )
          })}
          <TableRow>
            <TableCell
              colSpan={
                primaryMeasurements.length
                  ? String(primaryMeasurements.length + 1)
                  : '1'
              }
            >
              <Typography
                variant="subtitle2"
                align="center"
                style={{ fontWeight: 700 }}
              >
                Summary (Totals)
              </Typography>
            </TableCell>
            <TableCell align="center" style={{ fontWeight: 700 }}>
              {totalPopulation && formatNumber(totalPopulation)}
            </TableCell>
            <TableCell align="center" style={{ fontWeight: 700 }}>
              {totalCoverage ? `${totalCoverage}%` : ''}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  )
}

export default SizeTable
