const UNITS = {
  metric: {
    label: 'Metric (cm/kg)',
  },
  us: {
    label: 'US (in/lb)',
  },
}

export function getUnitsLabel(units) {
  return UNITS[units] ? UNITS[units].label : 'Unknown'
}

export function kgToLb(val) {
  return val * 2.20462
}

export function lbToKg(val) {
  return val / 2.20462
}

export function cmToIn(val) {
  return val / 2.54
}

export function inToCm(val) {
  return val * 2.54
}
