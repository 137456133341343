import * as RestAPI from '../rest_api'

const toBase64 = (query) => {
  return Buffer.from(JSON.stringify(query)).toString('base64')
}

// =====   JSON Encoded Queries   ===== //

export const audienceDistribution = (
  population,
  audience,
  units,
  measure,
  correlationMeasure = ''
) => {
  const query = {
    population,
    audience: {
      filters: audience,
    },
    measure,
    correlationMeasure,
    units,
  }
  const encodedQuery = toBase64(query)
  return RestAPI.GET('/distribution', { q: encodedQuery })
}

export const fitCoverage = (population, audience, sizeDefs, units) => {
  const query = {
    population,
    audience: {
      filters: audience,
    },
    sizeDefs,
    units,
  }

  const encodedQuery = toBase64(query)
  return RestAPI.GET('/fitcoverage', { q: encodedQuery })
}

export const sizeDefDistribution = async (query) => {
  const encodedQuery = toBase64(query)
  return RestAPI.GET('/distribution', { q: encodedQuery })
}

export const scanBuckets = (population, audience, sizeDef, dims, units) => {
  const query = {
    population,
    audience: {
      filters: audience,
    },
    sizeDef,
    dims,
    units,
  }
  const encodedQuery = toBase64(query)
  return RestAPI.GET('/buckets', { q: encodedQuery })
}

export const search = async (params) => {
  const encodedQuery = toBase64(params)
  return RestAPI.GET('/search', { q: encodedQuery })
}

export const getSizeSpecCentroids = async (params) => {
  const encodedQuery = toBase64(params)
  return RestAPI.GET('/sizespec_centroids', { q: encodedQuery })
}

export const summary = async (params, measurements) => {
  const encodedQuery = toBase64({ ...params, measures: measurements })
  return RestAPI.GET('/summary', { q: encodedQuery })
}
