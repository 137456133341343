import _ from 'lodash'
import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Grow from '@material-ui/core/Grow'
import Typography from '@material-ui/core/Typography'

import CreateProjectCard from 'components/homepage/CreateProjectCard'
import LoadingSpinner from 'components/size_explorer/lib/LoadingSpinner'
import ProjectCard from 'components/homepage/ProjectCard'
import SearchBar from 'components/homepage/SearchBar'
import SnackbarCollection from 'components/lib/SnackbarCollection'
import * as ProjectActions from 'actions/projects'
import { purchasedPopulations } from 'selectors/populations'
import { sortedProjects } from 'selectors/projects'

const styles = makeStyles((theme) => ({
  cardMatrix: {
    marginTop: theme.spacing(4),
  },
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '1280px',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  titlebar: {},
  toolbar: theme.mixins.toolbar,
}))

function HomepageIndex({
  history,
  populations,
  projects,
  purchasedPopulations,
}) {
  const classes = styles()
  const dispatch = useDispatch()

  const [filteredProjects, setFilteredProjects] = React.useState([])
  const [snackItems, setSnackItems] = React.useState([])

  React.useEffect(() => {
    if (projects) {
      setFilteredProjects(projects)
    }
  }, [projects])

  const handleCloseSnackbar = (closeItem) => {
    setSnackItems(snackItems.filter((item) => item.id !== closeItem.id))
  }

  const handleCreate = (project) => {
    dispatch(ProjectActions.created(project))
  }

  const handleDelete = (project) => {
    dispatch(ProjectActions.deleted(project))
    setSnackItems([
      ...snackItems,
      { id: project.id, message: `project ${project.name} was deleted` },
    ])
  }

  const handleSearch = (query) => {
    if (_.isEmpty(query)) {
      setFilteredProjects(projects)
    } else {
      // start simple.  look through original projects for names matching query
      const q = _.toLower(query)
      setFilteredProjects(
        projects.filter((proj) => _.toLower(proj.name).indexOf(q) >= 0)
      )
    }
  }

  const handleUpdate = (project) => {
    dispatch(ProjectActions.updated(project))
    setSnackItems([
      ...snackItems,
      { id: project.id, message: `updated project ${project.name}` },
    ])
  }

  return (
    <div className={classes.root}>
      <Grid container justify="space-between" className={classes.titlebar}>
        <Grid item>
          <Typography variant="h5" style={{ fontWeight: 600 }}>
            Sizing Projects
          </Typography>
        </Grid>
        <Grid item>
          <SearchBar onSearch={handleSearch} />
        </Grid>
      </Grid>

      {!projects ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: 'calc(100vh - 48px)',
          }}
        >
          <LoadingSpinner />
        </div>
      ) : (
        <Grow in={Boolean(projects)} timeout={600}>
          <Grid container spacing={4} className={classes.cardMatrix}>
            <Grid item xs={12} md={6} lg={4}>
              <CreateProjectCard
                onNewProject={handleCreate}
                populations={purchasedPopulations}
              />
            </Grid>

            {filteredProjects.map((project, idx) => (
              <Grid item xs={12} md={6} lg={4} key={idx}>
                <ProjectCard
                  onDelete={handleDelete}
                  onUpdate={handleUpdate}
                  project={project}
                  populations={populations}
                />
              </Grid>
            ))}
          </Grid>
        </Grow>
      )}

      <SnackbarCollection
        items={snackItems}
        onCloseItem={handleCloseSnackbar}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  populations: state.populations,
  projects: sortedProjects(state),
  purchasedPopulations: purchasedPopulations(state),
})

export default connect(mapStateToProps)(HomepageIndex)
