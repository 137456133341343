import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
// import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import Tooltip from '@material-ui/core/Tooltip'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'

import FileUploader from 'components/size_explorer/size_specs/csv/FileUploader'

const useStyles = makeStyles((theme) => ({
  content: {
    paddingBottom: theme.spacing(2),
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default ({ spec, onSpecUpdate, setMeasurementValues }) => {
  const classes = useStyles()

  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Tooltip title="Import CSV">
        <CloudUploadIcon
          color="primary"
          size="small"
          onClick={handleClickOpen}
        />
      </Tooltip>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Import Size Spec
        </DialogTitle>
        <DialogContent className={classes.content}>
          <FileUploader
            onSpecUpdate={onSpecUpdate}
            setMeasurementValues={setMeasurementValues}
            onClose={handleClose}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}
