import _ from 'lodash'
import React from 'react'
import Papa from 'papaparse'
import moment from 'moment'

import CSVDownloadButton from 'components/size_explorer/lib/CSVDownloadButton'

import * as MeasurementsUtil from 'lib/measurements'

const makeMeasurementsCsv = (measurementList, measurementsData, unitSystem) => {
  // { age: { isFetching: true, audience: { data: [...], std: 0.1, mean: 0.2 }, population: {...} } }
  const temp = measurementList
    .map((measure) => {
      const data = measurementsData[measure]

      return ['population', 'audience'].map((grouping) => ({
        Measure: MeasurementsUtil.getMeasurementLabel(measure),
        Scope: grouping,
        Unit: MeasurementsUtil.getMeasurementUnit(measure, unitSystem),
        Mean: data[grouping].mean.toFixed(3),
        'Standard Deviation': data[grouping].std.toFixed(3),
        'Bodies Total': data[grouping].data.reduce(
          (total, val) => total + val.count,
          0
        ),
        'Bodies 10th Percentile': data[grouping].percentile10.toFixed(3),
        'Bodies 90th Percentile': data[grouping].percentile90.toFixed(3),
      }))
    })
    .flat()

  return Papa.unparse(temp)
}

export default ({ measurementList, measurementsData, units }) => {
  const createCSVContent = () => {
    return makeMeasurementsCsv(measurementList, measurementsData, units)
  }

  return (
    <CSVDownloadButton
      fileName={`measurements-${moment().format()}.csv`}
      canDownload={_.every(
        Object.values(measurementsData),
        (data) => data.isFetching === false
      )}
      createCSVContent={createCSVContent}
    />
  )
}
