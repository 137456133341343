import _ from 'lodash'

// attempt to parse the given value into an integer and if we fail simply return original value
export const intOrVal = (val) => {
  try {
    const v = parseFloat(val, 10)
    return typeof v === 'number' && !isNaN(v) ? v : val
  } catch (error) {
    return val
  }
}

export const measurementTable = (scan) => {
  const filtered = _.omit(scan, 'packageId') // deep clone; doesn't mutate 'scan'

  // convert 'camelCaseString' to 'Camel Case String'
  const unCamelCase = (str) => {
    return str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
      return str.toUpperCase()
    })
  }

  Object.keys(filtered).forEach((key) => {
    const newKey = unCamelCase(key)
    filtered[newKey] = filtered[key]
    delete filtered[key]
  })

  return filtered
}

// Used for calculating audience total
export const getTotalCount = (data = []) => {
  const callback = (acc, val) => {
    return acc + val.count
  }
  return data.reduce(callback, 0)
}
