import React, { useEffect } from 'react'
import { withRouter } from 'react-router'

const ScrollToTop = ({ children, location: { pathname } }) => {
  // console.log('this is props from scroll to top', props)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return <div>{children}</div>
}

export default withRouter(ScrollToTop)
