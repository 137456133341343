import _ from 'lodash'
import { combineReducers } from 'redux'

const avatars = (state = null, action) => {
  switch (action.type) {
    case 'AVATARS_PURCHASED_LIST_FAIL':
      return {}
    case 'AVATARS_PURCHASED_LIST_SUCCESS':
      return _.keyBy(action.result.avatars, 'id')
    default:
      return state
  }
}

const avatarsStatus = (state = null, action) => {
  switch (action.type) {
    case 'AVATARS_PURCHASED_LIST_START':
      return 'working'
    case 'AVATARS_PURCHASED_LIST_SUCCESS':
      return null
    case 'AVATARS_PURCHASED_LIST_FAIL':
      return 'error'
    default:
      return state
  }
}

const bookmarks = (state = null, action) => {
  switch (action.type) {
    case 'AVATARS_BOOKMARK_LIST_FAIL':
      return {}
    case 'AVATARS_BOOKMARK_LIST_SUCCESS':
      return _.keyBy(action.result, 'packageId')
    case 'AVATARS_BOOKMARK_ADD_SUCCESS':
      return {
        ...state,
        [action.bookmark.packageId]: action.bookmark,
      }
    case 'AVATARS_BOOKMARK_REMOVE_SUCCESS': {
      const { [action.pkgId]: unbookmarked, ...rest } = state
      return rest
    }
    case 'AVATARS_BOOKMARKS_REMOVED':
      return _.omit(
        state,
        action.bookmarks.map((bmrk) => bmrk.packageId)
      )
    default:
      return state
  }
}

const bookmarksStatus = (state = null, action) => {
  switch (action.type) {
    case 'AVATARS_BOOKMARK_LIST_START':
      return 'working'
    case 'AVATARS_BOOKMARK_LIST_SUCCESS':
      return null
    case 'AVATARS_BOOKMARK_LIST_FAIL':
      return 'error'
    default:
      return state
  }
}

const orders = (state = null, action) => {
  switch (action.type) {
    case 'AVATARS_ORDERS_LIST_FAIL':
      return []
    case 'AVATARS_ORDERS_LIST_SUCCESS':
      return action.result.orders
    case 'AVATARS_ORDER_CREATED':
      return [action.order, ...state]
    default:
      return state
  }
}

const ordersStatus = (state = null, action) => {
  switch (action.type) {
    case 'AVATARS_ORDERS_LIST_START':
      return 'working'
    case 'AVATARS_ORDERS_LIST_SUCCESS':
      return null
    case 'AVATARS_ORDERS_LIST_FAIL':
      return 'error'
    default:
      return state
  }
}

const page = (state = 'library', action) => {
  switch (action.type) {
    case 'AVATARS_PAGE_SET':
      return action.page
    default:
      return state
  }
}

export default combineReducers({
  avatars,
  avatarsStatus,
  bookmarks,
  bookmarksStatus,
  orders,
  ordersStatus,
  page,
})
