import _ from 'lodash'

export const unflattenSizeDefs = (sizeDefs) => {
  const measurementValues = {}
  const filters = {}

  if (sizeDefs) {
    for (var i = 0; i < sizeDefs.length; i++) {
      const { size, additionalFilters, ...measurements } = sizeDefs[i]
      measurementValues[size] = {}

      // go through all primary measurements and add them to our measurementValues object
      for (const msmt in measurements) {
        measurementValues[size] = {
          ...measurementValues[size],
          [msmt]: measurements[msmt], // measurements[msmt] is the value
        }
      }

      // go through additional filters (absolute vs mean/std)
      // we add any absolute to measurementValues bc it uses the same table component as primary measurements
      if (additionalFilters) {
        additionalFilters.forEach(({ measure, type, min, max }) => {
          if (type === 'absolute') {
            measurementValues[size] = {
              ...measurementValues[size],
              [`${measure}Min`]: min,
              [`${measure}Max`]: max,
            }
          } else if (!filters[measure]) {
            filters[measure] = { type, min, max }
          }
        })
      }
    }
  }

  return { measurementValues, filters }
}

export const formatSizeDefs = (
  primaryMeasurements,
  secondaryMeasurements,
  values,
  additionalFilters,
  sizes
) => {
  // generate secondary filters from mean/std
  const baseFilters = []

  // TODO: strip down additionalFilters

  for (const measure in additionalFilters) {
    if (
      secondaryMeasurements.includes(measure) &&
      additionalFilters[measure].selected !== 'absolute'
    ) {
      baseFilters.push({
        measure,
        ...additionalFilters[measure],
      })
    }
  }

  // begin constructing size defs
  const sizeDefs = []

  sizes.forEach((size) => {
    let filters = baseFilters

    // choose all primary measurements
    const primaryValues = _.pickBy(values[size], (val, key) => {
      return primaryMeasurements.includes(key.slice(0, -3))
    })

    // choose all secondary measurements
    const secondaryValues = _.pickBy(values[size], (val, key) => {
      return secondaryMeasurements.includes(key.slice(0, -3))
    })

    // we need to parse out the 'absolute' filters for secondary measurements (i.e. if users input ranges manually)
    if (!_.isEmpty(secondaryValues)) {
      secondaryMeasurements.forEach((measure) => {
        if (
          additionalFilters[measure].selected === 'absolute' &&
          secondaryValues[`${measure}Min`] &&
          secondaryValues[`${measure}Max`]
        ) {
          filters = [
            ...baseFilters,
            {
              measure,
              type: 'absolute',
              min: Number(secondaryValues[`${measure}Min`]),
              max: Number(secondaryValues[`${measure}Max`]),
            },
          ]
        }
      })
    }

    sizeDefs.push({
      size,
      ...primaryValues,
      additionalFilters: filters,
    })
  })

  // format values from string to int
  return sizeDefs.map(({ size, additionalFilters, ...measurements }) => ({
    size,
    ..._.mapValues(measurements, (msmt) => parseFloat(msmt)),
    additionalFilters,
  }))
}

// takes in 2 lists of sizedefs, old & new, and merges the old ones into the new
// if an oldDef doesn't exist in the newDefs it's skipped
export const mergeSizeDefs = (newDefs, oldDefs) => {
  const oldDefIndex = _.keyBy(oldDefs, 'size')

  return newDefs.map((newDef) => {
    const oldDef = oldDefIndex[newDef.size]

    if (oldDef) {
      return {
        ...oldDef,
        ...newDef,
      }
    } else {
      return newDef
    }
  })
}
