import React from 'react'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import ClusterBarChart from 'components/size_explorer/charts/ClusterBarChart'
import LoadingSpinner from 'components/size_explorer/lib/LoadingSpinner'
import { generateClusterBarChartData } from 'lib/charts'
import { formatNumber } from 'lib/format'

// const sampleData = [
//   { size: 'XS', baseline: { count: 50, coverage: 0.2 }, potential: { count: 24, coverage: 0.3 } },
//   { size: 'S', baseline: { count: 32, coverage: 0.2 }, potential: { count: 24, coverage: 0.3 } },
//   { size: 'M', baseline: { count: 24, coverage: 0.2 }, potential: { count: 32, coverage: 0.3 } },
//   { size: 'L', baseline: { count: 61, coverage: 0.2 }, potential: { count: 10, coverage: 0.3 } },
//   { size: 'XL', baseline: { count: 20, coverage: 0.2 }, potential: { count: 5, coverage: 0.3 } }
// ]

const TOOLTIP_WIDTH = 200
const TOOLTIP_HEIGHT = 95

const tooltipStyle = {
  position: 'absolute',
  width: TOOLTIP_WIDTH,
  height: TOOLTIP_HEIGHT,
  padding: '8px',
  background: 'rgba(255, 255, 255, 0.8',
  boxShadow: '0 1px 2px rgba(0, 0, 0, 0.6)',
  borderRadius: '2px',
  whiteSpace: 'noWrap',
}

export default ({ baseline, potential, baselineSpec, potentialSpec }) => {
  const data = generateClusterBarChartData(baseline.data, potential.data)
  const isFetching = baseline.isFetching || potential.isFetching

  // initial render view
  // conditions to render the loading spinner: isFetching
  // conditions to render the reload: !isFetching && isDataEmpty
  // else, render the cluster bar chart data

  return (
    // <Card style={{ minHeight: 330, padding: '16px 8px 0 8px', border: '1px solid #ddd' }}>
    <Card style={{ minHeight: 330, padding: '16px 12px 0 12px' }}>
      <Grid id="size-coverage" container>
        <Grid item xs={12}>
          <Typography
            align="center"
            style={{ fontWeight: 600, marginBottom: 16 }}
          >
            Coverage by Size
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ minHeight: '230px' }}
          >
            {isFetching ? (
              <LoadingSpinner />
            ) : (
              <ClusterBarChart
                data={data}
                oAccessor="size"
                rAccessor={[(d) => d.baseline.count, (d) => d.potential.count]}
                tooltipContent={(d) => {
                  const isBaseline = d.rName === 'function-0'
                  const xDiff =
                    document.getElementById('size-coverage').offsetWidth - d.x

                  const style = {
                    ...tooltipStyle,
                    left:
                      xDiff < TOOLTIP_WIDTH + 10
                        ? xDiff - (TOOLTIP_WIDTH + 10)
                        : 0,
                    top: d.scaledValue < TOOLTIP_HEIGHT ? -90 : 0,
                  }

                  return (
                    <div style={style}>
                      <div
                        style={{ color: isBaseline ? '#3033ff' : '#691B99' }}
                      >
                        {isBaseline ? baselineSpec.name : potentialSpec.name}
                      </div>
                      <div>Size: {d.size}</div>
                      <div>{`Population: ${formatNumber(
                        isBaseline ? d.baseline.count : d.potential.count
                      )}`}</div>
                      <div>{`Fit Coverage: ${
                        isBaseline ? d.baseline.coverage : d.potential.coverage
                      }%`}</div>
                    </div>
                  )
                }}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}
