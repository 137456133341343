import QueryString from 'query-string'
import * as RestAPI from '../rest_api'

const API_BASEURL = process.env.REACT_APP_API_BASEURL

// fetch scan image based on packageId identifier
export const fetchImage = (pkgId, angle = '000') => {
  let url = `${API_BASEURL}/records/${pkgId}/scan/img`
  if (angle) {
    const qs = QueryString.stringify({ angle })
    url += '?' + qs
  }

  return new Promise((resolve, reject) => {
    window
      .fetch(url, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + window.localStorage.getItem('token'),
          Accept: 'image/png',
        },
      })
      .then((response) => {
        if (response.ok) {
          resolve(response.blob())
        } else {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const fetchScanDetail = (pkgId) => {
  return RestAPI.GET(`/scans/${pkgId}`)
}
