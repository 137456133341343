import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

import ScanTile from 'components/lib/ScanTile'

const styles = makeStyles((theme) => ({
  root: {
    // width: 'calc(90vw)'
    maxWidth: 1000,
  },
}))

function AvatarGallery({ angle, avatars, gender, onSetAvatars }) {
  const classes = styles()

  const { primary, variants } = avatars

  const handleUnsetPrimary = () => {
    onSetAvatars({
      ...avatars,
      primary: null,
    })
  }

  const handleRemoveVariant = (removePkg) => {
    onSetAvatars({
      ...avatars,
      variants: avatars.variants.filter((pkg) => pkg !== removePkg),
    })
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <ScanTile
            label="Primary"
            color="primary"
            scanImageProps={{
              angle: angle,
              gender,
              pkgId: primary,
              upperRightAdornment: (
                <IconButton size="small" onClick={handleUnsetPrimary}>
                  <CloseIcon />
                </IconButton>
              ),
            }}
          />
        </Grid>

        {variants.map((vnt) => (
          <Grid item key={vnt} xs={2}>
            <ScanTile
              label="Variant"
              scanImageProps={{
                angle: angle,
                pkgId: vnt,
                upperRightAdornment: (
                  <Tooltip title="Remove">
                    <IconButton
                      size="small"
                      onClick={() => handleRemoveVariant(vnt)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                ),
              }}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

export default AvatarGallery
