import React from 'react'
import { useDispatch } from 'react-redux'
import Select from 'react-select'

import * as AudienceActions from 'actions/audience_distribution_correlation'
import * as SizeSpecActions from 'actions/size_specs'
import * as FitCoverageActions from 'actions/fit_coverage'

export default ({ project, selectedSizeSpecId }) => {
  const dispatch = useDispatch()

  const [specOptions, setSpecOptions] = React.useState([])
  const [selectedSpecOption, setSelectedSpecOption] = React.useState(null)

  // if project changes then refresh options
  React.useEffect(() => {
    if (
      project &&
      project.sizeSpecs &&
      Object.keys(project.sizeSpecs).length > 0
    ) {
      const keys = Object.keys(project.sizeSpecs)
      // label = name, value = id
      setSpecOptions(
        keys.map((sizeSpecId) => ({
          label: project.sizeSpecs[sizeSpecId].name,
          value: sizeSpecId,
        }))
      )
    }
  }, [project])

  // if selected spec changes update selected option
  React.useEffect(() => {
    if (selectedSizeSpecId) {
      const spec = project.sizeSpecs[selectedSizeSpecId]
      setSelectedSpecOption({ label: spec.name, value: selectedSizeSpecId })
    } else {
      setSelectedSpecOption(null)
    }
  }, [project, selectedSizeSpecId])

  const handleSelect = (choice) => {
    const selectedSpecId = choice.value

    // capture our selected spec
    dispatch(SizeSpecActions.setSizeSpec(project.id, selectedSpecId))

    // fetch fit coverage data for Context Bar
    dispatch(
      FitCoverageActions.fetchFitCoverage({
        units: project.units,
        population: project.population,
        audienceFilter: project.audience,
        sizeDefs: project.sizeSpecs[selectedSpecId].sizeDefs,
      })
    )

    // also grab scatterplot data
    dispatch(
      AudienceActions.fetchAllCorrelationDistributions({
        units: project.units,
        population: project.population,
        audienceFilter: project.audience,
        measurements: project.sizeSpecs[selectedSpecId].primaryMeasurements,
      })
    )
  }

  return (
    <Select
      options={specOptions}
      value={selectedSpecOption}
      onChange={handleSelect}
      styles={{
        container: (provided, state) => ({
          ...provided,
          width: '280px',
        }),
        valueContainer: (provided, state) => ({
          ...provided,
          fontWeight: 600,
          justifyContent: 'center', // centers the selected item tex
        }),
        menuList: (provided, state) => ({
          ...provided,
          zIndex: 999,
          textAlign: 'center', // centers the menu list item text
        }),
      }}
      isSearchable={false}
      isDisabled={Object.values(project.sizeSpecs).length === 0}
      placeholder="Active Size Spec"
    />
  )
}
