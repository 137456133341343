import * as RestAPI from 'lib/rest_api'
// CREATE, GET ALL, GET ONE, DELETE

export const fetchSizeSpecList = (projectId) => {
  return new Promise((resolve, reject) => {
    RestAPI.GET(`/projects/${projectId}/sizespecs`)
      .then((result) => resolve(result))
      .catch((err) => reject(err))
  })
}

export const fetchSizeSpec = (projectId, sizeSpecId) => {
  return new Promise((resolve, reject) => {
    RestAPI.GET(`/projects/${projectId}/sizespecs/${sizeSpecId}`)
      .then((result) => resolve(result))
      .catch((err) => reject(err))
  })
}

/* Example Size Spec
 {
    "name": "Womens Tops - Size S-XL",
    "units":"us",
    "sizes":{
        "chestBustGirth":{
            "min":40,
            "grade":4
        },
        "waistGirth": {
            "min":32,
            "grade":4
        },
        "hipSeatGirth": {
            "min":44,
            "grade":4
        }
    }
  }
*/
export const createSizeSpec = (projectId, sizeSpec) => {
  return new Promise((resolve, reject) => {
    RestAPI.POST(`/projects/${projectId}/sizespecs`, sizeSpec)
      .then((result) => resolve(result))
      .catch((err) => reject(err))
  })
}

export const updateSizeSpec = (projectId, sizeSpec) => {
  const { id } = sizeSpec
  return new Promise((resolve, reject) => {
    RestAPI.POST(`/projects/${projectId}/sizespecs/${id}`, sizeSpec)
      .then((result) => resolve(result))
      .catch((err) => reject(err))
  })
}

export const deleteSizeSpec = (projectId, sizeSpecId) => {
  return new Promise((resolve, reject) => {
    RestAPI.DELETE(`/projects/${projectId}/sizespecs/${sizeSpecId}`)
      .then((result) => resolve(result))
      .catch((err) => reject(err))
  })
}
