import _ from 'lodash'
import { createSelector } from 'reselect'
import { getTotalCount } from 'lib/utils'

const scatterplots = (state) => {
  return state.charts.correlationPlots
}

export const scatterplotSelector = (plotName) =>
  createSelector(scatterplots, (plots) => {
    if (plots.length === 0) {
      return []
    }

    return plots
  })

// TODO: there are 10 colors; ie. a histogram can show maximum of 10 unique colors for the sizes
const COLORS = [
  '#ac58e5',
  '#E0488B',
  '#9fd0cb',
  '#e0d33a',
  '#7566ff',
  '#533f82',
  '#7a255d',
  '#365350',
  '#a19a11',
  '#3f4482',
]

const normalizeData = (data) => {
  const totalCount = getTotalCount(data)

  return data.map((obj) => ({
    ...obj,
    count: ((obj.count / totalCount) * 100).toFixed(1),
  }))
}

export const histogramSelector = createSelector(
  (state) => state.sizeSpecDistributions.distributions.data,
  (state) => state.sizeSpecDistributions.summary,
  (distributionData, summaryData) => {
    if (!summaryData || !distributionData) return []

    const semioticsInputData = distributionData.map((el, i) => {
      const { data, measure, size } = el

      if (data.length === 0) {
        return {
          size,
          color: COLORS[i % 10],
          mean: null,
          coordinates: [],
        }
      }

      const summary = _.find(summaryData, (sd) => sd.size === size)
      const mean =
        summary &&
        summary[measure] &&
        summary[measure].mean &&
        summary[measure].mean.toFixed(1)

      return {
        size,
        color: COLORS[i % 10],
        mean,
        coordinates: normalizeData(data),
      }
    })

    return semioticsInputData
  }
)

export const minmaxPegSelector = createSelector(
  (state) => state.sizeSpecDistributions.distributions.data,
  (distributionData) => {
    if (!distributionData || distributionData.length < 1)
      return { coordinates: [] }

    const measure = distributionData[0].measure
    const allValues = distributionData
      .map((sizeData) => sizeData.data.map((datum) => datum[measure]))
      .flat()
    const min = _.min(allValues)
    const max = _.max(allValues)

    const minDatum = {
      [measure]: min,
      count: 0,
    }

    const maxDatum = {
      [measure]: max,
      count: 0,
    }

    return {
      coordinates: [minDatum, maxDatum],
    }
  }
)
