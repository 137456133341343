import _ from 'lodash'
import numeral from 'numeral'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import BookmarkFullIcon from '@material-ui/icons/Bookmark'
import BookmarkEmptyIcon from '@material-ui/icons/BookmarkBorder'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import CircularProgress from '@material-ui/core/CircularProgress'
import ErrorIcon from '@material-ui/icons/ErrorOutline'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import StarFilledIcon from '@material-ui/icons/Star'
import StarUnfilledIcon from '@material-ui/icons/StarBorder'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

import AvatarMeasurementsTable from 'components/avatars/search/AvatarMeasurementsTable'
import BodyShapeIndicator from 'components/avatars/sizing/modal/BodyShapeIndicator'
import * as QueriesApi from 'lib/api/queries'
import ScanTile from 'components/lib/ScanTile'
import SearchInputForm from 'components/avatars/sizing/modal/SearchInputForm'

const styles = makeStyles((theme) => ({
  angleSelect: {
    width: '60%',
  },
  carousel: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '400px',
  },
  carouselBody: {
    flex: 1,
  },
  carouselLeft: {
    // display: 'flex'
  },
  carouselLoading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '400px',
  },
  carouselRight: {
    // display: 'flex'
  },
  summary: {
    paddingTop: theme.spacing(2),
  },
  table: {
    paddingTop: theme.spacing(2),
    textAlign: 'right',
  },
}))

function AvatarBrowser({
  angle,
  avatars,
  onSetAvatars,
  population,
  project,
  sizeDef,
  sizeSpec,
}) {
  const classes = styles()

  const [carouselIndex, setCarouselIndex] = React.useState(0)
  const [searchResult, setSearchResult] = React.useState(null)
  const [searchStatus, setSearchStatus] = React.useState(null)
  const [size, setSize] = React.useState(null)

  const scans = searchResult ? searchResult.scans : []
  const means = searchResult ? searchResult.means : {}

  const carouselCurrentScan = scans.length > 0 ? scans[carouselIndex] : null

  const primaryMeasurementRanges = React.useMemo(() => {
    if (sizeDef && sizeSpec) {
      return sizeSpec.primaryMeasurements.map((measurement) => ({
        measurement,
        range: [sizeDef[`${measurement}Min`], sizeDef[`${measurement}Max`]],
      }))
    } else {
      return []
    }
  }, [sizeDef, sizeSpec])

  React.useEffect(() => {
    // reset state when size changes
    if (sizeDef && sizeDef.size !== size) {
      setCarouselIndex(0)
      setSearchResult(null)
      setSearchStatus(null)
      setSize(sizeDef.size)
    }
  }, [size, sizeDef])

  const handleLeft = () => {
    if (carouselIndex > 0) {
      setCarouselIndex(carouselIndex - 1)
    }
  }

  const handleRight = () => {
    if (scans.length > carouselIndex + 1) {
      setCarouselIndex(carouselIndex + 1)
    }
  }

  const handleSearch = (query) => {
    setSearchResult(null)
    setCarouselIndex(0)
    setSearchStatus('working')
    QueriesApi.search(query)
      .then((result) => {
        setSearchStatus(null)
        setSearchResult(result)
      })
      .catch((err) => {
        console.log(err)
        setSearchStatus('error')
      })
  }

  const handleSetPrimary = () => {
    onSetAvatars({
      ...avatars,
      primary: carouselCurrentScan.packageId,
      // NOTE: when setting the primary we need to ensure we remove the scan from variants if it was there before
      variants: avatars.variants.filter(
        (pkg) => pkg !== carouselCurrentScan.packageId
      ),
    })
  }

  const handleSetVariant = () => {
    if (avatars.variants.indexOf(carouselCurrentScan.packageId) >= 0) {
      // remove this pkg as a variant
      // onSetAvatars({
      //   ...avatars,
      //   variants: avatars.variants.filter(pkg => pkg !== carouselCurrentScan.packageId)
      // })
    } else {
      // adding a new variant
      onSetAvatars({
        ...avatars,
        variants: [...avatars.variants, carouselCurrentScan.packageId],
      })
    }
  }

  const primaryAdornment = (
    <Tooltip title="Set as primary">
      <IconButton
        onClick={handleSetPrimary}
        disabled={
          carouselCurrentScan &&
          carouselCurrentScan.packageId === avatars.primary
        }
      >
        {carouselCurrentScan &&
        carouselCurrentScan.packageId === avatars.primary ? (
          <StarFilledIcon />
        ) : (
          <StarUnfilledIcon />
        )}
      </IconButton>
    </Tooltip>
  )

  const variantAdornment =
    carouselCurrentScan &&
    carouselCurrentScan.packageId === avatars.primary ? null : (
      <Tooltip title="Add as variant">
        <IconButton
          onClick={handleSetVariant}
          disabled={
            carouselCurrentScan &&
            _.some(avatars.variants, (v) => v === carouselCurrentScan.packageId)
          }
        >
          {carouselCurrentScan &&
          _.some(
            avatars.variants,
            (v) => v === carouselCurrentScan.packageId
          ) ? (
            <BookmarkFullIcon />
          ) : (
            <BookmarkEmptyIcon />
          )}
        </IconButton>
      </Tooltip>
    )

  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <SearchInputForm
          isLoading={searchStatus === 'working'}
          onSubmit={handleSearch}
          population={population}
          project={project}
          sizeDef={sizeDef}
          sizeSpec={sizeSpec}
        />
      </Grid>

      <Grid item xs={4}>
        {searchStatus === 'error' ? (
          <Grid
            container
            direction="column"
            className={classes.carouselLoading}
          >
            <div>
              <ErrorIcon style={{ fontSize: 64 }} color="error" />
            </div>
            <Typography variant="caption" color="textSecondary" component="div">
              Error occurred during search
            </Typography>
          </Grid>
        ) : searchStatus === 'working' ? (
          <Grid
            container
            direction="column"
            className={classes.carouselLoading}
          >
            <div>
              <CircularProgress size={64} color="primary" disableShrink />
            </div>
            <Typography variant="caption" color="textSecondary" component="div">
              Searching avatar database
            </Typography>
          </Grid>
        ) : (
          <div className={classes.carousel}>
            <div className={classes.carouselLeft}>
              <IconButton
                disabled={!searchResult || carouselIndex === 0}
                onClick={handleLeft}
              >
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <div className={classes.carouselBody}>
              <ScanTile
                label={
                  <Typography variant="caption">
                    Showing{' '}
                    <b>
                      {!searchResult || scans.length < 1
                        ? 0
                        : carouselIndex + 1}
                    </b>{' '}
                    of <b>{numeral(scans.length).format('0,0')}</b> available
                    avatars
                  </Typography>
                }
                scanImageProps={{
                  angle,
                  gender: population.filters.gender,
                  pkgId: carouselCurrentScan && carouselCurrentScan.packageId,
                  upperLeftAdornment: carouselCurrentScan
                    ? primaryAdornment
                    : null,
                  upperRightAdornment: carouselCurrentScan
                    ? variantAdornment
                    : null,
                  lowerLeftAdornment: (
                    <BodyShapeIndicator
                      data={carouselCurrentScan}
                      primaryMeasurementRanges={primaryMeasurementRanges}
                    />
                  ),
                }}
              />
            </div>
            <div className={classes.carouselRight}>
              <IconButton
                disabled={!searchResult || carouselIndex >= scans.length - 1}
                onClick={handleRight}
              >
                <ChevronRightIcon />
              </IconButton>
            </div>
          </div>
        )}
      </Grid>

      <Grid item xs={3} className={classes.table}>
        <AvatarMeasurementsTable scan={carouselCurrentScan} means={means} />
      </Grid>
    </Grid>
  )
}

export default AvatarBrowser
