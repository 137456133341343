import _ from 'lodash'
import React from 'react'
import numeral from 'numeral'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import * as Measurements from 'lib/measurements'

const styles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  label: {
    paddingRight: theme.spacing(1),
    textAlign: 'right',
    width: 140,
  },
  header: {
    textAlign: 'left',
  },
}))

const SHORTLIST_MEASUREMENTS = [
  'age',
  'height',
  'weight',
  'midNeckGirth',
  'chestBustGirth',
  'naturalWaistGirth',
  'hipSeatGirth',
  'armLength',
  'inseam',
]

export default ({ means, measurements }) => {
  const classes = styles()

  if (!measurements) {
    return null
  } else {
    const data = _.pick(measurements, SHORTLIST_MEASUREMENTS)

    return (
      <table className={classes.root}>
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th className={classes.header}>Avatar</th>
            <th className={classes.header}>Mean</th>
            <th className={classes.header}>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(data).map((measurement) => (
            <tr key={measurement}>
              <td className={classes.label}>
                <Typography variant="caption">
                  {Measurements.getMeasurementLabel(measurement)}
                </Typography>
              </td>
              <td>
                <Typography variant="caption">
                  {numeral(data[measurement]).format('0,0.0')}
                </Typography>
              </td>
              <td>
                <Typography variant="caption">
                  {numeral(means[measurement]).format('0,0.0')}
                </Typography>
              </td>
              <td>
                <Typography variant="caption">
                  {numeral(data[measurement] - means[measurement]).format(
                    '0,0.0'
                  )}
                </Typography>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  }
}
