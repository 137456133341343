import React from 'react'
import ResponsiveXYFrame from 'semiotic/lib/ResponsiveXYFrame'
import { parseCamelCase, formatNumber } from 'lib/format'
import Typography from '@material-ui/core/Typography'

const COLOR = ['#d1d2ff', '#3033ff']

const getFrameProps = (data, measurement) => ({
  lines: data,
  size: [700, 340],
  // margin: { left: 80, bottom: 90, right: 10, top: 40 },
  responsiveWidth: true,
  lineType: 'area',
  xAccessor: measurement,
  yAccessor: 'count',
  yExtent: [0], // sets lower bound of yAxis to zero
  lineDataAccessor: 'coordinates',
  lineStyle: (d, i) => ({
    stroke: COLOR[i],
    strokeWidth: 1.5,
    fill: COLOR[i],
    fillOpacity: 0.6,
  }),
  axes: [
    // { orient: 'left', label: 'Scan count', tickFormat: function (e) { return e / 1e3 + 'k' } },
    // { orient: 'bottom', label: { name: parseCamelCase(measurement), locationDistance: 20 } }
    {
      orient: 'bottom',
      ticks: 6,
    },
  ],
  hoverAnnotation: true,
  tooltipContent: (d) => {
    const {
      parentLine: { title },
    } = d
    return (
      <div
        style={{
          background: 'rgba(255, 255, 255, 0.8',
          whiteSpace: 'noWrap',
          minWidth: 'max-content',
          padding: '8px',
          boxShadow: '0 1px 2px rgba(0, 0, 0, 0.6)',
          borderRadius: '2px',
        }}
      >
        <Typography
          variant="caption"
          display="block"
          align="center"
          style={{ fontWeight: 600 }}
        >
          {parseCamelCase(title)}
        </Typography>
        <Typography variant="caption" display="block" align="center">
          Value: {formatNumber(d[measurement])}
        </Typography>
        <Typography variant="caption" display="block" align="center">
          Count: {formatNumber(d.count)}
        </Typography>
      </div>
    )
  },
})

export default ({ data, measurement, std, mean }) => {
  const frameProps = getFrameProps(data, measurement, std, mean)
  return <ResponsiveXYFrame {...frameProps} />
}
