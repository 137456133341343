import React from 'react'
import { useDispatch } from 'react-redux'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'

import * as SizeSpecsApi from 'lib/api/size_specs'
import ConfirmationDialog from 'components/lib/ConfirmationDialog'

export default ({ projectId, spec: { id: specId, name } }) => {
  const dispatch = useDispatch()

  const [deleteStatus, setDeleteStatus] = React.useState(null)

  const handleDelete = () => {
    setDeleteStatus('working')

    SizeSpecsApi.deleteSizeSpec(projectId, specId)
      .then((response) => {
        setDeleteStatus(null)

        dispatch({
          type: 'DELETE_SIZE_SPEC_SUCCESS',
          payload: response,
          projectId,
          meta: { sizeSpecId: specId },
        })
      })
      .catch((error) => {
        setDeleteStatus('error')

        dispatch({ type: 'DELETE_SIZE_SPEC_FAILURE', error })
      })
  }

  const handleClickOpen = () => {
    setDeleteStatus('confirm')
  }

  const handleClose = () => {
    setDeleteStatus(null)
  }

  return (
    <>
      <Tooltip title="Delete Spec">
        <IconButton edge="end" aria-label="delete" onClick={handleClickOpen}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <ConfirmationDialog
        confirmText={
          <span>
            Confirming will permanently delete size spec <b>{name}</b>
          </span>
        }
        errorText={
          deleteStatus === 'error' ? 'Failed to delete size spec' : null
        }
        isWorking={deleteStatus === 'working'}
        onClose={handleClose}
        onConfirm={handleDelete}
        open={deleteStatus !== null}
        title="Confirm Delete"
      />
    </>
  )
}
