import moment from 'moment'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CheckboxFullIcon from '@material-ui/icons/CheckBox'
import CheckboxEmptyIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

import Notification from 'components/lib/Notification'
import ScanTile from 'components/lib/ScanTile'

const styles = makeStyles((theme) => ({
  avatars: {
    marginTop: theme.spacing(2),
  },
  avatarCollTitle: {},
  avatarCollGrid: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  avatarCollGridTile: {
    cursor: 'pointer',
  },
}))

function AvatarCollection({
  angle,
  avatarLabel,
  canClick,
  collections,
  emptyState,
  onSelect,
  selectedAvatars = {},
}) {
  const classes = styles()

  const [raisedAvatar, setRaisedAvatar] = React.useState(null)

  const handleAvatarClicked = (avatar) => {
    const isClickable = canClick ? canClick(avatar) : true
    if (!isClickable) {
      return null
    } else if (selectedAvatars[avatar.packageId]) {
      const { [avatar.packageId]: deleted, ...rest } = selectedAvatars
      onSelect(rest)
    } else {
      const selected = { ...selectedAvatars, [avatar.packageId]: avatar }
      onSelect(selected)
    }
  }

  return (
    <div className={classes.avatars}>
      {collections.map((collection) => (
        <div key={`${collection.title}-${collection.subtitle}`}>
          {collection.title ? (
            <div className={classes.avatarCollTitle}>
              <Typography variant="h5">{collection.title}</Typography>
              {collection.subtitle ? (
                <Typography variant="caption">{collection.subtitle}</Typography>
              ) : null}
            </div>
          ) : null}

          <Grid container className={classes.avatarCollGrid} spacing={2}>
            {collection.avatars.map((avatar) => (
              <Grid
                item
                key={avatar.packageId}
                xs={2}
                className={classes.avatarCollGridTile}
              >
                <ScanTile
                  label={
                    avatarLabel ? (
                      avatarLabel(avatar)
                    ) : !avatar.projectId ? (
                      moment(avatar.ts).format('LL')
                    ) : (
                      <Typography variant="body2" align="center">
                        {avatar.size} {avatar.variant ? <Notification /> : null}
                      </Typography>
                    )
                  }
                  onClick={() => handleAvatarClicked(avatar)}
                  onMouseOver={(e) => setRaisedAvatar(avatar.packageId)}
                  onMouseOut={(e) => setRaisedAvatar(null)}
                  raised={raisedAvatar && raisedAvatar === avatar.packageId}
                  color={selectedAvatars[avatar.packageId] ? 'primary' : null}
                  scanImageProps={{
                    angle,
                    gender: avatar.gender,
                    pkgId: avatar.packageId,
                    upperRightAdornment: (
                      <IconButton disabled>
                        {selectedAvatars[avatar.packageId] ? (
                          <CheckboxFullIcon color="primary" />
                        ) : (
                          <CheckboxEmptyIcon />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      ))}

      {collections.length === 0 && emptyState ? emptyState : null}
    </div>
  )
}

export default AvatarCollection
