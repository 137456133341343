import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Snackbar from '@material-ui/core/Snackbar'
import Typography from '@material-ui/core/Typography'

import AngleSelect from 'components/lib/AngleSelect'
import AvatarCollection from 'components/avatars/AvatarCollection'
import AvatarCollectionPurchaseActions from 'components/avatars/AvatarCollectionPurchaseActions'
import * as AvatarsUtil from 'lib/avatars'
import Notification from 'components/lib/Notification'
import PageContent from 'components/lib/PageContent'
import PageTitle from 'components/lib/PageTitle'
import SearchInputForm from 'components/avatars/search/SearchInputForm'
import SearchResultBrowser from 'components/avatars/search/SearchResultBrowser'
import * as QueriesApi from 'lib/api/queries'
import { purchasedAvatars } from 'selectors/avatars'

const styles = makeStyles((theme) => ({
  emptyState: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    minHeight: '400px',
    textAlign: 'center',
  },
  spacer: {
    height: theme.spacing(4),
  },
}))

function AvatarSearchIndex({ avatars, bookmarks }) {
  const classes = styles()

  // state for Search
  const [gender, setGender] = React.useState('female')
  const [searchResult, setSearchResult] = React.useState(null)
  const [status, setStatus] = React.useState(null)
  const [units, setUnits] = React.useState('metric')

  // state for Saved Avatars
  const [angle, setAngle] = React.useState('000')
  const [selectedAvatars, setSelectedAvatars] = React.useState({})
  const [snackbar, setSnackbar] = React.useState(null)

  // a list of bookmark collections represented as an object
  // each collection should have a title, subtitle (optional), and list of avatars.
  // e.g. { title: 'Amazing Avatars', subtitle: 'stuff i really really like', avatars: [pkg, pkg, pkg] }
  const organizedBookmarks = React.useMemo(() => {
    if (bookmarks) {
      return AvatarsUtil.organizeByDate(bookmarks)
    } else {
      return []
    }
  }, [bookmarks])

  const handleAvatarsPurchased = (purchased) => {
    setSelectedAvatars({})
    setSnackbar(
      `purchase requested for ${purchased.length} ${
        purchased.length > 1 ? 'avatars' : 'avatar'
      }`
    )
  }

  const handleAvatarsRemoved = (removed) => {
    setSelectedAvatars({})
    setSnackbar(`removed ${removed.length} saved avatars`)
  }

  const handleSearch = (query) => {
    setSearchResult(null)
    setStatus('working')
    QueriesApi.search(query)
      .then((result) => {
        setStatus(null)
        setSearchResult(result)
      })
      .catch((err) => {
        console.log(err)
        setStatus('error')
      })
  }

  const handleSetGender = (gender) => {
    setGender(gender)
  }

  return (
    <div>
      <PageTitle
        title="Global Search"
        adornment={
          <div>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={units}
              onChange={(e) => setUnits(e.target.value)}
              row
              style={{ justifyContent: 'space-evenly' }}
            >
              <FormControlLabel
                value="metric"
                label="Metric (cm/kg)"
                control={<Radio />}
              />
              <FormControlLabel
                value="us"
                label="US (in/lb)"
                control={<Radio />}
              />
            </RadioGroup>
          </div>
        }
      />

      <PageContent>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <div style={{ textAlign: 'center', padding: '20px 0' }}>
              <Typography variant="h6">Filter Collection</Typography>
              <Typography variant="caption">
                Describe the type of avatar you are looking for
              </Typography>
            </div>
            <SearchInputForm
              isLoading={status === 'working'}
              onSetGender={handleSetGender}
              onSubmit={handleSearch}
              units={units}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <div style={{ textAlign: 'center', padding: '20px 0' }}>
              <Typography variant="h6">Browse & Save</Typography>
              <Typography variant="caption">
                See real avatars and bookmark them for reference
              </Typography>
            </div>
            <SearchResultBrowser
              gender={gender}
              isError={status === 'error'}
              isLoading={status === 'working'}
              result={searchResult}
            />
          </Grid>
        </Grid>
      </PageContent>

      <div className={classes.spacer} />

      <PageTitle title="Saved Avatars" />

      <PageContent>
        <Grid container alignItems="center">
          <Grid item>
            <AvatarCollectionPurchaseActions
              onPurchase={handleAvatarsPurchased}
              onRemove={handleAvatarsRemoved}
              selectedAvatars={selectedAvatars}
            />
          </Grid>
          <Grid item style={{ flex: 1, marginLeft: 40 }}>
            <span>
              <Notification style={{ background: '#2b907f' }} />{' '}
              <Typography variant="caption">
                avatar has been purchased
              </Typography>
            </span>
          </Grid>
          <Grid item>
            <div style={{ minWidth: 250, marginLeft: 'auto' }}>
              <AngleSelect
                angle={angle}
                onAngleSelect={(angle) => setAngle(angle)}
              />
            </div>
          </Grid>
        </Grid>

        <AvatarCollection
          angle={angle}
          collections={organizedBookmarks}
          emptyState={
            <div className={classes.emptyState}>
              <Typography variant="h5">
                Start by running a search above. <br />
                Any avatars you save will appear here
              </Typography>
            </div>
          }
          avatarLabel={(avatar) => (
            <Typography variant="body2" align="center">
              {moment(avatar.createdAt).format('LL')}
              {avatars[avatar.packageId] && (
                <Notification style={{ background: '#2b907f' }} />
              )}
            </Typography>
          )}
          onSelect={(selected) => setSelectedAvatars(selected)}
          selectedAvatars={selectedAvatars}
        />
      </PageContent>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={Boolean(snackbar)}
        autoHideDuration={6000}
        onClose={() => setSnackbar(null)}
        message={snackbar}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setSnackbar(null)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  avatars: purchasedAvatars(state),
  bookmarks: state.avatars.bookmarks
    ? Object.values(state.avatars.bookmarks).filter(
        (bmrk) => !bmrk.purchaseDate
      )
    : [],
})

export default connect(mapStateToProps)(AvatarSearchIndex)
