import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import BarChart from 'components/size_explorer/charts/BarChart'
import Reload from 'components/size_explorer/lib/Reload'
import LoadingSpinner from 'components/size_explorer/lib/LoadingSpinner'

const TOOLTIP_WIDTH = 150
const TOOLTIP_HEIGHT = 80

const tooltipStyle = {
  position: 'absolute',
  width: TOOLTIP_WIDTH,
  height: TOOLTIP_HEIGHT,
  padding: '8px',
  background: 'rgba(255, 255, 255, 0.8',
  boxShadow: '0 1px 2px rgba(0, 0, 0, 0.6)',
  borderRadius: '2px',
  whiteSpace: 'noWrap',
}

export default ({ isLoading, sizeData }) => {
  return (
    <Grid id="size-coverage" container>
      <Grid item xs={12}>
        <Typography variant="subtitle2" align="center">
          <b>Coverage by Size</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          style={{ height: 260 }}
        >
          {isLoading ? (
            <LoadingSpinner />
          ) : sizeData.length !== 0 ? (
            <BarChart
              data={sizeData}
              oAccessor="size"
              rAccessor={(e) => e.fitCoverage}
              legend={
                Object.keys(sizeData[0].colorMap).length >= 2
                  ? {
                      // width: 200,
                      title: 'Morphotypes',
                      // position: 'left',
                      legendGroups: [
                        {
                          type: 'fill',
                          styleFn: (d) => ({ fill: d.color }),
                          items: Object.keys(sizeData[0].colorMap).map(
                            (key) => ({
                              label:
                                key === 'no-prefix' ? 'No Morphotype' : key,
                              color: sizeData[0].colorMap[key],
                            })
                          ),
                        },
                      ],
                    }
                  : null
              }
              tooltipContent={(d) => {
                const xDiff =
                  document.getElementById('size-coverage').offsetWidth - d.x

                const style = {
                  ...tooltipStyle,
                  left:
                    xDiff < TOOLTIP_WIDTH + 10
                      ? xDiff - (TOOLTIP_WIDTH + 10)
                      : 0,
                  top: d.scaledValue < TOOLTIP_HEIGHT ? -90 : 0,
                }

                return (
                  <div style={style}>
                    <div>{`Size: ${d && d.size}`}</div>
                    <div>{`Population: ${
                      d && d.data && d.data.population
                    }`}</div>
                    <div>{`Fit Coverage: ${d.fitCoverage}%`}</div>
                  </div>
                )
              }}
              oLabel={
                Object.keys(sizeData[0].colorMap).length >= 2 ||
                sizeData.length > 24
                  ? (d) => null
                  : true
              }
            />
          ) : (
            <Reload errorMessage="Failed to load size coverage" />
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
