import * as Queries from 'lib/api/queries'

export const fetchSizeSpecSummaries = (params, measurements) => (dispatch) => {
  dispatch({ type: 'SPEC_SUMMARY_START' })

  Queries.summary(params, measurements)
    .then((response) => {
      dispatch({
        type: 'SPEC_SUMMARY_SUCCESS',
        data: response.data,
      })
    })
    .catch((err) => {
      console.log('error', err)
      dispatch({ type: 'SPEC_SUMMARY_FAIL' })
    })
}

export const fetchSizeSpecDistributions = (params) => (dispatch, getState) => {
  dispatch({ type: 'SPEC_DISTRIBUTIONS_START' })

  Queries.sizeDefDistribution(params)
    .then((response) => {
      dispatch({
        type: 'SPEC_DISTRIBUTIONS_SUCCESS',
        sizes: response.sizes,
      })
    })
    .catch((err) => {
      console.log('spec distributions fetch error: ', err)
      dispatch({
        type: 'SPEC_DISTRIBUTIONS_FAIL',
      })
    })
}
