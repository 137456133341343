import _ from 'lodash'
import moment from 'moment'

export function organizeByDate(avatars) {
  const collections = []

  const sortedByTs = [...avatars].sort(
    (a, b) => (b.createdAt || b.ts || 0) - (a.createdAt || a.ts || 0)
  )

  // recent bookmarks
  // const ts72HoursAgo = new Date().getTime() - (1000 * 60 * 60 * 24 * 3)
  // const recentAvatars = sortedByTs.filter(bmrk => bmrk.ts >= ts72HoursAgo)
  // if (recentAvatars.length > 0) {
  //   collections.push({ title: 'Recent', subtitle: 'last 3 days', avatars: recentAvatars })
  // }

  // rest is done by month
  while (sortedByTs.length > 0) {
    // determine beginning of month of newest entry
    const startOfMonth = moment(
      sortedByTs[0].createdAt || sortedByTs[0].ts
    ).startOf('month')
    const startOfMonthTs = startOfMonth.valueOf()

    // gather all bookmarks in same month
    const avatars = _.remove(
      sortedByTs,
      (avatar) => (avatar.createdAt || avatar.ts) >= startOfMonthTs
    )

    // add the collection
    collections.push({ title: startOfMonth.format('MMM YYYY'), avatars })
  }

  return collections
}

export function organizeByProject(avatars) {
  // first filter out any avatars which are not associated with a project
  const projectAvatars = avatars.filter((avatar) => Boolean(avatar.projectId))

  // group the avatars by their project / spec
  const groupedAvatars = _.groupBy(
    projectAvatars,
    (avatar) => `${avatar.projectId}-${avatar.specId}`
  )

  return Object.values(groupedAvatars)
    .map((avatars) => {
      const firstAvatar = avatars[0]

      return {
        title: firstAvatar.project.name,
        subtitle: firstAvatar.sizeSpec.name,
        ts: firstAvatar.project.updatedAt,
        avatars,
      }
    })
    .sort((a, b) => (b.ts || 0) - (a.ts || 0))
}

function specHasModelAvatars(spec) {
  return _.some(
    spec.sizeDefs,
    (sizeDef) =>
      sizeDef.avatarModels &&
      (sizeDef.avatarModels.primary ||
        _.some(sizeDef.avatarModels.variants, (v) => Boolean(v)))
  )
}

export function makeAvatars(project, population, specId) {
  // NOTE: we are actually looking for Project / Size Spec combos where the spec has done model selections
  const sizeSpec = project.sizeSpecs[specId]

  if (sizeSpec && specHasModelAvatars(sizeSpec)) {
    return {
      title: project.name,
      subtitle: sizeSpec.name,
      avatars: sizeSpec.sizeDefs
        .map((sizeDef) => {
          if (sizeDef.avatarModels) {
            return [
              {
                packageId: sizeDef.avatarModels.primary,
                gender: population && population.filters.gender,
                projectId: project.id,
                specId: specId,
                size: sizeDef.size,
                variant: false,
                ts: new Date().getTime(),
              },
              ...sizeDef.avatarModels.variants.map((v) => ({
                packageId: v,
                gender: population && population.filters.gender,
                projectId: project.id,
                specId: specId,
                size: sizeDef.size,
                variant: true,
                ts: new Date().getTime(),
              })),
            ]
          } else {
            return null
          }
        })
        .flat()
        .filter((val) => Boolean(val) && val.packageId !== ''),
    }
  }
}
