import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

import * as MeasurementUtils from 'lib/measurements'

const styles = makeStyles((theme) => ({
  box: {
    backgroundColor: 'white',
    height: 11,
    border: '0.2px solid grey',
  },
  boxColored: {
    backgroundColor: '#270b61',
    height: 11,
    border: '0.2px solid grey',
  },
  main: {
    padding: theme.spacing(2),
  },
  tooltip: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

const addOrientation = (primaryMeasurementRanges, data) => {
  // orientation is what third of range a value lies (1 = lower 1/3, 2 = middle 1/3, 3 = upper 1/3)
  return primaryMeasurementRanges.map(({ measurement, range }) => {
    const rangeThirdSize = (range[1] - range[0]) / 3

    return {
      measurement,
      range,
      orientation:
        data[measurement] < range[0] + rangeThirdSize
          ? 1
          : data[measurement] > range[1] - rangeThirdSize
          ? 3
          : 2,
    }
  })
}

function BodyShapeIndicator({ primaryMeasurementRanges, data }) {
  const classes = styles()

  if (!data || !primaryMeasurementRanges) return null

  return (
    <Tooltip
      title={
        <div className={classes.tooltip}>
          {primaryMeasurementRanges.map(({ measurement, range }) => (
            <Typography
              key={`scan-bucket-label-${measurement}`}
              variant="caption"
              display="block"
            >
              {MeasurementUtils.getMeasurementLabel(measurement)}:{' '}
              <em>{`${Number(range[0]).toFixed(1)} - ${Number(range[1]).toFixed(
                1
              )}`}</em>
            </Typography>
          ))}
        </div>
      }
    >
      <div className={classes.main}>
        <Grid container direction="column">
          {addOrientation(primaryMeasurementRanges, data).map(
            ({ measurement, range, orientation }) => (
              <Grid container style={{ width: 33 }} key={measurement}>
                <Grid
                  item
                  xs={4}
                  className={
                    orientation === 1 ? classes.boxColored : classes.box
                  }
                />
                <Grid
                  item
                  xs={4}
                  className={
                    orientation === 2 ? classes.boxColored : classes.box
                  }
                />
                <Grid
                  item
                  xs={4}
                  className={
                    orientation === 3 ? classes.boxColored : classes.box
                  }
                />
              </Grid>
            )
          )}
        </Grid>
      </div>
    </Tooltip>
  )
}

export default BodyShapeIndicator
