import React from 'react'
import Card from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import TestBarChart from 'components/size_explorer/charts/TestBarChart'
import LoadingSpinner from 'components/size_explorer/lib/LoadingSpinner'

const styles = {
  fill: {
    // covered: '#7c55d1',
    // uncovered: '#888891'
    baseline: '#3033ff',
    potential: '#691B99',
  },
}

export default ({ baseline, potential }) => {
  const isFetching = baseline.isFetching || potential.isFetching
  const baselineCovered = (baseline.totalCoverage * 100).toFixed(1) || 0
  const potentialCovered = (potential.totalCoverage * 100).toFixed(1) || 0

  const barChartData = [
    {
      type: 'baseline',
      coverage: baselineCovered,
    },
    {
      type: 'potential',
      coverage: potentialCovered,
    },
  ]

  const getOverallChange = () => {
    const baselineCount = baseline.totalCount
    const potentialCount = potential.totalCount

    if (!baselineCount || !potentialCount) {
      return null
    }
    if (baselineCount < potentialCount) {
      return (
        <Typography
          align="center"
          style={{ color: styles.fill.potential, fontSize: '2rem' }}
        >
          {`${(potentialCount / baselineCount).toFixed(1)}x Increase`}
        </Typography>
      )
    }
    if (baselineCount > potentialCount) {
      return (
        <Typography
          align="center"
          style={{ color: styles.fill.baseline, fontSize: '2rem' }}
        >
          {`${(potentialCount / baselineCount).toFixed(1)}x Decrease`}
        </Typography>
      )
    }
  }

  return (
    <Card style={{ minHeight: 330, padding: '16px 20px' }}>
      <header>
        <Typography
          align="center"
          style={{ fontWeight: 600, marginBottom: 16 }}
        >
          Fit Coverage Summary
        </Typography>
      </header>
      <main
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '230px',
        }}
      >
        {isFetching ? (
          <LoadingSpinner />
        ) : (
          <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item xs={5}>
              <section style={{ marginBottom: '32px' }}>
                <Typography
                  align="left"
                  style={{ color: 'grayDark', borderBottom: '1px solid grey' }}
                >
                  Baseline
                </Typography>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div style={{ color: styles.fill.baseline }}>
                    <Typography style={{ fontWeight: 600 }} variant="subtitle2">
                      Covered
                    </Typography>
                    <Typography
                      style={{ fontWeight: 600, fontSize: '1.5rem' }}
                    >{`${baselineCovered}%`}</Typography>
                  </div>
                  <div style={{ color: 'grayDark' }}>
                    <Typography style={{ fontWeight: 600 }} variant="subtitle2">
                      Not covered
                    </Typography>
                    <Typography
                      style={{ fontWeight: 600, fontSize: '1.5rem' }}
                    >{`${(100 - baselineCovered).toFixed(1)}%`}</Typography>
                  </div>
                </div>
              </section>
              <section>
                <Typography
                  align="left"
                  style={{
                    color: 'grayDark',
                    borderBottom: '1px solid gray',
                  }}
                >
                  Comparison
                </Typography>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div style={{ color: styles.fill.potential }}>
                    <Typography style={{ fontWeight: 600 }} variant="subtitle2">
                      Covered
                    </Typography>
                    <Typography
                      style={{ fontWeight: 600, fontSize: '1.5rem' }}
                    >{`${potentialCovered}%`}</Typography>
                  </div>
                  <div style={{ color: 'grayDark' }}>
                    <Typography style={{ fontWeight: 600 }} variant="subtitle2">
                      Not covered
                    </Typography>
                    <Typography
                      style={{ fontWeight: 600, fontSize: '1.5rem' }}
                    >{`${(100 - potentialCovered).toFixed(1)}%`}</Typography>
                  </div>
                </div>
              </section>
            </Grid>
            <Grid item xs={7}>
              <section>
                <Typography style={{ fontWeight: 600 }} align="center">
                  Overall Change
                </Typography>
                {getOverallChange()}
                <TestBarChart
                  data={barChartData}
                  oAccessor="type"
                  rAccessor="coverage"
                />
              </section>
            </Grid>
          </Grid>
        )}
      </main>
    </Card>
  )
}
