import * as VisualizeApi from 'lib/api/visualize'

export const saveReferenceAvatars = (projectId, spec, referencePkgId) => (
  dispatch
) => {
  VisualizeApi.saveReferenceAvatars(projectId, spec, referencePkgId)
    .then((result) => {
      dispatch({
        type: 'REFERENCE_AVATARS_SAVED',
        projectId: projectId,
        payload: result,
      })
    })
    .catch((error) => {
      console.log(error)
    })
}
