import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'

import { MEASUREMENTS } from 'lib/measurements'

const useStyles = makeStyles((theme) => ({
  sizesText: {
    marginBottom: theme.spacing(0.75),
  },
  chipsContainer: {
    display: 'flex',
    width: '100%',
    color: theme.palette.grey[50],
  },
  chip: {
    marginRight: theme.spacing(1),
    fontSize: 12,
  },
}))

export default ({ spec }) => {
  const classes = useStyles()
  return (
    <div>
      <Typography className={classes.sizesText} variant="body2">
        <b>Sizes:</b> {spec.sizeDefs.map(({ size }) => size).join(', ')}
      </Typography>
      <div className={classes.chipsContainer}>
        {spec.primaryMeasurements.map((measurement) => (
          <Chip
            key={measurement}
            className={classes.chip}
            variant="outlined"
            size="small"
            label={MEASUREMENTS[measurement].label}
          />
        ))}
      </div>
    </div>
  )
}
