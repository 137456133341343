import React from 'react'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'

import ScatterPlot from 'components/size_explorer/charts/ScatterPlot'
import LoadingSpinner from 'components/size_explorer/lib/LoadingSpinner'
import Reload from 'components/size_explorer/lib/Reload'

export default function ScatterPlots({
  primaryMeasurements,
  scatterplots = [],
  sizeData,
}) {
  return (
    <Grid container spacing={2}>
      {scatterplots.map((plot, idx) => {
        let body
        if (plot.data) {
          const { data, measurement, correlationMeasurement } = plot

          const annotationsTemplate = sizeData.map(
            ({ size, measurements, color }, i) => {
              if (
                measurements[measurement] &&
                measurements[correlationMeasurement]
              ) {
                const xMeasurements = measurements[correlationMeasurement]
                const yMeasurements = measurements[measurement]

                return {
                  type: 'myCustomType',
                  x: xMeasurements.min,
                  y: yMeasurements.max,
                  xDiff: xMeasurements.max - xMeasurements.min,
                  yDiff: yMeasurements.max - yMeasurements.min,
                  label: size,
                  color,
                }
              }
              return null
            }
          )

          if (data.length) {
            body = (
              <ScatterPlot
                plotName="fitCoverage"
                data={data}
                xAccessor={correlationMeasurement}
                yAccessor={measurement}
                styles={{
                  gradient: {
                    min: '#F1E0FF',
                    max: '#270b61',
                  },
                }}
                annotations={annotationsTemplate}
                increaseRadius={primaryMeasurements.length === 2}
              />
            )
          } else if (!plot.isFetching && data.length) {
            body = <Reload errorMessage="Failed to load data" />
          }
        }

        return (
          <Grid item key={`fit-coverage-plot-${idx}`} xs={12} md={4}>
            <Card style={{ padding: '20px 28px 0px 28px' }}>
              {plot.isFetching && (
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  style={{ height: 450 }}
                >
                  <LoadingSpinner />
                </Grid>
              )}
              {body}
            </Card>
          </Grid>
        )
      })}
    </Grid>
  )
}
