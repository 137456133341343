import React from 'react'
import { useDropzone } from 'react-dropzone'
import csv from 'csvtojson'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import Template from 'components/size_explorer/size_specs/csv/Template'

import { formatFraction } from 'lib/format'
import { isValidMeasurement, sortMeasurements } from 'lib/measurements'
import { unflattenSizeDefs } from 'lib/size_specs_form'

const useStyles = makeStyles((theme) => ({
  content: {
    paddingBottom: theme.spacing(2),
  },
  cancelButton: {
    marginLeft: 'auto',
    marginRight: theme.spacing(2),
  },
}))

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const activeStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

// TODO: rename setIsUploaded vs setUploadDone
const FileUploader = ({ onSpecUpdate, setMeasurementValues, onClose }) => {
  const classes = useStyles()

  const [isUploadDone, setUploadDone] = React.useState(false)
  const [measurements, setMeasurements] = React.useState([])
  const [myFiles, setMyFiles] = React.useState([])

  // Use CSV, should update sizeDefs: Array, primaryMeasurements: Array, and sizes: String ('S, M')
  const handleSubmit = () => {
    let primaryMeasurements = []
    if (measurements && measurements[0]) {
      Object.keys(measurements[0]).forEach((key) => {
        const name = key.substring(0, key.length - 3)
        if (isValidMeasurement(name) && !primaryMeasurements.includes(name)) {
          primaryMeasurements.push(name)
        }
      })

      primaryMeasurements = sortMeasurements(primaryMeasurements)
    }

    onSpecUpdate('sizeDefs', measurements)
    onSpecUpdate('primaryMeasurements', primaryMeasurements)
    onSpecUpdate('sizes', measurements.map(({ size }) => size).join(', '))
    setMeasurementValues(unflattenSizeDefs(measurements).measurementValues)
    onClose()
  }

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      const reader = new FileReader() // eslint-disable-line no-undef

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        const binaryStr = reader.result

        const tempMeasurements = [...measurements]
        const primaryMeasurements = []

        csv({
          output: 'csv',
          noheader: true,
        })
          .fromString(binaryStr)
          .subscribe((jsonObj, idx) => {
            // TODO: handle error for invalid primaryMeasurements (template) format (what constitutes as invalid?)
            /*
            0: [Name, '', '', '', '', '', '', '', '', '', units, in]
            1: ['Size', measurement, '', measurement, '', measurement, '', measurement, '', '', gender, genderVal]
            2: ['', min, max, min, max, min, max, min, max, ..., sizing category name, name]
            3+: [sizeVal, minVal, maxVal, minVal, maxVal, minVal, maxVal, minVal, maxVal, ...]
          */

            // generate primaryMeasurements (template) from scratch
            if (idx === 1) {
              for (let i = 1; i <= 5; i += 2) {
                if (isValidMeasurement(jsonObj[i])) {
                  primaryMeasurements.push(jsonObj[i])
                }
              }
            }

            if (idx >= 3 && !!jsonObj[0]) {
              const size = jsonObj[0]
              const sizeObj = {
                size,
              }

              // 1: iterate through primary measurement (doesn't matter what ppl put as the measurement in csv)
              // 2: corresponding jsonObj elements are min: ((i * 2) + 1) and max: (i * 2) + 2)
              for (let i = 0; i < primaryMeasurements.length; i++) {
                const measurement = primaryMeasurements[i]

                // formatting fractions allows for SOME user formatting error, such as extra spaces
                const min = formatFraction(jsonObj[i * 2 + 1])
                const max = formatFraction(jsonObj[i * 2 + 2])

                sizeObj[`${measurement}Min`] = min
                sizeObj[`${measurement}Max`] = max
              }
              tempMeasurements.push(sizeObj)
            }
          })
          .on('done', () => {
            setUploadDone(true)
            setMeasurements(tempMeasurements)
          })
      }

      acceptedFiles.forEach((file) => reader.readAsBinaryString(file))
      setMyFiles([...myFiles, ...acceptedFiles])
    },
    [measurements, myFiles]
  )

  // const removeAll = () => {
  //   setMyFiles([])
  // }

  const {
    // acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: '.csv',
    onDrop,
  })

  const style = React.useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragAccept, isDragActive, isDragReject]
  )

  const acceptedFilesItems = myFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ))

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          Use our CSV template file to input all of the details for your sizes
          and measurement ranges, then simply upload it and you're done!{' '}
          <Template />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <div style={{ marginBottom: 16 }}>
          <div className="container">
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <p>Drag 'n' drop your file here, or click to select a file</p>
              <em>(Only *.csv files will be accepted)</em>
            </div>
            {acceptedFilesItems.length > 0 && (
              <aside>
                <h4>Uploaded file:</h4>
                <ul>{acceptedFilesItems}</ul>
              </aside>
            )}
          </div>
        </div>
        <Grid container>
          <Button className={classes.cancelButton} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={!isUploadDone}
            onClick={handleSubmit}
          >
            Use CSV
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FileUploader
