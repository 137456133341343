import { combineReducers } from 'redux'

import {
  FETCH_AUDIENCE_START,
  FETCH_AUDIENCE_SUCCESS,
  FETCH_AUDIENCE_FAIL,
} from 'actions/audience_distribution'

import {
  FETCH_CORRELATION_DISTRIBUTION_START,
  FETCH_CORRELATION_DISTRIBUTION_SUCCESS,
  FETCH_CORRELATION_DISTRIBUTION_FAIL,
} from 'actions/audience_distribution_correlation'

const distribution = (state = {}, action) => {
  switch (action.type) {
    case 'PROJECT_CLEAR':
    case 'PROJECT_SET':
    case 'PROJECT_UPDATED':
    case 'PROJECT_UPDATED_AUDIENCE':
      return {}
    case FETCH_AUDIENCE_START:
      return {
        ...state,
        [action.measurement]: {
          audience: {},
          population: {},
          isFetching: true,
        },
      }
    case FETCH_AUDIENCE_SUCCESS:
      return {
        ...state,
        [action.measurement]: {
          audience: action.audience,
          population: action.population,
          isFetching: false,
        },
      }
    case FETCH_AUDIENCE_FAIL:
      return {
        ...state,
        [action.measurement]: {
          audience: {},
          population: {},
          isFetching: false,
        },
      }
    default:
      return state
  }
}

const correlationPlots = (state = [], action) => {
  switch (action.type) {
    case 'PROJECT_CLEAR':
    case 'SET_SIZE_SPEC':
    case 'SIZE_SPEC_UPDATED':
      return []
    case FETCH_CORRELATION_DISTRIBUTION_START: {
      const newState = [...state]
      const dataContainer = {
        measurement: action.measurement,
        correlationMeasurement: action.correlationMeasurement,
        data: [],
        isFetching: true,
      }

      newState.push(dataContainer)
      return newState
    }

    case FETCH_CORRELATION_DISTRIBUTION_SUCCESS: {
      const newState = state.map((dataContainer) => {
        const { measurement, correlationMeasurement } = dataContainer
        if (
          measurement === action.measurement &&
          correlationMeasurement === action.correlationMeasurement
        ) {
          return {
            measurement,
            correlationMeasurement,
            data: action.data, // update data from backend
            isFetching: false,
          }
        } else {
          return dataContainer
        }
      })

      return newState
    }

    case FETCH_CORRELATION_DISTRIBUTION_FAIL: {
      const newState = state.map((dataContainer) => {
        const { measurement, correlationMeasurement } = dataContainer
        if (
          measurement === action.measurement &&
          correlationMeasurement === action.correlationMeasurement
        ) {
          return {
            measurement,
            correlationMeasurement,
            data: [], // fetch failed, data is null
            isFetching: false,
          }
        } else {
          return dataContainer
        }
      })

      return newState
    }
    default:
      return state
  }
}

export default combineReducers({
  distribution,
  correlationPlots,
})
