import React from 'react'
import Grid from '@material-ui/core/Grid'
import AccountIcon from '@material-ui/icons/AccountCircle'
import Popover from '@material-ui/core/Popover'
import AccountManagerPopover from './AccountManagerPopover'

const AccountManager = () => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = (e) => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <Grid item>
      <AccountIcon
        onClick={handleClick}
        style={{ width: 25, height: 25, marginLeft: 16, cursor: 'pointer' }}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{ top: 30 }}
      >
        <AccountManagerPopover closePopover={() => setAnchorEl(null)} />
      </Popover>
    </Grid>
  )
}

export default AccountManager
