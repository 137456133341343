import React from 'react'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import Tooltip from '@material-ui/core/Tooltip'
import _ from 'lodash'

import { MEASUREMENTS, listBodyMeasurements } from 'lib/measurements'

const MAIN_MEASUREMENTS = [
  'chestBustGirth',
  'naturalWaistGirth',
  'hipSeatGirth',
  'midNeckGirth',
]

const MORE_MEASUREMENTS = _.difference(
  listBodyMeasurements(),
  MAIN_MEASUREMENTS
)

export default ({ spec, category, additionalFilters, onSpecUpdate }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const measurements = spec[category] // category === primaryMeasurements or secondaryMeasurements

  const options = [...MAIN_MEASUREMENTS, ...MORE_MEASUREMENTS].filter(
    (measurement) =>
      ![...spec.primaryMeasurements, ...spec.secondaryMeasurements].includes(
        measurement
      )
  )

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDelete = (measurement) => {
    const newMeasurements = [...measurements]
    _.remove(newMeasurements, (o) => o === measurement)

    onSpecUpdate(category, newMeasurements)
  }

  const handleMeasurementSelect = (measurement) => {
    // set default secondary filter to stddev +/- 1
    // NOTE: I want to move this elsewhere
    if (
      category === 'secondaryMeasurements' &&
      !additionalFilters[measurement]
    ) {
      additionalFilters[measurement] = {
        selected: 'std',
        type: 'std',
        min: -1,
        max: 1,
      }
    }
    onSpecUpdate(category, [...measurements, measurement])
    handleClose()
  }

  return (
    <Grid container alignItems="center" spacing={2}>
      {measurements.map((measurement) => (
        <Grid key={measurement} item>
          <Chip
            label={MEASUREMENTS[measurement].label}
            onDelete={() => handleDelete(measurement)}
          />
        </Grid>
      ))}
      {measurements.length < 3 && (
        <Grid item>
          <Tooltip title="Add" placement="right">
            <IconButton onClick={handleClick}>
              <AddCircleIcon color="primary" />
            </IconButton>
          </Tooltip>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {options.map((measurement) => (
              <MenuItem
                key={`${measurement}-option`}
                onClick={() => handleMeasurementSelect(measurement)}
                value={measurement}
              >
                {MEASUREMENTS[measurement].label}
              </MenuItem>
            ))}
          </Menu>
        </Grid>
      )}
    </Grid>
  )
}
