import React from 'react'
import { useTheme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import CSVUploadForm from 'components/size_explorer/size_specs/csv/CSVUpload'
import AdditionalOptions from 'components/size_explorer/size_specs/forms/AdditionalOptions'
import TableContainer from 'components/size_explorer/size_specs/forms/SizeTableContainer'
import Table from 'components/size_explorer/size_specs/forms/SizeTable'

import { MEASUREMENTS } from 'lib/measurements'

export default ({
  spec,
  measurementValues,
  setMeasurementValues,
  additionalFilters,
  setAdditionalFilters,
  sizes,
  onSpecUpdate,
  setIsDirty,
}) => {
  const theme = useTheme()

  const { primaryMeasurements, secondaryMeasurements, sizeDefs } = spec

  // we use measurementIdx bc material-ui tabs take in numeric values
  const [measurementIdx, setMeasurementIdx] = React.useState(0)
  const [additionalOption, setAdditionalOption] = React.useState('absolute')

  const measurements = [...primaryMeasurements, ...secondaryMeasurements]
  const measurement = measurements[measurementIdx]
  const measurementType = primaryMeasurements.includes(measurement)
    ? 'primaryMeasurement'
    : 'secondaryMeasurement'

  React.useEffect(() => {
    const combined = [...primaryMeasurements, ...secondaryMeasurements]
    if (combined.length && measurementIdx >= combined.length) {
      setMeasurementIdx(combined.length - 1)
    }
  }, [measurementIdx, primaryMeasurements, secondaryMeasurements])

  // if someone selects secondary measurements first, we need to default the selected option to std
  React.useEffect(() => {
    if (
      measurementType === 'secondaryMeasurement' &&
      additionalFilters[measurement]
    ) {
      setAdditionalOption(additionalFilters[measurement].type) // default = std
    }
  }, [additionalFilters, measurement, measurementType, secondaryMeasurements])

  const handleChange = (size, key, value, idx) => {
    setIsDirty(true)
    setMeasurementValues({
      ...measurementValues,
      [size]: {
        ...measurementValues[size],
        [key]: value,
      },
    })
  }

  // updating secondary measurement filter min/max when type === 'mean/std'
  const handleDynamicRangeChange = (type, value) => {
    setIsDirty(true)
    setAdditionalFilters({
      ...additionalFilters,
      [measurement]: {
        type,
        min: -Math.abs(value),
        max: Number(value),
      },
    })
  }

  // change measurements on filters
  const handleMeasurementChange = (idx) => {
    setMeasurementIdx(idx)

    // set option to mean/std based on stored filter
    if (additionalFilters[measurements[idx]]) {
      setAdditionalOption(additionalFilters[measurements[idx]].type)
    } else {
      setAdditionalOption('absolute')
    }
  }

  const handleTypeChange = (e) => {
    // bc our filters are stateful, we need a way to identify which type of filter we want to use (absolute, mean, or std?)
    // to do so, we pass a key/variable pair to our additionalFilter data structure to know which one is "selected"
    if (measurementType === 'secondaryMeasurement') {
      setAdditionalFilters({
        ...additionalFilters,
        [measurement]: {
          ...additionalFilters[measurement],
          selected: e.target.value,
        },
      })
    }

    setAdditionalOption(e.target.value)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ marginBottom: theme.spacing(1) }}>
        <TableContainer
          measurements={
            measurements.length > 0
              ? measurements.map((msmt) => MEASUREMENTS[msmt].label)
              : ['Measurement']
          }
          idx={measurementIdx > 0 ? measurementIdx : 0}
          onMeasurementChange={handleMeasurementChange}
          tableComponent={
            additionalOption === 'absolute' ? (
              <Table
                sizes={sizes}
                measurement={measurement}
                sizeDefs={sizeDefs}
                measurementValues={measurementValues}
                hasMeasurements={measurements.length > 0}
                onTableChange={handleChange}
              />
            ) : null
          }
          additionalOptions={
            <AdditionalOptions
              additionalOption={additionalOption}
              setAdditionalOption={setAdditionalOption}
              filter={additionalFilters[measurement]}
              measurementType={measurementType}
              onDynamicRangeChange={handleDynamicRangeChange}
              onTypeChange={handleTypeChange}
            />
          }
          csvUploadComponent={
            <CSVUploadForm
              spec={spec}
              onSpecUpdate={onSpecUpdate}
              setMeasurementValues={setMeasurementValues}
            />
          }
        />
      </Grid>
    </Grid>
  )
}
