import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'

const Star = ({ referencePackageId, packageId, onClick }) => {
  const isStarred = referencePackageId === packageId

  const Icon = isStarred ? StarIcon : StarBorderIcon

  const handleClick = (e) => {
    e.stopPropagation()
    if (!isStarred) {
      onClick(packageId)
    }
  }

  return (
    <Tooltip title={isStarred ? 'Reference avatar' : 'Set as reference avatar'}>
      <Icon onClick={handleClick} />
    </Tooltip>
  )
}

export default Star
