import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'

import Notification from 'components/lib/Notification'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  legend: {
    display: 'flex',
    alignItems: 'center',
  },
  legendLabel: {
    marginLeft: theme.spacing(1),
  },
}))

const GlobalSwitch = ({ displayGlobal, onGlobalToggle }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div>
        <div className={classes.legend}>
          <Notification />
          <Typography variant="caption" className={classes.legendLabel}>
            Indicates auto selected avatar
          </Typography>
        </div>
        <div className={classes.legend}>
          <Notification style={{ background: '#2b907f' }} />
          <Typography variant="caption" className={classes.legendLabel}>
            Indicates global avatar
          </Typography>
        </div>
      </div>
      <FormControlLabel
        control={
          <Switch
            checked={displayGlobal}
            onChange={onGlobalToggle}
            name="checkedB"
            color="primary"
          />
        }
        label="Include global avatars"
      />
    </div>
  )
}

export default GlobalSwitch
