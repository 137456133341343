import * as RestAPI from '../rest_api'

export const addBookmark = async (bookmark) => {
  return RestAPI.POST('/bookmarks', bookmark)
}

export const removeBookmark = async (pkgId) => {
  return RestAPI.DELETE('/bookmarks/' + pkgId)
}

export const getBookmarkList = async () => {
  return RestAPI.GET('/bookmarks')
}
