import * as Units from 'lib/units'

// master set of exposed measurements
export const MEASUREMENTS = {
  acrossBackShoulderWidth: {
    id: 'acrossBackShoulderWidth',
    isBodyMeasurement: true,
    isCoreMeasurement: false,
    label: 'Across Back Shoulder Width',
    order: 7,
    range90th: [35, 47],
    scale: [10, 75],
    unitType: 'length',
  },
  acrossFrontShoulderWidth: {
    id: 'acrossFrontShoulderWidth',
    isBodyMeasurement: true,
    isCoreMeasurement: false,
    label: 'Across Front Shoulder Width',
    order: 8,
    range90th: [35, 47],
    scale: [10, 75],
    unitType: 'length',
  },
  age: {
    id: 'age',
    isBodyMeasurement: false,
    isCoreMeasurement: false,
    label: 'Age',
    order: 0,
    range90th: [18, 65],
    scale: [0, 125],
    unitType: 'year',
  },
  ankleGirth: {
    id: 'ankleGirth',
    isBodyMeasurement: true,
    isCoreMeasurement: false,
    label: 'Ankle Girth',
    order: 22,
    range90th: [24, 35],
    scale: [1, 80],
    unitType: 'length',
  },
  armLength: {
    id: 'armLength',
    isBodyMeasurement: true,
    isCoreMeasurement: false,
    label: 'Arm Length',
    order: 12,
    range90th: [50, 61],
    scale: [20, 85],
    unitType: 'length',
  },
  armscyeGirth: {
    id: 'armscyeGirth',
    isBodyMeasurement: true,
    isCoreMeasurement: false,
    label: 'Armscye Girth',
    order: 10,
    range90th: [40, 54],
    scale: [18, 125],
    unitType: 'length',
  },
  bmi: {
    id: 'bmi',
    isBodyMeasurement: false,
    isCoreMeasurement: false,
    label: 'Body Mass Index (BMI)',
    order: 0,
    range90th: [21, 35],
    scale: [0, 100],
    unitType: 'none',
  },
  bodyComposition: {
    id: 'bodyComposition',
    isBodyMeasurement: false,
    isCoreMeasurement: false,
    label: 'Body Composition',
    order: 0,
    range90th: [20, 43],
    scale: [1, 60],
    unitType: 'percentage',
  },
  calfGirth: {
    id: 'calfGirth',
    isBodyMeasurement: true,
    isCoreMeasurement: false,
    label: 'Calf Girth',
    order: 21,
    range90th: [35, 46],
    scale: [25, 95],
    unitType: 'length',
  },
  cervicalToWristLength: {
    id: 'cervicalToWristLength',
    isBodyMeasurement: true,
    isCoreMeasurement: false,
    label: 'Cervical to Wrist Length',
    order: 13,
    range90th: [69, 83],
    scale: [35, 110],
    unitType: 'length',
  },
  chestBustGirth: {
    id: 'chestBustGirth',
    isBodyMeasurement: true,
    isCoreMeasurement: true,
    label: 'Chest/Bust Girth',
    order: 2,
    range90th: [90, 122],
    scale: [60, 175],
    unitType: 'length',
  },
  crotchHeight: {
    id: 'crotchHeight',
    isBodyMeasurement: true,
    isCoreMeasurement: false,
    label: 'Crotch Height',
    order: 17,
    range90th: [66, 79],
    scale: [45, 105],
    unitType: 'length',
  },
  crotchLength: {
    id: 'crotchLength',
    isBodyMeasurement: true,
    isCoreMeasurement: false,
    label: 'Crotch Length',
    order: 18,
    range90th: [60, 84],
    scale: [15, 135],
    unitType: 'length',
  },
  forearmGirth: {
    id: 'forearmGirth',
    isBodyMeasurement: true,
    isCoreMeasurement: false,
    label: 'Forearm Girth',
    order: 11,
    range90th: [23, 32],
    scale: [15, 80],
    unitType: 'length',
  },
  height: {
    id: 'height',
    isBodyMeasurement: false,
    isCoreMeasurement: false,
    label: 'Height',
    order: 0,
    range90th: [157, 183],
    scale: [140, 210],
    unitType: 'length',
  },
  hipSeatGirth: {
    id: 'hipSeatGirth',
    isBodyMeasurement: true,
    isCoreMeasurement: true,
    label: 'Hip/Seat Girth',
    order: 6,
    range90th: [94, 122],
    scale: [70, 180],
    unitType: 'length',
  },
  kneeGirth: {
    id: 'kneeGirth',
    isBodyMeasurement: true,
    isCoreMeasurement: false,
    label: 'Knee Girth',
    order: 20,
    range90th: [35, 45],
    scale: [25, 75],
    unitType: 'length',
  },
  inseam: {
    id: 'inseam',
    isBodyMeasurement: true,
    isCoreMeasurement: false,
    label: 'Inseam',
    order: 16,
    range90th: [67, 80],
    scale: [50, 105],
    unitType: 'length',
  },
  midNeckGirth: {
    id: 'midNeckGirth',
    isBodyMeasurement: true,
    isCoreMeasurement: false,
    label: 'Neck Girth',
    order: 1,
    range90th: [32, 44],
    scale: [10, 85],
    unitType: 'length',
  },
  naturalWaistGirth: {
    id: 'naturalWaistGirth',
    isBodyMeasurement: true,
    isCoreMeasurement: true,
    label: 'Natural Waist Girth',
    order: 4,
    range90th: [71, 107],
    scale: [50, 180],
    unitType: 'length',
  },
  outseam: {
    id: 'outseam',
    isBodyMeasurement: true,
    isCoreMeasurement: false,
    label: 'Outseam',
    order: 15,
    range90th: [92, 108],
    scale: [55, 135],
    unitType: 'length',
  },
  preferredWaistGirth: {
    id: 'preferredWaistGirth',
    isBodyMeasurement: true,
    isCoreMeasurement: false,
    label: 'Preferred Waist Girth',
    order: 5,
    range90th: [78, 111],
    scale: [55, 175],
    unitType: 'length',
  },
  thighGirth: {
    id: 'thighGirth',
    isBodyMeasurement: true,
    isCoreMeasurement: false,
    label: 'Thigh Girth',
    order: 19,
    range90th: [55, 71],
    scale: [35, 105],
    unitType: 'length',
  },
  trunkLength: {
    id: 'trunkLength',
    isBodyMeasurement: true,
    isCoreMeasurement: false,
    label: 'Trunk Length',
    order: 14,
    range90th: [142, 173],
    scale: [100, 255],
    unitType: 'length',
  },
  underBustGirth: {
    id: 'underBustGirth',
    isBodyMeasurement: true,
    isCoreMeasurement: false,
    label: 'Under Bust Girth',
    order: 3,
    range90th: [76, 111],
    scale: [55, 170],
    unitType: 'length',
  },
  upperArmGirth: {
    id: 'upperArmGirth',
    isBodyMeasurement: true,
    isCoreMeasurement: false,
    label: 'Upper Arm Girth',
    order: 9,
    range90th: [28, 41],
    scale: [15, 100],
    unitType: 'length',
  },
  weight: {
    id: 'weight',
    isBodyMeasurement: false,
    isCoreMeasurement: false,
    label: 'Weight',
    order: 0,
    range90th: [58, 103],
    scale: [20, 250],
    unitType: 'mass',
  },
}

export function getMeasurementLabel(measurement) {
  const config = MEASUREMENTS[measurement]
  return config ? config.label : 'Unknown'
}

export function getMeasurementUnit(measurement, unitSystem) {
  const config = MEASUREMENTS[measurement]

  if (config) {
    const unitType = config.unitType || 'length'

    // we need to consider both body measurements and other measures
    switch (unitType) {
      case 'length':
        return unitSystem === 'metric' ? 'cm' : 'in'
      case 'mass':
        return unitSystem === 'metric' ? 'kg' : 'lb'
      case 'year':
        return 'years'
      case 'percentage':
        return '%'
      default:
        return ''
    }
  }
}

export function getRange90th(measurement, unitSystem) {
  const config = MEASUREMENTS[measurement]

  if (config) {
    if (unitSystem === 'metric') {
      return [...config.range90th]
    } else {
      // convert to US units
      const unitType = config.unitType || 'length'
      const origRange = [...config.range90th]

      if (unitType === 'length') {
        // cm -> in
        return origRange.map((v) => Math.round(Units.cmToIn(v)))
      } else if (unitType === 'mass') {
        // kg -> lb
        return origRange.map((v) => Math.round(Units.kgToLb(v)))
      } else {
        return origRange
      }
    }
  }
}

export function getScale(measurement, unitSystem) {
  const config = MEASUREMENTS[measurement]

  if (config) {
    if (unitSystem === 'metric') {
      return [...config.scale]
    } else {
      // convert to US units
      const unitType = config.unitType || 'length'
      const origScale = [...config.scale]

      if (unitType === 'length') {
        // cm -> in
        return origScale.map((v) => Math.round(Units.cmToIn(v)))
      } else if (unitType === 'mass') {
        // kg -> lb
        return origScale.map((v) => Math.round(Units.kgToLb(v)))
      } else {
        return origScale
      }
    }
  }
}

export function isValidMeasurement(measurement) {
  return Boolean(MEASUREMENTS[measurement])
}

export function listBodyMeasurements() {
  return Object.values(MEASUREMENTS)
    .filter((m) => m.isBodyMeasurement)
    .map((m) => m.id)
}

export const sortMeasurements = (measurements) => {
  return measurements.sort((a, b) => {
    const measurementA = MEASUREMENTS[a] || { order: -100 }
    const measurementB = MEASUREMENTS[b] || { order: -100 }

    return measurementA.order - measurementB.order
  })
}
