import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Slider from '@material-ui/core/Slider'
import ThreeDRotationIcon from '@material-ui/icons/ThreeDRotation'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  hiddenIcon: {
    marginLeft: theme.spacing(2),
    opacity: 0, // make the icon transparent
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  slider: {
    width: '100%',
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
}))

// NOTE: sliding through angles too quickly can result in race conditions avatar image fetch
export default ({ angle, onAngleSelect, padRight = false }) => {
  const classes = useStyles()

  const marks = [
    {
      value: 0,
      label: 'Front',
    },
    {
      value: 90,
      label: 'Side',
    },
    {
      value: 180,
      label: 'Back',
    },
  ]

  const handleChange = (event, value) => {
    // this converts from an int to a zero padded angle that we use
    const val = value === 0 ? '000' : value === 90 ? '090' : '180'

    if (val !== angle) {
      onAngleSelect(val)
    }
  }

  return (
    <div className={classes.root}>
      <ThreeDRotationIcon className={classes.icon} fontSize="small" />
      <Slider
        defaultValue={parseInt(angle)}
        aria-labelledby="angle-select"
        valueLabelDisplay="off"
        step={90}
        marks={marks}
        min={0}
        max={180}
        className={classes.slider}
        onChangeCommitted={handleChange}
      />
      {/* This is here purely to maintain a balanced width with the slider properly centered */}
      {padRight ? (
        <ThreeDRotationIcon className={classes.hiddenIcon} fontSize="small" />
      ) : null}
    </div>
  )
}
