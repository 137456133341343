import React from 'react'
import Typography from '@material-ui/core/Typography'

export default function ScanStats({ count, coverage }) {
  const formattedCoverage =
    Math.round((coverage * 100 + Number.EPSILON) * 100) / 100

  return (
    <Typography variant="caption">
      {count} scans ({formattedCoverage}%)
    </Typography>
  )
}
