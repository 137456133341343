import _ from 'lodash'
import moment from 'moment'
import Papa from 'papaparse'
import React from 'react'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import LoadingSpinner from 'components/size_explorer/lib/LoadingSpinner'
import CSVDownloadButton from 'components/size_explorer/lib/CSVDownloadButton'
import * as SizeSpecSummaryActions from 'actions/sizespec_distribution'
import {
  getMeasurementLabel,
  listBodyMeasurements,
  sortMeasurements,
} from 'lib/measurements'
import { sizeListSelector, extendedSizesSelector } from 'selectors/size_specs'

const measurements = [...listBodyMeasurements(), 'height']
const sortedMeasurements = sortMeasurements(measurements)

const tableHeaderStyle = {
  fontWeight: 600,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const makeExtendedChartCsv = (sizesList, extendedSizesList, extendedSizes) => {
  const data = extendedSizesList.map((measurement) => ({
    Measure: getMeasurementLabel(measurement),
    ..._.mapValues(extendedSizes[measurement], (val) =>
      val ? Number(val.mean).toFixed(1) : ''
    ),
  }))

  return Papa.unparse({
    fields: ['Measure', ...sizesList],
    data,
  })
}

const ExtendedSizeChart = ({
  extendedSizes,
  fetchSizeSpecSummaries,
  project,
  selectedSpecId,
  showHeading = true,
  sizesList,
}) => {
  React.useEffect(() => {
    if (!extendedSizes) {
      fetchSizeSpecSummaries(
        {
          population: project.population,
          audience: {
            filters: project.audience,
          },
          sizeDefs: project.sizeSpecs[selectedSpecId].sizeDefs,
          units: project.units,
        },
        measurements
      )
    }
  }, [fetchSizeSpecSummaries, extendedSizes, project, selectedSpecId])

  const dataLoading = !extendedSizes

  const createCSVContent = () => {
    return makeExtendedChartCsv(sizesList, sortedMeasurements, extendedSizes) // TODO: fix this
  }

  return (
    <>
      {showHeading ? (
        <Grid container justify="space-between">
          <Typography style={{ fontWeight: 600 }} variant="h5">
            Extended Size Chart
          </Typography>
          <CSVDownloadButton
            fileName={`extended-sizes-${moment().format()}.csv`}
            canDownload={
              extendedSizes &&
              !_.some(Object.values(extendedSizes), (data) => _.isEmpty(data))
            }
            createCSVContent={createCSVContent}
          />
        </Grid>
      ) : null}

      <Paper>
        <Grid
          container
          style={{ padding: '8px 12px', borderBottom: '2px black solid' }}
        >
          <Grid style={{ ...tableHeaderStyle, width: '25%' }} align="center">
            Size
          </Grid>
          {sizesList.map((size) => (
            <Grid
              key={`extended-header-${size}`}
              style={{
                ...tableHeaderStyle,
                width: `${75 / sizesList.length}%`,
              }}
              align="center"
            >
              {size}
            </Grid>
          ))}
        </Grid>
        {dataLoading ? (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ padding: '18px', minHeight: '120px' }}
          >
            <LoadingSpinner />
          </Grid>
        ) : (
          <>
            {sortedMeasurements.map((measurement) => (
              <Grid
                container
                key={measurement}
                style={{
                  padding: '12px 12px',
                  borderBottom: '1px #ddd solid',
                }}
              >
                <Grid style={{ width: '25%' }}>
                  {getMeasurementLabel(measurement)}
                </Grid>

                {sizesList.map((size) => (
                  <Grid
                    key={`extended-row-${size}`}
                    style={{ width: `${75 / sizesList.length}%` }}
                    align="center"
                  >
                    {extendedSizes[measurement] &&
                    extendedSizes[measurement][size]
                      ? Number(extendedSizes[measurement][size].mean).toFixed(1)
                      : ''}
                  </Grid>
                ))}
              </Grid>
            ))}
          </>
        )}
      </Paper>
    </>
  )
}

const mapStateToProps = (state) => ({
  project: state.project.entities[state.project.selected],
  selectedSpecId: state.project.selectedSizeSpecId,
  sizesList: sizeListSelector(state),
  extendedSizes: extendedSizesSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  fetchSizeSpecSummaries: (params, measurements) =>
    dispatch(
      SizeSpecSummaryActions.fetchSizeSpecSummaries(params, measurements)
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(ExtendedSizeChart)
