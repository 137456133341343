import * as FitCoverageActions from 'actions/fit_coverage'
import * as AudienceActions from 'actions/audience_distribution_correlation_compare'

export const SET_SIZE_SPEC = 'SET_SIZE_SPEC'
export const SET_SIZE_SPEC_COMPARE_BASELINE = 'SET_SIZE_SPEC_COMPARE_BASELINE'
export const SET_SIZE_SPEC_COMPARE_POTENTIAL = 'SET_SIZE_SPEC_COMPARE_POTENTIAL'

export const setSizeSpec = (projectId, sizeSpecId) => ({
  type: 'SET_SIZE_SPEC',
  projectId,
  sizeSpecId,
})

export const clearSizeSpecCompareMode = () => ({
  type: 'CLEAR_SIZE_SPEC_COMPARE',
})

// THUNK
export const setSizeSpecCompareMode = (compareSpec) => (dispatch, getState) => {
  const state = getState()
  const project = state.project.entities[state.project.selected]
  const sizeSpec = project.sizeSpecs[state.project.selectedSizeSpecId]

  dispatch({
    type: 'SET_SIZE_SPEC_COMPARE_POTENTIAL',
    sizeSpecId: compareSpec.id,
  })

  dispatch(
    FitCoverageActions.fetchFitCoverageCompareMode('baseline')(
      sizeSpec.sizeDefs
    )
  )

  dispatch(
    FitCoverageActions.fetchFitCoverageCompareMode('potential')(
      compareSpec.sizeDefs
    )
  )

  dispatch(
    AudienceActions.fetchAllCorrelationDistributions({
      population: project.population,
      audienceFilter: project.audience,
      units: project.units,
      measurements: sizeSpec.primaryMeasurements,
    })
  )
}
