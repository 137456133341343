import React from 'react'
import TextField from '@material-ui/core/TextField'

export default ({ spec, onSpecUpdate }) => {
  const error = ''
  return (
    <TextField
      variant="outlined"
      id="sizes"
      label="Sizes"
      placeholder="e.g. S, M, L, XL"
      multiline
      rows={2}
      value={spec.sizes || ''}
      style={{ marginBottom: 16, width: '100%' }}
      onChange={(e) => onSpecUpdate('sizes', e.target.value)}
      error={!!error}
      helperText={
        error ||
        'Order sizes from smallest (left) to largest (right) and separate by commas'
      }
    />
  )
}
