import { combineReducers } from 'redux'

import {
  FETCH_CONTEXT,
  FETCH_CONTEXT_SUCCESS,
  FETCH_CONTEXT_FAILURE,
} from 'actions/context'

const isUpdatingCounts = (state = false, action) => {
  switch (action.type) {
    case FETCH_CONTEXT:
      return true
    case FETCH_CONTEXT_SUCCESS:
    case FETCH_CONTEXT_FAILURE:
      return false
    default:
      return state
  }
}

const populationCount = (state = null, action) => {
  switch (action.type) {
    case FETCH_CONTEXT_SUCCESS:
      return action.payload.populationCount
    case FETCH_CONTEXT_FAILURE:
      return null
    default:
      return state
  }
}

const audienceCount = (state = null, action) => {
  switch (action.type) {
    case FETCH_CONTEXT_SUCCESS:
      return action.payload.audienceCount
    case FETCH_CONTEXT_FAILURE:
      return null
    default:
      return state
  }
}

export default combineReducers({
  isUpdatingCounts,
  populationCount,
  audienceCount,
})
