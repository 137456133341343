// String conversions
export const capitalize = (str) =>
  str ? str.charAt(0).toUpperCase() + str.slice(1) : null

export const convertToFloat = (val) =>
  !isNaN(parseFloat(val)) ? parseFloat(val) : null

export const parseCamelCase = (string) => {
  if (string === 'chestBustGirth') {
    return 'Chest/Bust Girth'
  }
  if (string === 'hipSeatGirth') {
    return 'Hip/Seat Girth'
  }

  const result = string.replace(/([A-Z])/g, ' $1')
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1)
  return finalResult
}

export const camelize = (str) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    })
    .replace(/\s+/g, '')
}

export const formatNumber = (num) => {
  if (num === null || num === undefined) {
    return ''
  }
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

// num is in the format of a string
export const formatFraction = (num) => {
  let float = num.match(/\d+\.?\d+/)

  const fraction = num.match(/\d+\s*\/\s*\d+/g)

  let decimal = 0

  if (float && float[0]) float = parseFloat(float[0])

  if (fraction && fraction[0]) {
    const [a, b] = fraction[0].replace(/\s/g, '').split('/')
    decimal = parseInt(a) / parseInt(b)
  }

  return float + decimal
}

export const formatHeight = (inches) => {
  inches = parseInt(inches)
  const feet = Math.floor(inches / 12)
  const remainder = inches % 12

  return `${feet}ft ${remainder}in`
}

// formats the value to be react-select friendly
export const selectify = (value) => ({
  label: value,
  value,
})

export const formatAudienceInput = (input) => {
  const minMaxParams = [
    'age',
    'bodyComposition',
    'chestBustGirth',
    'height',
    'hipSeatGirth',
    'naturalWaistGirth',
    'weight',
  ]
  // const singularParams = ['gender', 'addressCountry']

  const minMaxes = minMaxParams
    .map((minMax) => {
      const minKey = minMax + 'Min'
      const maxKey = minMax + 'Max'

      const summary = { name: parseCamelCase(minMax) }
      if (input[minKey] || input[maxKey]) {
        if (input[minKey] && !input[maxKey]) {
          summary.value = '' + input[minKey] + '+'
        } else if (input[maxKey] && !input[minKey]) {
          summary.value = `0-${input[maxKey]}`
        } else {
          summary.value = '' + input[minKey] + '-' + input[maxKey]
        }

        return summary
      } else {
        return null
      }
    })
    .filter((val) => val !== null)

  // let singulars = singularParams.map(param => ({
  //   name: parseCamelCase(param),
  //   value: input[param]
  // })).filter(val => val.value !== null)

  // const singulars = singularParams.map(param => {
  //   if (param === 'addressCountry') {
  //     return {
  //       name: 'Country',
  //       value: input[param]
  //     }
  //   }

  //   return {
  //     name: parseCamelCase(param),
  //     value: input[param]
  //   }
  // }).filter(val => val.value !== null)

  // return [ ...singulars, ...minMaxes ]
  return [...minMaxes] // remove gender & country from Audience Context filter summary
}
