import _ from 'lodash'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import SearchIcon from '@material-ui/icons/Search'

import SearchSlider from 'components/avatars/search/SearchSlider'
import * as Measurements from 'lib/measurements'
import { Typography } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  filterButton: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    textAlign: 'center',
  },
  filterInput: {},
  filterLabel: {
    fontWeight: 600,
    textAlign: 'right',
  },
}))

function SearchInputForm({
  isLoading,
  onSubmit,
  population,
  project,
  sizeSpec,
  sizeDef,
}) {
  const classes = styles()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [filters, setFilters] = React.useState({})
  const [measurements, setMeasurements] = React.useState([])

  React.useEffect(() => {
    if (sizeSpec) {
      const measurements = _.uniq([
        ...sizeSpec.secondaryMeasurements,
        'height',
        'inseam',
        'armLength',
      ])

      setMeasurements(measurements)
      setFilters(
        measurements.reduce(
          (acc, measurement) => ({
            ...acc,
            [measurement]: Measurements.getRange90th(
              measurement,
              project.units
            ) || [0, 100],
          }),
          {}
        )
      )
    }
  }, [project, sizeSpec])

  const handleAddMeasurement = (measurement) => {
    setMeasurements([...measurements, measurement])
    setFilters({
      ...filters,
      [measurement]: Measurements.getRange90th(measurement, project.units) || [
        0,
        100,
      ],
    })
    // after adding a measurement close the menu
    setAnchorEl(null)
  }

  const handleFilterChange = (measurement, value) => {
    setFilters({
      ...filters,
      [measurement]: value,
    })
  }

  const handleSearch = () => {
    // our range filters need to be built into our object style filters with Min/Max props
    const rangeFiltersArr = Object.keys(filters)
      .map((filter) => ({
        [`${filter}Min`]: filters[filter][0],
        [`${filter}Max`]: filters[filter][1],
      }))
      .flat()
    const rangeFiltersObj = Object.assign({}, ...rangeFiltersArr)

    const query = {
      population: project.population,
      audience: {
        filters: project.audience,
      },
      sizeDef: {
        ...sizeDef,
        ...rangeFiltersObj,
      },
      gender: population.filters.gender,
      units: project.units,
      primaryMeasurements: sizeSpec.primaryMeasurements,
      secondaryMeasurements: measurements,
    }

    onSubmit(query)
  }

  return (
    <Grid container spacing={3} alignItems="center">
      {sizeSpec &&
        sizeDef &&
        sizeSpec.primaryMeasurements.map((primaryMeasurement) => {
          const label = Measurements.getMeasurementLabel(primaryMeasurement)
          const range = [
            sizeDef[`${primaryMeasurement}Min`],
            sizeDef[`${primaryMeasurement}Max`],
          ]

          return (
            <React.Fragment key={`label-${primaryMeasurement}`}>
              <Grid item xs={6} className={classes.filterLabel}>
                {label}
              </Grid>
              <Grid item xs={6} className={classes.filterInput}>
                <Typography variant="caption" color="textSecondary">
                  {range[0]} - {range[1]}
                </Typography>
              </Grid>
            </React.Fragment>
          )
        })}

      {measurements.map((id) => {
        const label = Measurements.getMeasurementLabel(id)
        const scale = Measurements.getScale(id, project.units) || [
          undefined,
          undefined,
        ]
        const range = filters[id] || [undefined, undefined]

        return (
          <React.Fragment key={`label-${id}`}>
            <Grid item xs={6} className={classes.filterLabel}>
              {label}
            </Grid>
            <Grid item xs={6} className={classes.filterInput}>
              <SearchSlider
                value={range}
                valueLabelDisplay="on"
                min={scale[0]}
                max={scale[1]}
                onChange={(e, value) => handleFilterChange(id, value)}
              />
            </Grid>
          </React.Fragment>
        )
      })}

      <Grid item xs={12}>
        <Grid container className={classes.filterButton} justify="space-evenly">
          <Button
            variant="contained"
            size="small"
            startIcon={<AddIcon />}
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            Add Filter
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            {_.difference(
              Object.keys(Measurements.MEASUREMENTS),
              measurements,
              sizeSpec && sizeSpec.primaryMeasurements
            ).map((measurement) => (
              <MenuItem
                key={measurement}
                onClick={() => handleAddMeasurement(measurement)}
                value={measurement}
              >
                {Measurements.MEASUREMENTS[measurement].label}
              </MenuItem>
            ))}
          </Menu>

          <Button
            variant="contained"
            size="small"
            color="primary"
            startIcon={<SearchIcon />}
            disabled={isLoading}
            onClick={handleSearch}
          >
            Find Avatars
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SearchInputForm
