import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FolderIcon from '@material-ui/icons/FolderOutlined'
import LibraryIcon from '@material-ui/icons/GridOn'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import SearchIcon from '@material-ui/icons/Search'

const useStyles = makeStyles((theme) => ({
  listItemIcon: {
    minWidth: '1em', // override minWidth: 56px
    marginLeft: '1.25rem',
    marginRight: theme.spacing(2),
  },
  icon: {
    width: '0.85em',
    height: '0.85em',
  },
  root: {
    height: 'calc(100vh)',
    backgroundColor: theme.palette.common.white,
    borderRight: '1px solid #ddd',
    paddingTop: theme.spacing(2),
  },
  lowerSection: {
    paddingTop: theme.spacing(2),
  },
  topSection: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}))

export default ({ onChange, page }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <List disablePadding>
        <section className={classes.topSection}>
          <ListItem
            button
            disableGutters
            selected={page === 'library'}
            onClick={() => onChange('library')}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <LibraryIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary="Library" style={{ overflowX: 'auto' }} />
          </ListItem>
          <ListItem
            button
            disableGutters
            selected={page === 'search'}
            onClick={() => onChange('search')}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <SearchIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary="Search" style={{ overflowX: 'auto' }} />
          </ListItem>
          <ListItem
            button
            disableGutters
            selected={page === 'sizing'}
            onClick={() => onChange('sizing')}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <FolderIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              primary="Sizing Search"
              style={{ overflowX: 'auto' }}
            />
          </ListItem>
        </section>
      </List>
    </div>
  )
}
