import * as ScanAPI from 'lib/api/scan'

// THUNK
export const fetchScan = (pkgId, angle) => (dispatch) => {
  dispatch({ type: 'SCANPNG_LOADING', pkgId, angle })
  ScanAPI.fetchImage(pkgId, angle)
    .then((pngScan) => {
      const scanPng = URL.createObjectURL(pngScan)
      dispatch({ type: 'SCANPNG_CACHE', pkgId, angle, scanPng })
    })
    .catch((err) => {
      console.log('Error fetching scan image: ', err)
      dispatch({ type: 'SCANPNG_FAIL', pkgId, angle })
    })
}
