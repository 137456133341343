import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit'

import SpecSummary from 'components/size_explorer/presentation/SpecSummary'
import FitCoverage from 'components/size_explorer/presentation/FitCoverage'
import ReferenceAvatars from 'components/size_explorer/presentation/ReferenceAvatars'
import ExtendedSizeChart from 'components/size_explorer/fit_coverage/ExtendedSizeChart'

import { selectedPopulation } from 'selectors/populations'

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: theme.spacing(1),
  },
  icon: {
    cursor: 'pointer',
  },
}))

const PresentationIndex = ({
  fullscreen,
  project,
  population,
  selectedSpecId,
  fitCoverageData,
  toggleFullscreen,
  sizesList,
  extendedSizes,
}) => {
  const classes = useStyles()
  const selectedSpec = project.sizeSpecs[selectedSpecId]

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Grid className={classes.header} container justify="space-between">
          <Typography variant="h5" style={{ fontWeight: 600 }}>
            {project.name}
          </Typography>
          {fullscreen ? (
            <FullscreenExitIcon
              className={classes.icon}
              onClick={toggleFullscreen}
            />
          ) : (
            <FullscreenIcon
              className={classes.icon}
              onClick={toggleFullscreen}
            />
          )}
        </Grid>
        <SpecSummary
          project={project}
          population={population}
          selectedSpec={selectedSpec}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.header} variant="h6">
          Fit Coverage
        </Typography>
        <FitCoverage selectedSpec={selectedSpec} />
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.header} variant="h6">
          Reference Avatars
        </Typography>
        <ReferenceAvatars
          sizeSpec={selectedSpec}
          fitCoverageData={fitCoverageData}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.header} variant="h6">
          Extended Size Chart
        </Typography>
        <ExtendedSizeChart showHeading={false} />
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  fullscreen: state.fullscreen,
  project: state.project.entities[state.project.selected],
  population: selectedPopulation(state),
  selectedSpecId: state.project.selectedSizeSpecId,
  fitCoverageData: state.fitCoverage.data,
})

const mapDispatchToProps = (dispatch) => ({
  toggleFullscreen: () => dispatch({ type: 'TOGGLE_FULLSCREEN' }),
})

export default connect(mapStateToProps, mapDispatchToProps)(PresentationIndex)
