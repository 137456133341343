import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'

const useStyles = makeStyles((theme) => ({
  notification: {
    background: theme.palette.secondary.main,
    marginLeft: theme.spacing(1),
    width: 8,
    height: 8,
    borderRadius: '50%',
    display: 'inline-block',
  },
}))

export default function Notification({ title, style }) {
  const classes = useStyles()
  const dot = <span className={classes.notification} style={style} />

  return title ? <Tooltip title={title}>{dot}</Tooltip> : dot
}
