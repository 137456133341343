import React from 'react'
import { connect } from 'react-redux'
import AddIcon from '@material-ui/icons/AddCircle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

import * as AudienceActions from 'actions/audience_distribution'
import AudienceChart from 'components/size_explorer/audience/distributions/AudienceChart'
import CSVDownloadButton from 'components/size_explorer/audience/distributions/CSVDownloadButton'
import MeasurementsMenu from 'components/size_explorer/audience/distributions/MeasurementsMenu'

const DEFAULT_CHART_MEASUREMENTS = [
  'age',
  'height',
  'weight',
  'chestBustGirth',
  'naturalWaistGirth',
  'hipSeatGirth',
]

const AudienceDistributions = ({
  fetchAudienceDistribution,
  measurementsData,
  project,
  units,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [measurementList, setMeasurementList] = React.useState(
    DEFAULT_CHART_MEASUREMENTS
  )

  React.useEffect(() => {
    if (project) {
      // load defaults when component loads
      // refresh all when audience is saved
      measurementList.forEach((measurement) => {
        if (
          !measurementsData[measurement] ||
          measurementsData[measurement].audience.length === 0
        ) {
          fetchAudienceDistribution(measurement, project)
        }
      })
    }
  }, [fetchAudienceDistribution, measurementsData, measurementList, project])

  const handleAddChart = (measurement) => {
    // second condition will not produce cannot read length of undefined error, because first condition will always run first
    if (
      !measurementsData[measurement] ||
      measurementsData[measurement].audience.length === 0
    ) {
      fetchAudienceDistribution(measurement, project)
    }

    if (!measurementList.includes(measurement)) {
      setMeasurementList([...measurementList, measurement])

      setAnchorEl(null)
    }
  }

  const handleDelete = (measurement) => {
    setMeasurementList(measurementList.filter((item) => item !== measurement))
  }

  const handleReload = (measurement) => {
    fetchAudienceDistribution(measurement, project)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h5" style={{ fontWeight: 600 }}>
            Audience Distribution
          </Typography>
          <div>
            <Tooltip title="Add chart">
              <span>
                <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
                  <AddIcon />
                </IconButton>
              </span>
            </Tooltip>
            <MeasurementsMenu
              anchorEl={anchorEl}
              measurementList={measurementList}
              onClose={() => setAnchorEl(null)}
              onSelect={handleAddChart}
            />

            <CSVDownloadButton
              measurementList={measurementList}
              measurementsData={measurementsData}
              units={units}
            />
          </div>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          {measurementList
            .filter((mm) => Boolean(measurementsData[mm]))
            .map((measurement) => {
              return (
                <Grid key={`chart-${measurement}`} item xs={12} sm={4}>
                  <AudienceChart
                    chartData={measurementsData[measurement]}
                    measurement={measurement}
                    onDelete={() => handleDelete(measurement)}
                    onReload={() => handleReload(measurement)}
                    units={project.units}
                  />
                </Grid>
              )
            })}
        </Grid>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  measurementsData: state.charts.distribution,
  // these things are here simply to pass through to an action creator
  project: state.project.entities[state.project.selected],
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchAudienceDistribution: (measurement, project) => {
    dispatch(
      AudienceActions.fetchAudienceDistribution(
        project.population,
        project.audience,
        project.units,
        measurement
      )
    )
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AudienceDistributions)
