import _ from 'lodash'
import { createSelector } from 'reselect'

export const primaryMeasurementsSelector = createSelector(
  (state) =>
    state.project.entities[state.project.selected].sizeSpecs[
      state.project.selectedSizeSpecId
    ],
  (spec) => {
    return spec.primaryMeasurements
  }
)

export const secondaryMeasurementsSelector = createSelector(
  (state) =>
    state.project.entities[state.project.selected].sizeSpecs[
      state.project.selectedSizeSpecId
    ],
  (spec) => {
    return spec.secondaryMeasurements
  }
)

export const sizeListSelector = createSelector(
  (state) =>
    state.project.entities[state.project.selected].sizeSpecs[
      state.project.selectedSizeSpecId
    ].sizeDefs,
  (sizeDefs = []) => sizeDefs.map(({ size }) => size)
)

export const extendedSizesSelector = createSelector(
  (state) => state.sizeSpecDistributions.summary,
  (state) =>
    state.project.entities[state.project.selected].sizeSpecs[
      state.project.selectedSizeSpecId
    ].sizeDefs,
  (specSummary, sizeDefs = []) => {
    if (!specSummary) return null

    const result = {}
    // { measurement: { size: { ...stats } } }
    specSummary.forEach((sizeSummary) => {
      const { size, ...measurements } = sizeSummary

      Object.keys(measurements).forEach((measurement) => {
        const measurementDetails = result[measurement] || {}
        measurementDetails[size] = sizeSummary[measurement]
        result[measurement] = measurementDetails
      })
    })

    return result
  }
)

export const additionalFiltersTableSelector = createSelector(
  (state) =>
    state.project.entities[state.project.selected].sizeSpecs[
      state.project.selectedSizeSpecId
    ].sizeDefs,
  (sizeDefs = []) => {
    if (sizeDefs.length === 0) {
      return {
        sizeList: [],
        measurementList: [],
        selectedMeasurement: null,
      }
    }

    let tableData = {
      sizeList: sizeDefs.map(({ size }) => size),
      measurementList: [],
      selectedMeasurement: null,
    }

    sizeDefs.forEach((sizeDef) => {
      const { size, additionalFilters = [] } = sizeDef
      additionalFilters.forEach((filter) => {
        const { measure, type, min, max } = filter

        if (!tableData.selectedMeasurement) {
          tableData.selectedMeasurement = measure // selects first measurement
        }

        if (measure && !tableData.measurementList.includes(measure)) {
          tableData.measurementList.push(measure) // add to measurementList
          tableData[measure] = {} // create key-value container
        }

        let filterObj = {}
        if (type === 'absolute') {
          filterObj = {
            size,
            measurement: measure,
            type,
            min,
            max,
            value: null,
          }
        } else {
          // 'std' & 'relative'
          filterObj = {
            size,
            measurement: measure,
            type,
            min: null,
            max: null,
            value: max,
          }
        }

        if (Object.keys(filterObj) !== 0) {
          tableData = {
            ...tableData,
            [measure]: {
              ...tableData[measure],
              [size]: filterObj,
              type,
            },
          }
        }
      })
    })
    // console.log('this is tableData from selector', tableData)
    return tableData
  }
)

// only works if elements of array are primitives like number & string
// example: ['hip', 'waist', 'chest'] === ['chest', 'hip', 'waist']
const isArrayEqual = (a, b) => {
  if (!a || !b) {
    return false
  }
  return [...a].sort().toString() === [...b].sort().toString()
}

export const comparableSizeSpecs = createSelector(
  (state) => state.project.entities[state.project.selected].sizeSpecs,
  (state) => state.project.selectedSizeSpecId,
  (specs, selectedSpecId) => {
    if (!specs || _.isEmpty(specs)) {
      return {}
    } else if (!selectedSpecId || _.isEmpty(specs[selectedSpecId])) {
      return {}
    } else {
      return _.omitBy(specs, (spec, specId) => {
        // selected spec is baseline, so can't compare against yourself
        if (specId === selectedSpecId) {
          return true
        } else {
          // to compare we must have matching primary measurements
          return !isArrayEqual(
            spec.primaryMeasurements,
            specs[selectedSpecId].primaryMeasurements
          )
        }
      })
    }
  }
)
