import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

export default ({ size = 32, style }) => (
  <CircularProgress
    size={size}
    color="primary"
    disableShrink
    style={{ ...style }}
  />
)
