import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const styles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    marginTop: '-2px',
    color: theme.palette.text.secondary,
  },
  title: {
    flex: 1,
  },
}))

function PageTitle({ adornment, title = 'Page Title', subtitle }) {
  const classes = styles()

  return (
    <Grid
      container
      alignContent="center"
      justify="space-between"
      className={classes.root}
    >
      <Grid item className={classes.title}>
        <Typography variant="h5" style={{ fontWeight: 600 }}>
          {title}
        </Typography>
        {subtitle ? (
          <Typography
            variant="caption"
            component="div"
            className={classes.subtitle}
          >
            {subtitle}
          </Typography>
        ) : null}
      </Grid>
      {adornment || null}
    </Grid>
  )
}

export default PageTitle
