import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

export default ({ errorMessage, handleReload }) => (
  <Grid container justify="center" alignItems="center" spacing={3}>
    <Grid item xs={12}>
      <Typography
        variant="subtitle2"
        align="center"
        style={{ fontStyle: 'italic', color: 'red' }}
      >
        {errorMessage}
      </Typography>
    </Grid>
    {handleReload && (
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleReload}
        >
          Reload
        </Button>
      </Grid>
    )}
  </Grid>
)
