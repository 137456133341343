import _ from 'lodash'
import React from 'react'
import { sortMeasurements } from 'lib/measurements'

// Generating unique primary measurement pairs for scatterplot data
export const getUniquePairs = (arrayOfMeasurements) => {
  const sorted = sortMeasurements(arrayOfMeasurements)
  const result = []

  // How to optimize time complexity? O(n)
  for (let i = 0; i < sorted.length - 1; i++) {
    for (let j = i + 1; j < sorted.length; j++) {
      const pair = [sorted[i], sorted[j]] // pair = [measurement, correlationMeasurement]; elements are sorted in order of importance
      result.push(pair)
    }
  }
  return result
}

export const generateAnnotationRules = (annotation) => (
  measurementRange,
  correlationMeasurementRange
) => {
  const { d, screenCoordinates, xScale, yScale } = annotation

  if (
    d.type === 'myCustomType' &&
    measurementRange &&
    correlationMeasurementRange
  ) {
    // Generate dividend value (outermost possible tick / respective range)
    const measurementDividend = xScale.domain()[1] / measurementRange
    const correlationMeasurementDividend =
      yScale.domain()[1] / correlationMeasurementRange

    const x = xScale.range()[1] / measurementDividend
    const y = yScale.range()[0] / correlationMeasurementDividend

    // https://developer.mozilla.org/en-US/docs/Web/SVG/Tutorial/Fills_and_Strokes
    return (
      <g transform={`translate(${screenCoordinates})`}>
        <rect
          width={String(x)}
          height={String(y)}
          stroke="black"
          strokeWidth="3"
          fill="transparent"
        />
        <text>{d.customLabelOrSomething}</text>
      </g>
    )
  } else {
    return null
  }
}

export const generateToleranceAnnotations = ({
  measurementName = '',
  meanVal = 0,
  toleranceVal = 0,
}) => {
  return [
    {
      type: 'x',
      color: 'red',
      [measurementName]: meanVal,
      dy: -10,
      dx: 0,
      connector: { end: 'none' },
      note: { label: 'Mean', align: 'middle', lineType: null },
    },
    { type: 'tolerance-annotation', meanVal, toleranceVal },
  ]
}

export const generateToleranceAnnotationRules = (annotation) => {
  // console.log('annotation', annotation)
  const { d, xScale, yScale } = annotation

  const xScaleFactor =
    xScale.range()[1] / (xScale.domain()[1] - xScale.domain()[0])

  if (d && d.type === 'tolerance-annotation') {
    const lowerBound = Number(d.meanVal) - Number(d.toleranceVal)
    const upperBound = Number(d.meanVal) + Number(d.toleranceVal)

    const width = (upperBound - lowerBound) * xScaleFactor
    const height = yScale.range()[0]

    const b = xScaleFactor * xScale.domain()[0]
    const transform = lowerBound * xScaleFactor - b

    return (
      <g transform={`translate(${transform}, 0)`}>
        <rect
          style={{
            width: `${width}`,
            height: `${height}`,
            fill: 'red',
            opacity: 0.3,
            stroke: 'red',
            strokeWidth: 2,
          }}
        />
      </g>
    )
  } else {
    return null
  }
}

// for Compare Mode - Coverage By Size
export const generateClusterBarChartData = (
  baselineFitCoverage,
  potentialFitCoverage
) => {
  const baselineSizeList = baselineFitCoverage.map((el) => el.size)
  const potentialSizeList = potentialFitCoverage.map((el) => el.size)

  const allSizeList = [
    ...baselineSizeList,
    ...potentialSizeList.filter((size) => !_.includes(baselineSizeList, size)),
  ]

  return allSizeList.map((size) => {
    const baseline = baselineFitCoverage.find((el) => el.size === size) || {}
    const potential = potentialFitCoverage.find((el) => el.size === size) || {}

    return {
      size,
      baseline: {
        count: baseline.count,
        coverage: (baseline.coverage * 100).toFixed(2),
      },
      potential: {
        count: potential.count,
        coverage: (potential.coverage * 100).toFixed(2),
      },
    }
  })
}
