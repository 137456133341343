import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import ScanImage from 'components/lib/ScanImage'

const useStyles = makeStyles((theme) => {
  return {
    avatar: {
      width: 'calc(33% - 16px)',
      margin: theme.spacing(1),
      height: '100%',
    },
    caption: {
      marginTop: theme.spacing(1),
      textAlign: 'center',
    },
    label: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#ddd',
      padding: theme.spacing(1),
      fontWeight: 600,
    },
  }
})

const ReferenceAvatars = ({
  sizeSpec,
  fitCoverageData,
  fetchingCentroids,
  onClickOpen,
}) => {
  const classes = useStyles()
  return (
    <Grid container alignItems="center">
      {sizeSpec.sizeDefs.map(({ size, referencePkgId }, idx) => {
        return (
          <div
            key={`reference-${referencePkgId}-${idx}`}
            className={classes.avatar}
          >
            <Card>
              <div style={{ display: 'flex' }}>
                <ScanImage
                  pkgId={referencePkgId}
                  angle="000"
                  isFetching={
                    typeof referencePkgId === 'undefined' && fetchingCentroids
                  }
                />
                <ScanImage
                  pkgId={referencePkgId}
                  angle="090"
                  isFetching={
                    typeof referencePkgId === 'undefined' && fetchingCentroids
                  }
                />
              </div>
              <div className={classes.label}>
                <Typography variant="body2" align="center">
                  {size}
                </Typography>
              </div>
            </Card>

            {/* <div className={classes.caption}>
              <ScanStats
                count={fitCoverageData.sizes && fitCoverageData.sizes[idx] ? fitCoverageData.sizes[idx].count : 0}
                coverage={fitCoverageData.sizes && fitCoverageData.sizes[idx] ? fitCoverageData.sizes[idx].coverage : 0}
              />
            </div> */}
          </div>
        )
      })}
    </Grid>
  )
}

export default ReferenceAvatars
