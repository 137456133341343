import queryString from 'query-string'

import * as auth from 'lib/auth'

const API_BASEURL = process.env.REACT_APP_API_BASEURL

export const GET = (uri, params) => {
  let url = API_BASEURL + uri
  if (params) {
    const qs = queryString.stringify(params)
    url += '?' + qs
  }

  return new Promise((resolve, reject) => {
    window
      .fetch(url, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + auth.getSessionToken(),
        },
      })
      .then((response) => {
        if (response.ok) {
          resolve(response.json())
        } else if (response.status === 401) {
          // user doesn't have valid session, force login
          auth.logout()
        } else {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const PUT = (uri, body) => {
  return new Promise((resolve, reject) => {
    window
      .fetch(API_BASEURL + uri, {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.getSessionToken(),
        },
      })
      .then((response) => {
        if (response.ok) {
          resolve(response.json())
        } else if (response.status === 401) {
          // user doesn't have valid session, force login
          auth.logout()
        } else {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const POST = (uri, body) => {
  return new Promise((resolve, reject) => {
    window
      .fetch(API_BASEURL + uri, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.getSessionToken(),
        },
      })
      .then((response) => {
        if (response.ok) {
          resolve(response.json())
        } else if (response.status === 401) {
          // user doesn't have valid session, force login
          auth.logout()
        } else {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const DELETE = (uri) => {
  return new Promise((resolve, reject) => {
    window
      .fetch(API_BASEURL + uri, {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + auth.getSessionToken(),
        },
      })
      .then((response) => {
        if (response.ok) {
          resolve(null)
        } else if (response.status === 401) {
          // user doesn't have valid session, force login
          auth.logout()
        } else {
          reject(response)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}
