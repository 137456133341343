import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 12,
  },
  radioContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2.5),
  },
  input: {
    padding: 8,
    width: 50,
    fontSize: 14,
  },
}))

export default ({
  additionalOption,
  filter,
  measurementType,
  onDynamicRangeChange,
  onTypeChange,
}) => {
  const classes = useStyles()

  const isDisabled = measurementType === 'primaryMeasurement'

  return (
    <div className={classes.root}>
      <RadioGroup
        aria-label="gender"
        name="gender1"
        value={additionalOption}
        onChange={onTypeChange}
        row
      >
        <FormControlLabel
          value="absolute"
          control={<Radio />}
          label="Fixed Range"
        />
        {measurementType !== 'primaryMeasurement' && (
          <div className={classes.radioContainer}>
            <FormControlLabel
              value="mean"
              control={<Radio disabled={isDisabled} />}
              label="Mean +/-"
            />
            <div>
              <TextField
                id="outlined-number"
                type="number"
                value={(filter && filter.type === 'mean' && filter.max) || ''}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ className: classes.input }}
                variant="outlined"
                onChange={(e) => onDynamicRangeChange('mean', e.target.value)}
                disabled={isDisabled}
              />
            </div>
          </div>
        )}
        {measurementType !== 'primaryMeasurement' && (
          <div className={classes.radioContainer}>
            <FormControlLabel
              value="std"
              control={<Radio disabled={isDisabled} />}
              label="Std Dev +/-"
            />
            <div>
              <TextField
                id="outlined-number"
                type="number"
                value={(filter && filter.type === 'std' && filter.max) || ''}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ className: classes.input }}
                variant="outlined"
                onChange={(e) => onDynamicRangeChange('std', e.target.value)}
                disabled={isDisabled}
              />
            </div>
          </div>
        )}
      </RadioGroup>
    </div>
  )
}
