import React from 'react'
import ResponsiveXYFrame from 'semiotic/lib/ResponsiveXYFrame'
import { scaleLinear } from 'd3-scale'
import { parseCamelCase } from 'lib/format'
import './style.css'

const getMaxCount = (data) => {
  const callback = (currentMax, val) => Math.max(currentMax, val.count)
  return data.reduce(callback, 0)
}

const getMinCount = (data) => {
  const callback = (currentMin, val) => Math.min(currentMin, val.count)
  return data.reduce(callback, getMaxCount(data))
}

const gradientScale = (data) =>
  scaleLinear()
    .domain([getMinCount(data), getMaxCount(data)])
    .range(['#F1E0FF', '#3033ff'])

/*
NOTES
props.styles.gradient: informs the gradient color ranges
props.xAccessor = 'naturalWaistGirth'
props.yAccessor = 'chestBustGirth'
props.data = [ {...}, {...}, {...} ]
props.plotName = 'audience' || 'fitCoverage' (undefined)
props.selectProps = { x: {...}, y: {...} }
props.annotations = [ { type: 'myCustomType', x: 20, y: 25: label: 1 }, {...}, {...} ]
*/

const genderateFrameProps = (data, xAccessor, yAccessor, increaseRadius) => ({
  points: data,
  size: [500, 420],
  responsiveWidth: true,
  margin: { left: 60, bottom: 70, right: 10, top: 40 },
  xAccessor,
  yAccessor,
  // yExtent: [0],
  // xExtent: [0],
  pointStyle: (d) => {
    const r = increaseRadius ? 5 : 3
    // console.log('radius', r)
    // TODO: find a heuristic to adjust radius r if data count is too small/sparse so that the scatterplot looks 'filled up'
    // if (data.length < 100) { r = 10 }
    return {
      r,
      fill: gradientScale(data)(d.count),
    }
  },
  title: (
    <text textAnchor="middle" fontWeight="bold">
      <tspan fill="black">{parseCamelCase(xAccessor)} - </tspan>
      <tspan fill="black">{parseCamelCase(yAccessor)}</tspan>
    </text>
  ),
  axes: [
    {
      orient: 'bottom',
      label: { name: parseCamelCase(xAccessor), locationDistance: 55 },
    },
    { orient: 'left', label: parseCamelCase(yAccessor) },
  ],
  showLinePoints: true,
  showSummaryPoints: true,
})

const generateAnnotationRules = (annotation) => {
  const { d, i, screenCoordinates, xScale, yScale } = annotation

  if (d && d.type === 'myCustomType' && d.xDiff && d.yDiff) {
    const measurementDividend =
      (xScale.domain()[1] - xScale.domain()[0]) / d.xDiff
    const correlationMeasurementDividend =
      (yScale.domain()[1] - yScale.domain()[0]) / d.yDiff

    let x = xScale.range()[1] / measurementDividend
    let y = yScale.range()[0] / correlationMeasurementDividend

    const coordinates = [
      screenCoordinates[0] < 0
        ? 0
        : screenCoordinates[0] > xScale.range()[1]
        ? xScale.range()[1]
        : screenCoordinates[0],
      screenCoordinates[1] < 0
        ? 0
        : screenCoordinates[1] > yScale.range()[0]
        ? yScale.range()[0]
        : screenCoordinates[1],
    ]

    // Handle edge cases so the annotations don't overflow
    if (screenCoordinates[0] < 0) {
      x = x + screenCoordinates[0]
    } else if (x + screenCoordinates[0] > xScale.range()[1]) {
      x = xScale.range()[1] - screenCoordinates[0]
    }

    if (screenCoordinates[1] < 0) {
      y = y + screenCoordinates[1]
    } else if (y + screenCoordinates[1] > yScale.range()[0]) {
      y = yScale.range()[0] - screenCoordinates[1]
    }

    const defaultStyle = {
      width: String(x),
      height: String(y),
      stroke: d.color,
      strokeWidth: '2',
      fill: 'none',
      // fill: color[i % 6],
      // fillOpacity: 0.6
    }

    const compareModeColor = d.mode === 'baseline' ? '#3033ff' : '#691B99'
    const compareModeStyle = {
      stroke: compareModeColor,
      strokeWidth: '2.5',
      strokeOpacity: '0.9',
      fill: 'none',
    }
    // https://developer.mozilla.org/en-US/docs/Web/SVG/Tutorial/Fills_and_Strokes
    return (
      <g
        className="size-annotation"
        key={`annotation-${x}-${y}-${i}`}
        transform={`translate(${coordinates})`}
      >
        <rect
          style={
            d.mode === undefined
              ? { ...defaultStyle }
              : { ...defaultStyle, ...compareModeStyle }
          }
        />
        <text
          className="size-annotation-tooltip"
          x={String(x / 2 - 5)}
          y={String(y / 2 + 5)}
          fontWeight="bold"
          fontSize="16px"
        >
          {d && d.label}
        </text>
      </g>
    )
  } else {
    return null
  }
}

function ScatterPlot({
  data,
  xAccessor,
  yAccessor,
  annotations,
  increaseRadius = false,
  responsiveWidth = true,
}) {
  const frameProps = genderateFrameProps(
    data,
    xAccessor,
    yAccessor,
    increaseRadius,
    responsiveWidth
  )

  // console.log('rendering plot')

  if (annotations) {
    frameProps.annotations = annotations
    frameProps.svgAnnotationRules = generateAnnotationRules
  }

  return <ResponsiveXYFrame {...frameProps} />
}

const propCheck = (prevProps, nextProps) => {
  // console.log(prevProps, nextProps)
  // console.log(
  //   prevProps.xAccessor === nextProps.xAccessor &&
  //   prevProps.yAccessor === nextProps.yAccessor &&
  //   prevProps.data === nextProps.data &&
  //   prevProps.annotations === nextProps.annotations
  // )
  // TODO: double check to see why annotations are different
  return (
    prevProps.xAccessor === nextProps.xAccessor &&
    prevProps.yAccessor === nextProps.yAccessor &&
    prevProps.data === nextProps.data
  )
}

export default React.memo(ScatterPlot, propCheck)
