import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import AudienceFilterPanel from 'components/size_explorer/audience/filters/AudienceFilters'
import AudienceDistributions from 'components/size_explorer/audience/distributions'

const AudienceIndex = ({ isLoading, project }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h5" style={{ fontWeight: 600 }}>
            Audience Filters
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <AudienceFilterPanel isLoading={isLoading} project={project} />
      </Grid>

      <Grid item xs={12}>
        <AudienceDistributions />
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  isLoading:
    _.some(
      Object.values(state.charts.distribution),
      (dist) => dist.isFetching
    ) || false,
  project: state.project.entities[state.project.selected],
})

export default connect(mapStateToProps)(AudienceIndex)
