import numeral from 'numeral'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import CircularProgress from '@material-ui/core/CircularProgress'
import ErrorIcon from '@material-ui/icons/ErrorOutline'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

import AngleSelect from 'components/lib/AngleSelect'
import AvatarMeasurementsTable from 'components/avatars/search/AvatarMeasurementsTable'
import BookmarkIconButton from 'components/avatars/search/BookmarkIconButton'
import ScanImage from 'components/lib/ScanImage'

const styles = makeStyles((theme) => ({
  angleSelect: {
    width: '60%',
  },
  carousel: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '400px',
  },
  carouselBody: {
    flex: 1,
  },
  carouselLeft: {
    // display: 'flex'
  },
  carouselLoading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '400px',
  },
  carouselRight: {
    // display: 'flex'
  },
  summary: {
    paddingTop: theme.spacing(2),
  },
  table: {
    paddingTop: theme.spacing(2),
  },
}))

export default ({ gender, isError, isLoading, result }) => {
  const { scans, means, count } =
    result !== null ? result : { scans: [], means: {}, count: 0 }
  const classes = styles()

  const [carouselIndex, setCarouselIndex] = React.useState(null)
  const [imageAngle, setImageAngle] = React.useState('000')

  React.useEffect(() => {
    if (result && result.scans && result.scans.length > 0) {
      setCarouselIndex(0)
    } else if (!result) {
      setCarouselIndex(null)
    }
  }, [result])

  const handleAngleSelect = (angle) => {
    setImageAngle(angle)
  }

  const handleLeft = () => {
    if (carouselIndex > 0) {
      setCarouselIndex(carouselIndex - 1)
    }
  }

  const handleRight = () => {
    if (scans.length > carouselIndex + 1) {
      setCarouselIndex(carouselIndex + 1)
    }
  }

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        {isError ? (
          <Grid
            container
            direction="column"
            className={classes.carouselLoading}
          >
            <div>
              <ErrorIcon style={{ fontSize: 64 }} color="error" />
            </div>
            <Typography variant="caption" color="textSecondary" component="div">
              Error occurred during search
            </Typography>
          </Grid>
        ) : isLoading ? (
          <Grid
            container
            direction="column"
            className={classes.carouselLoading}
          >
            <div>
              <CircularProgress size={64} color="primary" disableShrink />
            </div>
            <Typography variant="caption" color="textSecondary" component="div">
              Searching avatar database
            </Typography>
          </Grid>
        ) : (
          <div className={classes.carousel}>
            <div className={classes.carouselLeft}>
              <IconButton disabled={!carouselIndex} onClick={handleLeft}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <div className={classes.carouselBody}>
              <ScanImage
                angle={imageAngle}
                gender={gender}
                pkgId={
                  carouselIndex !== null ? scans[carouselIndex].packageId : null
                }
                upperLeftAdornment={
                  <BookmarkIconButton
                    gender={gender}
                    packageId={
                      carouselIndex !== null
                        ? scans[carouselIndex].packageId
                        : null
                    }
                  />
                }
              />
            </div>
            <div className={classes.carouselRight}>
              <IconButton
                disabled={
                  carouselIndex === null || carouselIndex >= scans.length - 1
                }
                onClick={handleRight}
              >
                <ChevronRightIcon />
              </IconButton>
            </div>
          </div>
        )}
      </Grid>

      <Grid item xs={12} className={classes.angleSelect}>
        <AngleSelect
          angle={imageAngle}
          onAngleSelect={handleAngleSelect}
          padRight
        />
      </Grid>

      <Grid item xs={12} className={classes.summary}>
        <Typography variant="caption">
          Showing <b>{carouselIndex === null ? 0 : carouselIndex + 1}</b> of{' '}
          <b>{count !== null ? numeral(count).format('0,0') : ''}</b> available
          avatars
        </Typography>
      </Grid>

      <Grid item xs={12} className={classes.table}>
        <AvatarMeasurementsTable
          scan={carouselIndex !== null ? scans[carouselIndex] : null}
          means={means}
        />
      </Grid>
    </Grid>
  )
}
