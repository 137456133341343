import React from 'react'
import ResponsiveOrdinalFrame from 'semiotic/lib/ResponsiveOrdinalFrame'
import Grid from '@material-ui/core/Grid'
// import Legend from 'semiotic/lib/Legend'
import _ from 'lodash'

export default (props) => {
  const { title, data, oAccessor, rAccessor, tooltipContent } = props
  const maxObj = _.maxBy(data, (o) => o.fitCoverage) // TODO: move this to outside
  const max = maxObj && maxObj.fitCoverage

  const frameProps = {
    data,
    size: [null, 270],
    type: 'bar',
    oPadding: 8, // spacing between bars
    oAccessor,
    rAccessor,
    // legend: props.legend,
    rExtent: [undefined, max > 10 ? max + 10 : 10],
    style: (d) => {
      if (d && d.color) {
        return { fill: '#3033ff', stroke: 'white' }
      }
      return { fill: 'black', stroke: 'white' }
    },
    title: (
      <text textAnchor="middle">
        <tspan fill="black" fontWeight="bold">
          {title}
        </tspan>
      </text>
    ),
    margin: { left: 22, bottom: 20, top: 18 },
    axes: { orient: 'left' },
    oLabel:
      props.oLabel || typeof props.oLabel === 'boolean' ? props.oLabel : true,
    pieceHoverAnnotation: true,
    tooltipContent,
  }

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      {/* <div style={{ border: '2px red solid' }}>{props.legend && <Legend {...props.legend} />}</div> */}
      {props.legend &&
        props.legend.legendGroups &&
        props.legend.legendGroups[0] && (
          <div
            style={{
              position: 'absolute',
              width: '100%',
              top: '12px',
              left: '40px',
              zIndex: 2,
              background: 'white',
            }}
          >
            {props.legend.legendGroups[0].items.map((item) => {
              return (
                <Grid
                  key={`legend-${item.label}`}
                  container
                  alignItems="center"
                  style={{ marginBottom: '2px' }}
                >
                  <div
                    style={{
                      marginRight: 8,
                      width: 10,
                      height: 10,
                      background: item.color,
                    }}
                  />
                  <span style={{ fontSize: '0.75rem' }}>{item.label}</span>
                </Grid>
              )
            })}
          </div>
        )}
      <ResponsiveOrdinalFrame {...frameProps} responsiveWidth />
    </div>
  )
}
