import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import _ from 'lodash'

import SizesInput from 'components/size_explorer/size_specs/forms/SizesInput'
import MeasurementSelect from 'components/size_explorer/size_specs/forms/MeasurementSelect'
import MeasurementRangeTable from 'components/size_explorer/size_specs/forms/MeasurementRangeForm'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  label: {
    color: '#555',
    marginBottom: theme.spacing(1),
  },
}))

export default ({
  spec,
  onSpecUpdate,
  measurementValues,
  setMeasurementValues,
  additionalFilters,
  setAdditionalFilters,
  setIsDirty,
}) => {
  const classes = useStyles()

  const sizes = React.useMemo(() => {
    // trim all white space, remove duplicate sizes, and filter by non-empty string
    return spec.sizes
      ? _.uniq(
          spec.sizes
            .split(',')
            .map((size) => size.trim())
            .filter((o) => !!o)
        )
      : []
  }, [spec.sizes])

  return (
    <Grid className={classes.root} container spacing={2}>
      <Grid item xs={12}>
        <Typography className={classes.label} variant="subtitle1">
          What sizes does the spec cover?
        </Typography>
        <SizesInput spec={spec} onSpecUpdate={onSpecUpdate} />
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.label} variant="subtitle1">
          What are the primary measurements?
        </Typography>
        <MeasurementSelect
          spec={spec}
          category="primaryMeasurements"
          onSpecUpdate={onSpecUpdate}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.label} variant="subtitle1">
          Do you use any secondary measurements?
        </Typography>
        <MeasurementSelect
          spec={spec}
          category="secondaryMeasurements"
          additionalFilters={additionalFilters}
          setAdditionalFilters={setAdditionalFilters}
          onSpecUpdate={onSpecUpdate}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.label} variant="subtitle1">
          Specify measurement ranges for each size
        </Typography>
        <MeasurementRangeTable
          spec={spec}
          sizes={sizes}
          measurementValues={measurementValues}
          setMeasurementValues={setMeasurementValues}
          additionalFilters={additionalFilters}
          setAdditionalFilters={setAdditionalFilters}
          onSpecUpdate={onSpecUpdate}
          setIsDirty={setIsDirty}
        />
      </Grid>
    </Grid>
  )
}
