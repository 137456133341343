import { parseCamelCase } from 'lib/format'
// a simple place for fixed options lists to exist and be shared

export const ageOptions = [
  { label: '18-25', value: '18-25', searchParams: { ageMin: 18, ageMax: 25 } },
  { label: '26-35', value: '26-35', searchParams: { ageMin: 26, ageMax: 35 } },
  { label: '36-45', value: '36-45', searchParams: { ageMin: 36, ageMax: 45 } },
  { label: '46-55', value: '46-55', searchParams: { ageMin: 46, ageMax: 55 } },
  { label: '56-65', value: '56-65', searchParams: { ageMin: 56, ageMax: 65 } },
  { label: '66+', value: '66+', searchParams: { ageMin: 66 } },
]

// these pertain to the situation where no gender is specified on the search
export const bodyfatGenderlessOptions = [
  {
    label: 'Lean',
    value: 'lean',
    searchParams: { bodyfatMin: 6, bodyfatMax: 24 },
  },
  {
    label: 'Average',
    value: 'average',
    searchParams: { bodyfatMin: 18, bodyfatMax: 31 },
  },
  {
    label: 'Overweight',
    value: 'overweight',
    searchParams: { bodyfatMin: 25, bodyfatMax: 40 },
  },
  { label: 'Obese', value: 'obese', searchParams: { bodyfatMin: 31 } },
]

export const bodyfatFemaleOptions = [
  {
    label: 'Lean (10-24%)',
    value: 'lean',
    searchParams: { bodyfatMin: 10, bodyfatMax: 24 },
  },
  {
    label: 'Average (25-31%)',
    value: 'average',
    searchParams: { bodyfatMin: 25, bodyfatMax: 31 },
  },
  {
    label: 'Overweight (32-40%)',
    value: 'overweight',
    searchParams: { bodyfatMin: 32, bodyfatMax: 40 },
  },
  { label: 'Obese (41%+)', value: 'obese', searchParams: { bodyfatMin: 41 } },
]

export const bodyfatMaleOptions = [
  {
    label: 'Lean (6-17%)',
    value: 'lean',
    searchParams: { bodyfatMin: 6, bodyfatMax: 17 },
  },
  {
    label: 'Average (18-24%)',
    value: 'average',
    searchParams: { bodyfatMin: 18, bodyfatMax: 24 },
  },
  {
    label: 'Overweight (25-30%)',
    value: 'overweight',
    searchParams: { bodyfatMin: 25, bodyfatMax: 30 },
  },
  { label: 'Obese (31%+)', value: 'obese', searchParams: { bodyfatMin: 31 } },
]

// make it easy to get the correct set of bodyfat options based on gender
export const bodyfatOptions = (gender) => {
  if (gender === 'male') {
    return bodyfatMaleOptions
  } else if (gender === 'female') {
    return bodyfatFemaleOptions
  } else {
    return bodyfatGenderlessOptions
  }
}

// convert array of measurements into 'options' that is consumable by <Select /> component
export const getOptions = (measurements = []) => {
  return measurements.map((measurement) => ({
    value: measurement,
    label: parseCamelCase(measurement),
  }))
}

/*
export const countryOptions = [
  { value: 'Global', label: 'Global' },
  { value: 'US', label: 'USA' },
  { value: 'AU', label: 'Australia' },
  { value: 'BR', label: 'Brazil' },
  { value: 'CA', label: 'Canada' },
  // { value: 'CH', label: 'China' },
  // { value: 'CR', label: 'Costa Rica' },
  { value: 'EC', label: 'Ecuador' },
  { value: 'FR', label: 'France' },
  { value: 'DE', label: 'Germany' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'MX', label: 'Mexico' },
  { value: 'NZ', label: 'New Zealand' },
  { value: 'NO', label: 'Norway' },
  { value: 'SE', label: 'Sweden' },
  { value: 'ES', label: 'Spain' },
  { value: 'TH', label: 'Thailand' },
  { value: 'AE', label: 'United Arab Emirate' },
  { value: 'GB', label: 'United Kingdom' }
]
*/

export const regionOptions = [
  { value: 'us', label: 'United States' },
  { value: 'us-west', label: 'US - West' },
  { value: 'as', label: 'Asia' },
  { value: 'am', label: 'Americas' },
  { value: 'ca', label: 'Canada' },
  { value: 'fr', label: 'France' },
  { value: 'eu', label: 'Europe' },
]

const regionHash = {
  us: 'United States',
  'us-west': 'US - West',
  as: 'Asia',
  am: 'Americas',
  ca: 'Canada',
  fr: 'France',
  eu: 'Europe',
}

export const getRegionName = (code) => {
  return regionHash[code]
}

export const selectify = (value) => ({
  label: parseCamelCase(value),
  value,
})

export const AUDIENCE_DISTRIBUTION_OPTIONS = [
  { label: 'Age', value: 'age' },
  { label: 'Across Back Shoulder Width', value: 'acrossBackShoulderWidth' },
  { label: 'Across Front Shoulder Width', value: 'acrossFrontShoulderWidth' },
  { label: 'Ankle Girth', value: 'ankleGirth' },
  { label: 'Arm Length', value: 'armLength' },
  { label: 'Armscye Girth', value: 'armscyeGirth' },
  { label: 'Body Composition', value: 'bodyComposition' },
  { label: 'Calf Girth', value: 'calfGirth' },
  { label: 'Cervical To Wrist Length', value: 'cervicalToWristLength' },
  { label: 'Chest Bust Girth', value: 'chestBustGirth' },
  { label: 'Crotch Height', value: 'crotchHeight' },
  { label: 'Crotch Length', value: 'crotchLength' },
  { label: 'Forearm Girth', value: 'forearmGirth' },
  { label: 'Height', value: 'height' },
  { label: 'Hip Seat Girth', value: 'hipSeatGirth' },
  { label: 'Knee Girth', value: 'kneeGirth' },
  { label: 'Inseam', value: 'inseam' },
  { label: 'Mid Neck Girth', value: 'midNeckGirth' },
  { label: 'Natural Waist Girth', value: 'naturalWaistGirth' },
  { label: 'Outseam', value: 'outseam' },
  { label: 'Preferred Waist Girth', value: 'preferredWaistGirth' },
  { label: 'Thigh Girth', value: 'thighGirth' },
  { label: 'Trunk Length', value: 'trunkLength' },
  { label: 'Under Bust Girth', value: 'underBustGirth' },
  { label: 'Upper Arm Girth', value: 'upperArmGirth' },
  { label: 'Weight', value: 'weight' },
]
