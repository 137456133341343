import React from 'react'
import ResponsiveOrdinalFrame from 'semiotic/lib/ResponsiveOrdinalFrame'

const COLOR = ['#3033ff', '#691B99']

const ClusterBarChart = ({ data, oAccessor, rAccessor, tooltipContent }) => {
  const frameProps = {
    data,
    size: [null, 270],
    type: 'clusterbar',
    oPadding: 8, // spacing between bars
    oAccessor,
    rAccessor,
    style: (d) => {
      // console.log('this is d', d)
      const fillColor = d.rName === 'function-0' ? COLOR[0] : COLOR[1]
      return {
        fill: fillColor,
        stroke: 'white',
      }
    },
    // axes: true,
    oLabel: true,
    pieceHoverAnnotation: true,
    tooltipContent,
  }
  return <ResponsiveOrdinalFrame {...frameProps} responsiveWidth />
}

export default ClusterBarChart
