import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'

// NOTE: items are expected to be objects containing ..
//   - id
//   - message
export default ({ items, onCloseItem }) => {
  return (
    <>
      {items.map((item, idx) => (
        <Snackbar
          key={idx}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open
          autoHideDuration={6000}
          onClose={() => onCloseItem(item)}
          message={item.message || 'Unknown'}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => onCloseItem(item)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      ))}
    </>
  )
}
