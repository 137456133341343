import * as Queries from 'lib/api/queries'

export const FETCH_FIT_COVERAGE_START = 'FETCH_FIT_COVERAGE_START'
export const FETCH_FIT_COVERAGE_SUCCESS = 'FETCH_FIT_COVERAGE_SUCCESS'
export const FETCH_FIT_COVERAGE_FAILURE = 'FETCH_FIT_COVERAGE_FAILURE'

export const FETCH_FIT_COVERAGE_START_COMPARE_BASELINE =
  'FETCH_FIT_COVERAGE_START_COMPARE_BASELINE'
export const FETCH_FIT_COVERAGE_SUCCESS_COMPARE_BASELINE =
  'FETCH_FIT_COVERAGE_SUCCESS_COMPARE_BASELINE'
export const FETCH_FIT_COVERAGE_FAILURE_COMPARE_BASELINE =
  'FETCH_FIT_COVERAGE_FAILURE_COMPARE_BASELINE'
export const FETCH_FIT_COVERAGE_START_COMPARE_POTENTIAL =
  'FETCH_FIT_COVERAGE_START_COMPARE_POTENTIAL'
export const FETCH_FIT_COVERAGE_SUCCESS_COMPARE_POTENTIAL =
  'FETCH_FIT_COVERAGE_SUCCESS_COMPARE_POTENTIAL'
export const FETCH_FIT_COVERAGE_FAILURE_COMPARE_POTENTIAL =
  'FETCH_FIT_COVERAGE_FAILURE_COMPARE_POTENTIAL'

export const fetchFitCoverage = ({
  population,
  audienceFilter,
  sizeDefs,
  units,
}) => (dispatch) => {
  dispatch({ type: FETCH_FIT_COVERAGE_START })

  Queries.fitCoverage(population, audienceFilter, sizeDefs, units)
    .then((response) => {
      const { sizes } = response
      if (!sizes || sizes.errorMessage) {
        throw new Error('fit coverage api error')
      }

      dispatch({
        type: FETCH_FIT_COVERAGE_SUCCESS,
        payload: response,
      })
    })
    .catch((error) => {
      dispatch({
        type: FETCH_FIT_COVERAGE_FAILURE,
        error,
      })
    })
}

// modeType is either 'baseline' or 'potential'
export const fetchFitCoverageCompareMode = (modeType) => (sizeDefs) => (
  dispatch,
  getState
) => {
  const state = getState()
  const project = state.project.entities[state.project.selected]

  dispatch({
    type: `FETCH_FIT_COVERAGE_START_COMPARE_${modeType.toUpperCase()}`,
  })

  return Queries.fitCoverage(
    project.population,
    project.audience,
    sizeDefs,
    project.units
  )
    .then((response) => {
      const { sizes } = response
      if (!sizes || sizes.errorMessage) {
        throw new Error('fit coverage api error')
      }

      dispatch({
        type: `FETCH_FIT_COVERAGE_SUCCESS_COMPARE_${modeType.toUpperCase()}`,
        payload: response,
      })
    })
    .catch((error) => {
      dispatch({
        type: `FETCH_FIT_COVERAGE_FAILURE_COMPARE_${modeType.toUpperCase()}`,
        error,
      })
    })
}
