import React from 'react'
import ResponsiveXYFrame from 'semiotic/lib/ResponsiveXYFrame'
import Typography from '@material-ui/core/Typography'
import { curveCatmullRom } from 'd3-shape'
import { parseCamelCase } from 'lib/format'

const generateTooltip = (xAccessor) => (d) => {
  // console.log('d', d)
  return (
    <div
      style={{
        background: 'rgba(255, 255, 255, 0.8',
        whiteSpace: 'noWrap',
        minWidth: 'max-content',
        padding: '8px',
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.6)',
        borderRadius: '2px',
      }}
    >
      <Typography variant="caption" display="block">
        Size: {d.parentLine && d.parentLine.size}
      </Typography>
      <Typography variant="caption" display="block">
        Measurement: {d.data && d.data[xAccessor]}
      </Typography>
      <Typography variant="caption" display="block">
        Coverage: {d.data && `${d.data.count}%`}
      </Typography>
    </div>
  )
}

const generateFrameProps = ({
  xAccessor,
  yAccessor,
  annotations,
  svgAnnotationRules,
  lines, // for sample code, go to https://semiotic.nteract.io/guides/line-chart
  setLines,
  minmaxPeg,
}) => {
  // console.log('minmax', minmaxPeg)
  return {
    lines: [...lines, minmaxPeg],
    size: [700, 500],
    margin: { left: 10, bottom: 60, top: 30 },
    lineType: { type: 'line', interpolator: curveCatmullRom },
    xAccessor,
    yAccessor,
    yExtent: [0],
    lineStyle: (d, i) => ({
      stroke: d.color,
      strokeWidth: 2,
      fill: 'none',
    }),
    axes: [
      // { orient: 'left' },
      {
        orient: 'bottom',
        label: {
          name: xAccessor && parseCamelCase(xAccessor),
          locationDistance: 55,
        },
      },
    ],
    hoverAnnotation: true,
    tooltipContent: generateTooltip(xAccessor),
    annotations,
    svgAnnotationRules,
    // annotations: [
    //   { type: 'x', color: 'blue', [xAccessor]: 13.6, dy: -10, dx: 0, connector: { end: 'none' }, note: { label: 'Mean', align: 'middle', lineType: null } },
    //   { type: 'custom' }
    // ],
  }
}

const CurvyLineChart = (props) => {
  return <ResponsiveXYFrame {...generateFrameProps(props)} responsiveWidth />
}

export default CurvyLineChart
