import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'

const useStyles = makeStyles((theme) => ({
  input: {
    padding: '8px 14px',
    width: 80,
  },
}))

export default ({
  sizes,
  measurementValues,
  measurement,
  hasMeasurements,
  onTableChange,
}) => {
  const classes = useStyles()
  const tableBody = []

  // generate text fields for each cell
  for (var i = 0; i < sizes.length; i++) {
    const size = sizes[i]
    const measurementMin = `${measurement}Min` // i.e. chestBustGirthMin
    const measurementMax = `${measurement}Max` // i.e. chestBustGirthMax

    tableBody.push(
      <TableRow key={size}>
        <TableCell>{size}</TableCell>
        <TableCell key={`cell-${size}-${i}`}>
          <FormControl>
            <TextField
              variant="outlined"
              value={
                (measurementValues[size] &&
                  measurementValues[size][measurementMin]) ||
                ''
              }
              onChange={(e) =>
                onTableChange(size, measurementMin, e.target.value)
              }
              inputProps={{ className: classes.input }}
              disabled={!hasMeasurements}
            />
          </FormControl>
        </TableCell>
        <TableCell key={`cell-${size}-${i + 1}`}>
          <FormControl>
            <TextField
              variant="outlined"
              value={
                (measurementValues[size] &&
                  measurementValues[size][measurementMax]) ||
                ''
              }
              onChange={(e) =>
                onTableChange(size, measurementMax, e.target.value)
              }
              inputProps={{ className: classes.input }}
              disabled={!hasMeasurements}
            />
          </FormControl>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Size</TableCell>
            <TableCell>Min</TableCell>
            <TableCell>Max</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{tableBody}</TableBody>
      </Table>
    </TableContainer>
  )
}
