import React from 'react'
import Select from 'react-select'

export default ({
  compareSpecs,
  disabled,
  onCancel,
  onSubmit,
  selectedSpecId,
}) => {
  const [selected, setSelected] = React.useState(null)
  const [options, setOptions] = React.useState([])

  React.useEffect(() => {
    if (compareSpecs) {
      setOptions(
        Object.keys(compareSpecs).map((specId) => ({
          label: compareSpecs[specId].name,
          value: specId,
        }))
      )
    }
  }, [compareSpecs])

  // this is here to reset the compare select whenever the app selected spec changes
  React.useEffect(() => {
    if (compareSpecs && selectedSpecId) {
      setSelected({
        label: compareSpecs[selectedSpecId].name,
        value: selectedSpecId,
      })
    } else {
      setSelected(null)
    }
  }, [compareSpecs, selectedSpecId])

  const handleSelect = (choice) => {
    if (!choice) {
      setSelected(null)
      onCancel()
    } else {
      setSelected(choice)
      onSubmit(choice.value)
    }
  }

  return (
    <Select
      options={options}
      value={selected}
      onChange={handleSelect}
      styles={{
        clearIndicator: (provided, state) => ({
          ...provided,
          cursor: 'pointer',
        }),
        container: (provided, state) => ({
          ...provided,
          minWidth: '280px',
        }),
        menuList: (provided, state) => ({
          ...provided,
          zIndex: 999,
        }),
      }}
      isClearable
      isDisabled={disabled || options.length === 0}
      isSearchable={false}
      placeholder={
        options.length === 0 ? 'No specs to compare' : 'Compare to another spec'
      }
    />
  )
}
