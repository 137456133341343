import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'

const styles = makeStyles((theme) => ({
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: '400px',
  },
  main: {
    padding: theme.spacing(2),
  },
}))

function PageContentWithLoading({ children, isLoading }) {
  const classes = styles()

  return (
    <Paper>
      <Box className={classes.main}>
        {isLoading ? (
          <div className={classes.loading}>
            <CircularProgress size={32} color="primary" disableShrink />
          </div>
        ) : (
          <>{children}</>
        )}
      </Box>
    </Paper>
  )
}

export default PageContentWithLoading
