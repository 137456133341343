import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'

export default ({ onClick }) => {
  return (
    <Tooltip title="Edit Spec">
      <IconButton aria-label="edit" onClick={onClick}>
        <EditIcon />
      </IconButton>
    </Tooltip>
  )
}
