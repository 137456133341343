import React from 'react'
import ResponsiveOrdinalFrame from 'semiotic/lib/ResponsiveOrdinalFrame'

// const colors = {
//   1: '#FF9AA2',
//   2: '#FFB7B2',
//   3: '#FFDAC1',
//   4: '#E2F0CB',
//   5: '#B5EAD7',
//   6: '#C7CEEA'
// }

const PieChart = (props) => {
  const frameProps = {
    /* --- Data --- */
    data: props.data,

    /* --- Size --- */
    size: [null, 220],
    // margin: 60,
    margin: 30,

    /* --- Layout --- */
    // type: { type: 'bar', innerRadius: 40 },
    type: 'bar',
    projection: 'radial',
    dynamicColumnWidth: 'count',

    /* --- Process --- */
    oAccessor: 'type',

    /* --- Customize --- */
    style: (d) => {
      return {
        fill:
          d && props.styles && props.styles.fill && props.styles.fill[d.type],
        stroke: 'white',
      }
    },
    // title: <g><text textAnchor='middle' style={{ fontWeight: 600, fontSize: '1rem' }}>Fit Coverage</text></g>,

    /* --- Annotate --- */
    // oLabel: true,
    pieceHoverAnnotation: true,
    tooltipContent: props.tooltipContent,
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ResponsiveOrdinalFrame {...frameProps} responsiveWidth />
      {/* <SizeLegend /> */}
    </div>
  )
}

export default PieChart
