import _ from 'lodash'
import { createSelector } from 'reselect'

export const sortedProjects = createSelector(
  (state) => state.project.entities,
  (state) => state.project.entitiesLoaded,
  (projects, hasProjectsLoaded) => {
    if (!hasProjectsLoaded || !projects) {
      return null
    } else {
      return _.sortBy(Object.values(projects), (proj) =>
        proj.name.toLowerCase()
      )
    }
  }
)
