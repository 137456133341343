import _ from 'lodash'
import React from 'react'
import Card from '@material-ui/core/Card'
import DeleteIcon from '@material-ui/icons/Clear'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

import LoadingSpinner from 'components/size_explorer/lib/LoadingSpinner'
import AreaChart from 'components/size_explorer/charts/AreaChart'
import Legend from 'components/size_explorer/audience/distributions/Legend'
import Reload from 'components/size_explorer/lib/Reload'

import { parseCamelCase } from 'lib/format'

// values are an array of object datums which take the form { <measurement>: 34, count: 1224 }
function bucketData(values, measurement, bucketSize) {
  const groups = _.groupBy(
    values.map((d) => ({
      ...d,
      bucket: Math.round(d[measurement] / bucketSize) * bucketSize,
    })),
    'bucket'
  )
  return Object.keys(groups).map((k) => ({
    [measurement]: parseInt(k),
    count: groups[k].reduce((tot, val) => tot + val.count, 0),
  }))
}

export default ({ chartData, measurement, onDelete, onReload, units }) => {
  // format our chart data for semiotic
  const data = React.useMemo(() => {
    const { audience, population } = chartData

    if (
      Object.entries(audience).length !== 0 &&
      Object.entries(population).length !== 0 &&
      audience.data.length !== 0 &&
      population.data.length !== 0
    ) {
      if (measurement === 'height') {
        // NOTE: for height data we bucket the values to avoid some rounding issues
        const bucketSize = units === 'metric' ? 5 : 2

        return [
          {
            title: 'population', // base population
            coordinates: bucketData(population.data, measurement, bucketSize),
          },
          {
            title: 'audience', // filtered audience
            coordinates: bucketData(audience.data, measurement, bucketSize),
          },
        ]
      } else if (measurement === 'weight') {
        // NOTE: for weight data we bucket the values to improve readability
        const bucketSize = units === 'metric' ? 4 : 5

        return [
          {
            title: 'population', // base population
            coordinates: bucketData(population.data, measurement, bucketSize),
          },
          {
            title: 'audience', // filtered audience
            coordinates: bucketData(audience.data, measurement, bucketSize),
          },
        ]
      } else {
        return [
          {
            title: 'population', // base population
            coordinates: population.data,
          },
          {
            title: 'audience', // filtered audience
            coordinates: audience.data, // same structure as 'const { data } = responseFromEndpoint'
          },
        ]
      }
    } else {
      return []
    }
  }, [chartData, measurement, units])

  return (
    <Card style={{ position: 'relative', padding: '1rem 12px', height: 400 }}>
      <Typography
        variant="subtitle2"
        align="center"
        style={{ marginBottom: '1rem', fontWeight: 600 }}
      >{`${parseCamelCase(measurement)}`}</Typography>
      <IconButton
        onClick={onDelete}
        size="small"
        style={{ position: 'absolute', top: '10px', right: '6px' }}
      >
        <DeleteIcon />
      </IconButton>
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ height: 'calc(100% - 2rem)' }}
      >
        {chartData.isFetching ? (
          <LoadingSpinner />
        ) : data.length > 0 ? ( // empty array is not a falsy value, hence arr.length > 0 conditional
          <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            <div
              style={{
                position: 'absolute',
                width: '100%',
                top: 0,
                right: 0,
                zIndex: 2,
              }}
            >
              <Legend
                audienceStd={
                  chartData.audience.std ? chartData.audience.std : ''
                }
                audienceMean={
                  chartData.audience.mean ? chartData.audience.mean : ''
                }
                populationStd={
                  chartData.population.std ? chartData.population.std : ''
                }
                populationMean={
                  chartData.population.mean ? chartData.population.mean : ''
                }
              />
            </div>
            <AreaChart measurement={measurement} data={data} />
          </div>
        ) : (
          <Reload errorMessage="Failed to load data" handleReload={onReload} />
        )}
      </Grid>
    </Card>
  )
}
