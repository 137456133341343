import _ from 'lodash'
import React from 'react'
import Select from 'react-select'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Slide from '@material-ui/core/Slide'
import TextField from '@material-ui/core/TextField'

import ButtonWithSpinner from 'components/lib/ButtonWithSpinner'
import * as ProjectAPI from 'lib/api/project'

const useStyles = makeStyles((theme) => ({
  dialog: {
    overflowY: 'visible',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialogContent: {
    minWidth: '400px',
    overflowY: 'visible',
  },
  section: {
    marginTop: theme.spacing(2),
  },
  submitButton: {
    marginLeft: theme.spacing(2),
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

const emptyProject = {
  name: '',
  population: '',
  units: '',
}

const formIsComplete = (form) => {
  return (
    !_.isEmpty(form.name) &&
    !_.isEmpty(form.population) &&
    !_.isEmpty(form.units)
  )
}

export default ({ onClose, onSave, open, populations, project }) => {
  const classes = useStyles()

  const [projectForm, setProjectForm] = React.useState(emptyProject)
  const [populationOptions, setPopulationOptions] = React.useState([])
  const [savingProject, setSavingProject] = React.useState(null) // api call status

  React.useEffect(() => {
    if (project) {
      setProjectForm({
        name: project.name,
        population: project.population,
        units: project.units,
      })
    } else {
      setProjectForm(emptyProject)
    }
  }, [project])

  React.useEffect(() => {
    if (populations) {
      setPopulationOptions(
        populations.map((pop) => ({ label: pop.name, value: pop.id }))
      )
    }
  }, [populations])

  const handleClose = () => {
    setProjectForm(emptyProject)
    setSavingProject(null)
    onClose()
  }

  const handleCreate = () => {
    setSavingProject('working')
    ProjectAPI.createProject(projectForm)
      .then((project) => {
        setSavingProject(null)
        onSave(project)
      })
      .catch((err) => {
        console.log(err)
        setSavingProject('error')
      })
  }

  const handleUpdate = () => {
    setSavingProject('working')
    ProjectAPI.updateProject(project.id, projectForm)
      .then((project) => {
        setSavingProject(null)
        onSave(project)
      })
      .catch((err) => {
        console.log(err)
        setSavingProject('error')
      })
  }

  return (
    <Dialog
      classes={{ paper: classes.dialog }}
      onClose={handleClose}
      open={open}
      keepMounted={false}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        {project ? 'Update project' : 'Create new project'}
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <section>
          <TextField
            fullWidth
            autoFocus
            size="small"
            label="Name"
            variant="outlined"
            value={projectForm.name}
            onChange={(e) =>
              setProjectForm({ ...projectForm, name: e.target.value })
            }
          />
        </section>

        <section className={classes.section}>
          <Select
            options={populationOptions}
            value={
              projectForm.population === ''
                ? null
                : _.find(
                    populationOptions,
                    (pop) => pop.value === projectForm.population
                  )
            }
            onChange={(choice) =>
              setProjectForm({ ...projectForm, population: choice.value })
            }
            placeholder="Avatar population"
            isSearchable={false}
            isDisabled={Boolean(project) || populationOptions.length === 0}
            styles={{
              menuList: (provided, state) => ({
                ...provided,
                zIndex: 999999,
              }),
            }}
          />
        </section>

        <section className={classes.section}>
          <FormControl>
            <RadioGroup
              value={projectForm.units}
              onChange={(e) =>
                setProjectForm({ ...projectForm, units: e.target.value })
              }
              style={{ flexDirection: 'row' }}
            >
              <FormControlLabel
                value="metric"
                control={<Radio color="primary" />}
                label="Metric (cm/kg)"
                disabled={Boolean(project)}
              />
              <FormControlLabel
                value="us"
                control={<Radio color="primary" />}
                label="US (in/lb)"
                disabled={Boolean(project)}
              />
            </RadioGroup>
          </FormControl>
        </section>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        {savingProject === 'error' ? (
          <div>
            Your {project ? 'update' : 'create'} failed to save{' '}
            <span role="img" aria-label=":cry:">
              😢
            </span>
          </div>
        ) : (
          <div />
        )}
        <div>
          <Button size="small" onClick={handleClose}>
            Cancel
          </Button>
          <ButtonWithSpinner
            className={classes.submitButton}
            disabled={savingProject !== null || !formIsComplete(projectForm)}
            isSpinning={savingProject === 'working'}
            size="small"
            variant="contained"
            color="primary"
            onClick={project ? handleUpdate : handleCreate}
            spinnerProps={{ color: 'secondary' }}
          >
            {project ? 'Save' : 'Create'}
          </ButtonWithSpinner>
        </div>
      </DialogActions>
    </Dialog>
  )
}
