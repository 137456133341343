import _ from 'lodash'
import { combineReducers } from 'redux'

// the collection of all Projects as a map keyed by projectId
// e.g. { projectId: { ...project } }
const entities = (state = {}, action) => {
  switch (action.type) {
    case 'PROJECTS_COLL_SET':
      return _.keyBy(action.projects, 'id')
    case 'PROJECT_UPDATED':
      return { ...state, [action.project.id]: action.project }
    case 'PROJECT_UPDATED_AUDIENCE':
      return {
        ...state,
        [action.projectId]: {
          ...state[action.projectId],
          audience: action.audience,
        },
      }
    case 'SIZE_SPEC_CREATED':
    case 'SIZE_SPEC_UPDATED':
    case 'REFERENCE_AVATARS_SAVED':
      return {
        ...state, // other projects
        [action.projectId]: {
          ...state[action.projectId], // rest of project attrs
          sizeSpecs: {
            ...state[action.projectId].sizeSpecs, // existing project specs
            [action.payload.id]: action.payload,
          },
        },
      }
    case 'PROJECT_UPDATED_SIZINGAVATARS':
      return {
        ...state, // other projects
        [action.project.id]: action.project,
      }
    case 'DELETE_SIZE_SPEC_SUCCESS':
      return {
        ...state, // other projects
        [action.projectId]: {
          ...state[action.projectId], // rest of project attrs
          sizeSpecs: _.omit(
            state[action.projectId].sizeSpecs,
            action.meta.sizeSpecId
          ), // omit deleted spec
        },
      }
    case 'CREATE_PROJECT_SUCCESS':
      return { ...state, [action.payload.id]: action.payload }
    case 'DELETE_PROJECT_SUCCESS':
      return _.omit(state, action.project.id)
    default:
      return state
  }
}

// this is just to track if we have ever loaded the entities with values
// since we want the default value of that reducer to be an object
const entitiesLoaded = (state = false, action) => {
  switch (action.type) {
    case 'PROJECTS_COLL_SET':
      return true
    default:
      return state
  }
}

// valid choices are: audience, specs, fitcoverage, visualize, presentation
const section = (state = 'audience', action) => {
  switch (action.type) {
    case 'PROJECT_CLEAR':
    case 'PROJECT_SET':
    case 'CREATE_PROJECT_SUCCESS':
      return 'audience'
    case 'PROJECT_SECTION':
      return action.section
    // when selecting a spec move to fit coverage section if not already in fit coverage or visualize
    case 'SET_SIZE_SPEC':
      if (
        state !== 'fitcoverage' &&
        state !== 'visualize' &&
        state !== 'presentation'
      ) {
        return 'fitcoverage'
      } else {
        return state
      }
    default:
      return state
  }
}

// the id of the currently selected/visible Project
const selected = (state = null, action) => {
  switch (action.type) {
    case 'PROJECT_CLEAR':
      return null
    case 'PROJECT_SET':
      return action.payload.id
    case 'CREATE_PROJECT_SUCCESS':
      return action.payload.id
    default:
      return state
  }
}

// NOTE: this is just the id of the selected spec
const selectedSizeSpecId = (state = null, action) => {
  switch (action.type) {
    case 'PROJECT_CLEAR':
    case 'PROJECT_SET':
    case 'CREATE_PROJECT_SUCCESS':
      return null
    case 'SET_SIZE_SPEC':
      return action.sizeSpecId
    case 'DELETE_SIZE_SPEC_SUCCESS':
      if (action.meta.sizeSpecId === state) {
        return null
      } else {
        return state
      }
    default:
      return state
  }
}

export default combineReducers({
  entities,
  entitiesLoaded,
  section, // what part of size explorer we are viewing
  selected,
  selectedSizeSpecId,
})
