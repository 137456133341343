import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'

import ButtonWithSpinner from 'components/lib/ButtonWithSpinner'

const useStyles = makeStyles((theme) => ({
  confirmButton: {
    marginLeft: 8,
  },
  dialogActions: {
    minWidth: '400px',
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

export default ({
  confirmButtonText,
  confirmText,
  errorText,
  isWorking,
  onClose,
  onConfirm,
  open,
  title,
}) => {
  const classes = useStyles()

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="simple-dialog-title">
        {title || 'Confirmation'}
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          {confirmText || 'Confirm you action'}
        </DialogContentText>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        {errorText ? <div>{errorText}</div> : <div />}
        <div>
          <Button size="small" onClick={onClose}>
            Cancel
          </Button>
          <ButtonWithSpinner
            className={classes.confirmButton}
            disabled={isWorking}
            isSpinning={isWorking}
            size="small"
            variant="contained"
            color="primary"
            onClick={onConfirm}
          >
            {confirmButtonText || 'Confirm'}
          </ButtonWithSpinner>
        </div>
      </DialogActions>
    </Dialog>
  )
}
