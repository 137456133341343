import clsx from 'clsx'
import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import * as ContextActions from 'actions/context'
import { formatAudienceInput, formatNumber } from 'lib/format'
import { getTotalPopulation } from 'lib/fit_coverage'
import { fitCoverageTotalsSelector } from 'selectors/fit_coverage'
import { selectedPopulation } from 'selectors/populations'

const useStyles = makeStyles((theme) => ({
  contextTitle: {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontSize: '0.8rem',
    fontWeight: 600,
  },
  highlightText: {
    fontWeight: 600,
  },
  root: {
    height: 'calc(100vh)',
    backgroundColor: theme.palette.common.white,
    borderLeft: '1px solid #ddd',
    paddingTop: theme.spacing(4),
  },
  section: {
    padding: theme.spacing(0, 2, 0, 2),
    marginBottom: theme.spacing(4),
  },
  text: {
    color: '#444',
    fontSize: '0.8rem',
    lineHeight: '1.5rem',
  },
}))

const ContextBar = ({
  population,
  project,
  context: { isUpdatingCounts, populationCount, audienceCount },
  selectedSpec,
  specCount,
  specCountIsLoading,
  getContext,
}) => {
  const classes = useStyles()

  // when the project changes update the population & audience counts
  React.useEffect(() => {
    if (project.id) {
      getContext(project)
    }
  }, [project, getContext])

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        style={{ margin: 0, overflowY: 'auto' }}
      >
        <Grid item className={classes.section}>
          <Typography className={classes.contextTitle}>Population</Typography>
          <Typography className={classes.text}>
            {(population && population.name) || '(unnamed)'}
          </Typography>
          {isUpdatingCounts ? (
            <Typography
              className={classes.text}
              style={{ fontStyle: 'italic' }}
            >
              Loading...
            </Typography>
          ) : (
            <Typography className={clsx(classes.text, classes.highlightText)}>
              {populationCount
                ? `${formatNumber(populationCount)} scans`
                : null}
            </Typography>
          )}
        </Grid>

        <Grid item className={classes.section}>
          <Typography className={classes.contextTitle}>Audience</Typography>
          {formatAudienceInput(project.audience).map((param, idx) => (
            <Typography
              className={classes.text}
              key={`audience-parameter-summary-${idx}`}
            >
              {`${param.name}: ${param.value}`}
            </Typography>
          ))}
          {isUpdatingCounts ? (
            <Typography
              className={classes.text}
              style={{ fontStyle: 'italic' }}
            >
              Loading...
            </Typography>
          ) : (
            <Typography className={clsx(classes.text, classes.highlightText)}>
              {audienceCount ? `${formatNumber(audienceCount)} scans` : null}
            </Typography>
          )}
        </Grid>

        {selectedSpec && selectedSpec.id && (
          <Grid item className={classes.section}>
            <Typography className={classes.contextTitle}>Size Spec</Typography>
            <Typography className={classes.text}>
              {selectedSpec.name || ''}
            </Typography>
            {specCountIsLoading ? (
              <Typography
                className={classes.text}
                style={{ fontStyle: 'italic' }}
              >
                Loading...
              </Typography>
            ) : (
              <Typography className={clsx(classes.text, classes.highlightText)}>
                {specCount ? `${specCount} scans` : null}
              </Typography>
            )}
          </Grid>
        )}
      </Grid>
    </div>
  )
}

const mapStateToProps = (state) => ({
  population: selectedPopulation(state),
  project: state.project.entities[state.project.selected],
  selectedSpec:
    state.project.entities[state.project.selected].sizeSpecs[
      state.project.selectedSizeSpecId
    ],
  context: state.context,
  specCount: getTotalPopulation(fitCoverageTotalsSelector(state).sizes),
  specCountIsLoading: state.fitCoverage.isFetching,
})

const mapDispatchToProps = (dispatch) => ({
  getContext: (project) => dispatch(ContextActions.getContext(project)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ContextBar)
