import * as SessionApi from 'lib/api/session'
import * as auth from 'lib/auth'

// THUNK
export const oauth2Login = (authResult) => (dispatch) => {
  // store our auth token details
  auth.setSession(authResult)

  // now verify the token with our api
  dispatch(validateSession())
}

export const validateSession = () => (dispatch) => {
  return SessionApi.current()
    .then((session) => {
      // const { email, name } = session
      const { email } = session

      // window.intercomSettings = {
      //   ...window.intercomSettings,
      //   name,
      //   email
      // }

      if (
        typeof window.onConversationsAPIReady === 'function' &&
        typeof window.sendIdentify === 'function'
      ) {
        window.sendIdentify(email)
      }

      // if (typeof window.Intercom === 'function') {
      //   window.Intercom('update', window.intercomSettings)
      // }

      dispatch({
        type: 'VALIDATE_SESSION_SUCCESS',
        payload: session,
      })

      // when we know we have a valid token grab profile details directly from auth0
      auth
        .getWebAuth(window.location.href)
        .client.userInfo(auth.getSessionToken(), (error, profile) => {
          if (profile) {
            dispatch({
              type: 'AUTH_PROFILE_SUCCESS',
              profile,
            })
          } else {
            dispatch({
              type: 'AUTH_PROFILE_FAIL',
              error,
            })
          }
        })
    })
    .catch((error) => {
      dispatch({
        type: 'VALIDATE_SESSION_FAIL',
        error,
      })
    })
}
