import { createSelector } from 'reselect'

// filter down our complete collection of populations to just the ones the user purchased
export const purchasedPopulations = createSelector(
  (state) => state.populations,
  (state) => state.session && state.session.user,
  (populations, user) => {
    if (!populations || !user) {
      return []
    } else {
      return user.team.populations
        .map((pop) => populations[pop])
        .filter((p) => p !== undefined)
    }
  }
)

export const selectedPopulation = createSelector(
  (state) => state.populations,
  (state) => state.project.entities[state.project.selected],
  (populations, project) => {
    if (
      !populations ||
      !project ||
      !project.population ||
      !populations[project.population]
    ) {
      return null
    } else {
      return populations[project.population]
    }
  }
)
