import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import SizeTable from 'components/size_explorer/fit_coverage/size_chart/SizeTable'

const SizeChart = ({
  fitCoverageTotals,
  isLoading,
  primaryMeasurements,
  sizeData,
}) => {
  if (isLoading) {
    return (
      <Paper>
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          style={{ height: 200 }}
        >
          <CircularProgress size={32} color="primary" disableShrink />
        </Grid>
      </Paper>
    )
  } else {
    return (
      <SizeTable
        fitCoverageTotals={fitCoverageTotals}
        primaryMeasurements={primaryMeasurements}
        sizeData={sizeData}
      />
    )
  }
}

export default SizeChart
