import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 400,
    background: '#FFF',
  },
  input: {
    height: 44,
  },
}))

export default ({ spec, onSpecUpdate }) => {
  const classes = useStyles()
  return (
    <TextField
      id="outlined-name"
      label="Name"
      value={spec.name}
      classes={{ root: classes.root }}
      InputProps={{ className: classes.input }}
      InputLabelProps={{ shrink: true }}
      onChange={(e) => onSpecUpdate('name', e.target.value)}
      margin="normal"
      variant="outlined"
    />
  )
}
