import React from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import clsx from 'clsx'
import moment from 'moment'
import { fade, makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import FolderIcon from '@material-ui/icons/Folder'
import IconButton from '@material-ui/core/IconButton'
import LaunchIcon from '@material-ui/icons/Launch'
import Typography from '@material-ui/core/Typography'

import ConfirmationDialog from 'components/lib/ConfirmationDialog'
import ProjectFormDialog from 'components/homepage/ProjectFormDialog'
import * as ProjectAPI from 'lib/api/project'
import * as Units from 'lib/units'

const styles = makeStyles((theme) => ({
  avatar: {
    // color: '#fff',
    // backgroundColor: theme.palette.primary.main
  },
  card: {
    minWidth: '320px',
    maxWidth: '436px',
    height: '240px',
    display: 'flex',
    flexDirection: 'column',
  },
  cardActions: {
    borderTop: '1px solid #eee',
    justifyContent: 'space-evenly',
  },
  cardContent: {
    flex: 1,
    overflow: 'wrap',
  },
  cardHeader: {
    borderBottom: '1px solid #eee',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  cardHeaderContent: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cardHeaderRaised: {
    backgroundColor: fade(theme.palette.primary.main, 0.1),
    borderBottom: '1px solid #ddd',
  },
}))

export default function ProjectCard({
  onDelete,
  onLaunch,
  onUpdate,
  populations,
  project,
}) {
  const classes = styles()

  const [isRaised, setIsRaised] = React.useState(false)
  const [deletingProject, setDeletingProject] = React.useState(null)
  const [isEditingProject, setIsEditingProject] = React.useState(false)

  const handleClose = () => {
    setDeletingProject(null)
    setIsEditingProject(false)
    setIsRaised(false)
  }

  const handleDelete = () => {
    setDeletingProject('working')

    ProjectAPI.deleteProject(project.id)
      .then((response) => {
        setDeletingProject(null)
        onDelete(project)
      })
      .catch((error) => {
        console.log(error)
        setDeletingProject('error')
      })
  }

  const handleUpdate = (project) => {
    onUpdate(project)
    setIsEditingProject(false)
  }

  // const favoriteAction = (
  //   <IconButton aria-label='favorite'>
  //     <StarOutlineIcon />
  //   </IconButton>
  // )

  // const unfavoriteAction = (
  //   <IconButton aria-label='favorite'>
  //     <StarIcon />
  //   </IconButton>
  // )

  return (
    <Card
      className={classes.card}
      raised={isRaised}
      onMouseEnter={() => setIsRaised(true)}
      onMouseLeave={() => setIsRaised(false)}
    >
      <CardHeader
        to={`/projects/${project.id}`}
        component={Link}
        className={clsx(
          classes.cardHeader,
          isRaised ? classes.cardHeaderRaised : null
        )}
        classes={{ content: classes.cardHeaderContent }}
        // action={project.favorite ? unfavoriteAction : favoriteAction}
        avatar={
          <Avatar className={classes.avatar}>
            <FolderIcon />
          </Avatar>
        }
        title={project.name}
        titleTypographyProps={{ variant: 'h6', color: 'primary' }}
        subheader={moment(project.updatedAt).format('LL')}
        subheaderTypographyProps={{ variant: 'caption' }}
      />
      <CardContent className={classes.cardContent}>
        <Typography variant="caption" style={{ fontWeight: 600 }}>
          {populations[project.population]
            ? populations[project.population].name
            : ''}
        </Typography>
        <Typography variant="caption" component="div">
          {Units.getUnitsLabel(project.units)}
        </Typography>
        <Typography variant="caption" component="div">
          {_.isEmpty(project.sizeSpecs)
            ? 'no size specs'
            : `${Object.keys(project.sizeSpecs).length} size specs`}
        </Typography>
      </CardContent>

      <CardActions className={classes.cardActions}>
        <IconButton
          color={isRaised ? 'primary' : 'default'}
          onClick={() => onLaunch(project)}
          aria-label="launch"
        >
          <LaunchIcon />
        </IconButton>
        <IconButton
          color={isRaised ? 'primary' : 'default'}
          onClick={() => setIsEditingProject(true)}
          aria-label="edit"
        >
          <EditIcon />
        </IconButton>
        <IconButton
          color={isRaised ? 'primary' : 'default'}
          onClick={() => setDeletingProject('confirm')}
          aria-label="delete"
        >
          <DeleteIcon />
        </IconButton>
      </CardActions>

      <ProjectFormDialog
        open={isEditingProject}
        onClose={handleClose}
        onSave={handleUpdate}
        populations={Object.values(populations)}
        project={project}
      />

      <ConfirmationDialog
        confirmButtonText="Delete"
        confirmText={
          <span>
            Are you sure you want to delete <b>{project.name}</b>?
          </span>
        }
        errorText={
          deletingProject === 'error' ? (
            <span>
              Project deletion failed{' '}
              <span role="img" aria-label=":cry:">
                😢
              </span>
            </span>
          ) : null
        }
        isWorking={deletingProject === 'working'}
        onClose={handleClose}
        onConfirm={handleDelete}
        open={Boolean(deletingProject)}
        title="Confirm Delete"
      />
    </Card>
  )
}
