import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import App from './App'
import * as serviceWorker from './serviceWorker'

import { Provider } from 'react-redux'
import store from './store/store'

import 'assets/css/theme.css'
import 'assets/css/annotations.css'

/* Initialize google analytics */
const trackingIds = ['UA-126660070-5']

const initGA = () => {
  const trackers = trackingIds.map((trackingId) => ({
    trackingId,
    gaOptions: { name: 'bbInsights' },
    debug: process.env.NODE_ENV === 'development',
  }))

  ReactGA.initialize(trackers)
  ReactGA.pageview(window.location.pathname + window.location.search)
}

initGA()
/* End */

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
