import * as AvatarsApi from 'lib/api/avatars'
import * as BookmarksAPI from 'lib/api/bookmark'

// THUNK
export const bookmarksAdd = (bmrk) => (dispatch) => {
  BookmarksAPI.addBookmark(bmrk)
    .then((bookmark) =>
      dispatch({ type: 'AVATARS_BOOKMARK_ADD_SUCCESS', bookmark })
    )
    .catch((error) => dispatch({ type: 'AVATARS_BOOKMARK_ADD_FAIL', error }))
}

// THUNK
export const bookmarksList = () => (dispatch) => {
  dispatch({ type: 'AVATARS_BOOKMARK_LIST_START' })
  BookmarksAPI.getBookmarkList()
    .then((result) =>
      dispatch({ type: 'AVATARS_BOOKMARK_LIST_SUCCESS', result })
    )
    .catch((error) => dispatch({ type: 'AVATARS_BOOKMARK_LIST_FAIL', error }))
}

// THUNK
export const bookmarksRemove = (pkgId) => (dispatch) => {
  BookmarksAPI.removeBookmark(pkgId)
    .then(() => dispatch({ type: 'AVATARS_BOOKMARK_REMOVE_SUCCESS', pkgId }))
    .catch((error) => dispatch({ type: 'AVATARS_BOOKMARK_REMOVE_FAIL', error }))
}

// expects an array of bookmark objects, each with a packageId attribute
export const bookmarksRemoved = (bookmarks) => ({
  type: 'AVATARS_BOOKMARKS_REMOVED',
  bookmarks,
})

// THUNK
export const getAvatars = () => (dispatch) => {
  dispatch({ type: 'AVATARS_PURCHASED_LIST_START' })
  AvatarsApi.listAvatars()
    .then((result) =>
      dispatch({ type: 'AVATARS_PURCHASED_LIST_SUCCESS', result })
    )
    .catch((error) => dispatch({ type: 'AVATARS_PURCHASED_LIST_FAIL', error }))
}

// THUNK
export const getOrders = () => (dispatch) => {
  dispatch({ type: 'AVATARS_ORDERS_LIST_START' })
  AvatarsApi.listOrders()
    .then((result) => dispatch({ type: 'AVATARS_ORDERS_LIST_SUCCESS', result }))
    .catch((error) => dispatch({ type: 'AVATARS_ORDERS_LIST_FAIL', error }))
}

export const orderCreated = (order) => ({
  type: 'AVATARS_ORDER_CREATED',
  order,
})

export const setPage = (page) => ({
  type: 'AVATARS_PAGE_SET',
  page,
})
