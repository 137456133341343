import _ from 'lodash'
import React from 'react'
import { useDispatch } from 'react-redux'
import Select from 'react-select'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

import AngleSelect from 'components/lib/AngleSelect'
import AvatarBrowser from 'components/avatars/sizing/modal/AvatarBrowser'
import AvatarGallery from 'components/avatars/sizing/modal/AvatarGallery'
import PageTitle from 'components/lib/PageTitle'
import * as ProjectsActions from 'actions/projects'
import * as ProjectsApi from 'lib/api/project'

const useStyles = makeStyles((theme) => ({
  adornment: {
    display: 'flex',
    flex: 3,
    marginBottom: theme.spacing(1),
  },
  angleSelect: {
    minWidth: 350,
    maxWidth: 400,
    marginRight: theme.spacing(2),
  },
  avatarCollGrid: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  emptyState: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    minHeight: '400px',
  },
  icon: {
    cursor: 'pointer',
    alignSelf: 'center',
  },
  main: {
    padding: theme.spacing(2),
    width: 'calc(100vw - 80px)',
    height: 'calc(100vw - 80px)',
  },
  root: {
    // width: 'calc(90vw)'
  },
}))

const SizingSearchModal = ({
  onClose,
  open,
  population,
  project,
  sizeSpec,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [angle, setAngle] = React.useState('000')
  const [selectedSize, setSelectedSize] = React.useState(null)

  const sizeOptions = React.useMemo(() => {
    if (sizeSpec) {
      return sizeSpec.sizeDefs.map((def) => ({
        label: def.size,
        value: def.size,
      }))
    } else {
      return []
    }
  }, [sizeSpec])

  const sizeDef = React.useMemo(() => {
    if (selectedSize) {
      let def = _.find(
        sizeSpec.sizeDefs,
        (def) => def.size === selectedSize.value
      )

      // make sure we have `avatarModels` defined in the sizeDef, which may not be there if this is the
      // first time the user is working through this process on this size spec
      if (!def.avatarModels) {
        def = {
          ...def,
          avatarModels: {
            primary: null,
            variants: [],
          },
        }
      }

      return def
    }
  }, [selectedSize, sizeSpec])

  const handleAngleSelect = (angle) => {
    setAngle(angle)
  }

  const handleSetAvatars = (avatars) => {
    // make api call to save changes and then dispatch
    ProjectsApi.setModelAvatars(project.id, sizeSpec.id, {
      size: sizeDef.size,
      avatars,
    })
      .then((updatedProject) => {
        dispatch(ProjectsActions.updatedSizingAvatars(updatedProject))
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="scan-profile"
      className={classes.root}
      maxWidth="xl"
    >
      <Box className={classes.main}>
        <div className={classes.adornment}>
          <Grid container justify="space-between">
            <Grid item style={{ display: 'flex', alignContent: 'center' }}>
              <Typography
                variant="h5"
                component="span"
                style={{ fontWeight: 600 }}
              >
                Size:&nbsp;
              </Typography>
              <Select
                options={sizeOptions}
                value={selectedSize}
                onChange={(choice) => setSelectedSize(choice)}
                styles={{
                  container: (provided, state) => ({
                    ...provided,
                    minWidth: '250px',
                    maxWidth: '400px',
                    display: 'inline-block',
                  }),
                  groupHeading: (provided, state) => ({
                    ...provided,
                    color: 'purple',
                    fontWeight: 600,
                  }),
                  menuList: (provided, state) => ({
                    ...provided,
                    zIndex: 999,
                  }),
                  valueContainer: (provided, state) => ({
                    ...provided,
                    fontWeight: 600,
                  }),
                }}
                isSearchable={false}
                placeholder="Size"
              />
            </Grid>
            <Grid item style={{ display: 'flex' }}>
              <div className={classes.angleSelect}>
                <AngleSelect
                  angle={angle}
                  onAngleSelect={handleAngleSelect}
                  padRight
                />
              </div>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </div>

        {sizeDef ? (
          <Grid container>
            <Grid item xs={12}>
              <AvatarGallery
                angle={angle}
                avatars={sizeDef.avatarModels}
                gender={population.filters.gender}
                onSetAvatars={handleSetAvatars}
              />
            </Grid>

            <Grid item xs={12} style={{ paddingTop: 25 }}>
              <PageTitle title="Find avatars" />
            </Grid>

            <Grid item xs={12}>
              <AvatarBrowser
                angle={angle}
                avatars={sizeDef.avatarModels}
                onSetAvatars={handleSetAvatars}
                population={population}
                project={project}
                sizeDef={sizeDef}
                sizeSpec={sizeSpec}
              />
            </Grid>
          </Grid>
        ) : (
          <div className={classes.emptyState}>
            <Typography variant="h5">Pick a size to begin</Typography>
          </div>
        )}
      </Box>
    </Dialog>
  )
}

export default SizingSearchModal
