import { combineReducers } from 'redux'

// sizespec summary stats
const summary = (state = null, action) => {
  switch (action.type) {
    case 'PROJECT_CLEAR':
    case 'SET_SIZE_SPEC':
    case 'SIZE_SPEC_UPDATED':
    case 'SPEC_SUMMARY_START':
      return null
    case 'SPEC_SUMMARY_SUCCESS':
      return action.data
    default:
      return state
  }
}

const DEFAULT = {
  isFetching: false,
  data: null,
}
// sizespec distribution data for a single measure
const distributions = (state = { ...DEFAULT }, action) => {
  switch (action.type) {
    case 'PROJECT_CLEAR':
    case 'SET_SIZE_SPEC':
    case 'SIZE_SPEC_UPDATED':
    case 'SPEC_DISTRIBUTIONS_FAIL':
      return { ...DEFAULT }
    case 'SPEC_DISTRIBUTIONS_START':
      return {
        ...DEFAULT,
        isFetching: true,
      }
    case 'SPEC_DISTRIBUTIONS_SUCCESS':
      return {
        isFetching: false,
        data: action.sizes,
      }
    default:
      return state
  }
}

export default combineReducers({
  summary,
  distributions,
})
