import React from 'react'
import { connect } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

import * as ScansActions from 'actions/scans'

const FEMALE_PLACEHOLDER = '/assets/blank-female.png'
const MALE_PLACEHOLDER = '/assets/blank-male.png'

const styles = makeStyles((theme) => ({
  lowerLeftAdornment: {
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  lowerRightAdornment: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  upperLeftAdornment: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  upperRightAdornment: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  image: {
    maxWidth: 300,
    width: '100%',
  },
  loadingIndicator: {
    position: 'absolute',
    left: 0,
    top: '100px',
    width: '100%',
    textAlign: 'center',
  },
  progress: {
    color: fade(theme.palette.primary.main, 0.3),
  },
  root: {
    position: 'relative',
    textAlign: 'center',
  },
}))

const ScanImage = ({
  angle = '000',
  fetchScan,
  gender,
  isFetching = false,
  onEvent,
  pkgId,
  scanPng,
  showLoadingIndicator = true,
  lowerLeftAdornment,
  lowerRightAdornment,
  upperLeftAdornment,
  upperRightAdornment,
}) => {
  const classes = styles()

  const placeholderImage =
    gender === 'male' ? MALE_PLACEHOLDER : FEMALE_PLACEHOLDER
  const isLoading = scanPng && scanPng === 'working'
  const url = scanPng && scanPng !== 'working' ? scanPng : placeholderImage

  const isWorking = isFetching || isLoading

  React.useEffect(() => {
    if (pkgId && !scanPng) {
      fetchScan(pkgId, angle)
    }
  }, [angle, fetchScan, pkgId, scanPng])

  return (
    <div className={classes.root}>
      <img
        src={url}
        className={classes.image}
        style={{ maxWidth: 300 }}
        alt=""
      />

      {showLoadingIndicator && isWorking && (
        <div className={classes.loadingIndicator}>
          <CircularProgress
            size={32}
            disableShrink
            classes={{ root: classes.progress }} // to get faded color
          />
        </div>
      )}

      {upperLeftAdornment && pkgId && !isWorking && (
        <span className={classes.upperLeftAdornment}>{upperLeftAdornment}</span>
      )}

      {upperRightAdornment && pkgId && !isWorking && (
        <span className={classes.upperRightAdornment}>
          {upperRightAdornment}
        </span>
      )}

      {lowerLeftAdornment && pkgId && !isWorking && (
        <span className={classes.lowerLeftAdornment}>{lowerLeftAdornment}</span>
      )}

      {lowerRightAdornment && pkgId && !isWorking && (
        <span className={classes.lowerRightAdornment}>
          {lowerRightAdornment}
        </span>
      )}
    </div>
  )
}

// NOTE: we connect this component to the redux store purely to use redux as a cache
//       this way we avoid fetching binary assets multiple times for no reason

const mapStateToProps = (state, { angle = '000', pkgId }) => ({
  scanPng: pkgId ? state.scanImages[`${pkgId}-${angle}`] : null,
})

const mapDispatchToProps = (dispatch) => ({
  fetchScan: (packageId, angle) =>
    dispatch(ScansActions.fetchScan(packageId, angle)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ScanImage)
