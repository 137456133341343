import * as Queries from 'lib/api/queries'

export const FETCH_CONTEXT = 'FETCH_CONTEXT'
export const FETCH_CONTEXT_SUCCESS = 'FETCH_CONTEXT_SUCCESS'
export const FETCH_CONTEXT_FAILURE = 'FETCH_CONTEXT_FAILURE'

export const getContext = (project) => (dispatch) => {
  const { population: populationId, audience, units } = project

  dispatch({ type: FETCH_CONTEXT })

  Promise.all([
    Queries.audienceDistribution(populationId, {}, units, 'chestBustGirth'),
    Queries.audienceDistribution(
      populationId,
      audience,
      units,
      'chestBustGirth'
    ),
  ])
    .then(([populationData, audienceData]) => {
      dispatch({
        type: FETCH_CONTEXT_SUCCESS,
        payload: {
          populationCount: populationData.data.reduce(
            (total, val) => total + val.count,
            0
          ),
          audienceCount: audienceData.data.reduce(
            (total, val) => total + val.count,
            0
          ),
        },
      })
    })
    .catch((err) => {
      console.log('failed getting counts', err)
      dispatch({ type: FETCH_CONTEXT_FAILURE })
    })
}
