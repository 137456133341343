import React from 'react'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Slide from '@material-ui/core/Slide'

import AvatarsNavbar from 'components/avatars/AvatarsNavbar'
import AvatarLibraryIndex from 'components/avatars/library/AvatarLibraryIndex'
import AvatarSearchIndex from 'components/avatars/search/AvatarSearchIndex'
import AvatarSizingIndex from 'components/avatars/sizing/AvatarSizingIndex'

import * as AvatarsActions from 'actions/avatars'

const AvatarsIndex = ({ onLoad, page, setPage }) => {
  React.useEffect(() => {
    onLoad()
  }, [onLoad])

  return (
    <main style={{ width: '100%', height: '100%' }}>
      <Slide in direction="up">
        <Grid container style={{ width: '100%', height: '100%' }}>
          <Grid item style={{ position: 'fixed', width: '200px' }}>
            <AvatarsNavbar onChange={(page) => setPage(page)} page={page} />
          </Grid>

          <Grid
            item
            style={{
              padding: '28px 32px',
              position: 'absolute',
              left: '200px',
              width: 'calc(100vw - 200px)',
              height: 'calc(100vh - 48px)',
              overflowY: 'scroll',
            }}
          >
            {page === 'library' ? (
              <AvatarLibraryIndex />
            ) : page === 'search' ? (
              <AvatarSearchIndex />
            ) : page === 'sizing' ? (
              <AvatarSizingIndex />
            ) : null}
          </Grid>
        </Grid>
      </Slide>
    </main>
  )
}

const mapStateToProps = (state) => ({
  page: state.avatars.page,
})

const mapDispatchToProps = (dispatch) => ({
  onLoad: () => {
    dispatch(AvatarsActions.getAvatars())
    dispatch(AvatarsActions.bookmarksList())
    dispatch(AvatarsActions.getOrders())
  },
  setPage: (page) => dispatch(AvatarsActions.setPage(page)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AvatarsIndex)
