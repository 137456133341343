import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'

import { getTotalPopulation } from 'lib/fit_coverage'
import { formatAudienceInput, formatNumber } from 'lib/format'

import { fitCoverageTotalsSelector } from 'selectors/fit_coverage'

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
  },
  contextTitle: {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontSize: '0.8rem',
    fontWeight: 600,
  },
  highlightText: {
    fontWeight: 600,
  },
  root: {
    height: 'calc(100vh)',
    backgroundColor: theme.palette.common.white,
    borderLeft: '1px solid #ddd',
    paddingTop: theme.spacing(4),
  },
  section: {
    padding: theme.spacing(0, 2, 0, 2),
    marginBottom: theme.spacing(4),
  },
  text: {
    color: '#444',
    fontSize: '0.8rem',
    lineHeight: '1.5rem',
  },
}))

const SpecSummary = ({
  project,
  population,
  context: { isUpdatingCounts, populationCount, audienceCount },
  selectedSpec,
  specCount,
  specCountIsLoading,
}) => {
  const classes = useStyles()

  return (
    <Card className={classes.card}>
      <Grid container spacing={2}>
        <Grid className={classes.section} item xs={3}>
          <Typography className={classes.contextTitle}>Population</Typography>
          <Typography className={classes.text}>
            {(population && population.name) || '(unnamed)'}
          </Typography>
          {isUpdatingCounts ? (
            <Typography
              className={classes.text}
              style={{ fontStyle: 'italic' }}
            >
              Loading...
            </Typography>
          ) : (
            <Typography className={clsx(classes.text, classes.highlightText)}>
              {populationCount
                ? `${formatNumber(populationCount)} scans`
                : null}
            </Typography>
          )}
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.contextTitle}>Audience</Typography>
          {formatAudienceInput(project.audience).map((param, idx) => (
            <Typography
              className={classes.text}
              key={`audience-parameter-summary-${idx}`}
            >
              {`${param.name}: ${param.value}`}
            </Typography>
          ))}
          {isUpdatingCounts ? (
            <Typography
              className={classes.text}
              style={{ fontStyle: 'italic' }}
            >
              Loading...
            </Typography>
          ) : (
            <Typography className={clsx(classes.text, classes.highlightText)}>
              {audienceCount ? `${formatNumber(audienceCount)} scans` : null}
            </Typography>
          )}
        </Grid>
        <Grid item xs={3}>
          <Grid item className={classes.section}>
            <Typography className={classes.contextTitle}>Size Spec</Typography>
            <Typography className={classes.text}>
              {selectedSpec.name || ''}
            </Typography>
            {specCountIsLoading ? (
              <Typography
                className={classes.text}
                style={{ fontStyle: 'italic' }}
              >
                Loading...
              </Typography>
            ) : (
              <Typography className={clsx(classes.text, classes.highlightText)}>
                {specCount ? `${specCount} scans` : null}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.contextTitle}>Sizes</Typography>
          <Typography className={classes.text}>
            {selectedSpec.sizeDefs.map(({ size }) => size).join(', ')}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  )
}

const mapStateToProps = (state) => ({
  context: state.context,
  specCount: getTotalPopulation(fitCoverageTotalsSelector(state).sizes),
  specCountIsLoading: state.fitCoverage.isFetching,
})

export default connect(mapStateToProps)(SpecSummary)
