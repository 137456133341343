import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import _ from 'lodash'

import { unflattenSizeDef } from 'lib/size_definition'
import { getMeasurementLabel } from 'lib/measurements'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  label: {
    paddingRight: theme.spacing(1),
    textAlign: 'right',
    width: 140,
  },
  header: {
    textAlign: 'left',
  },
}))

const SizeDefTable = ({ sizeSpec, selectedSize }) => {
  const classes = useStyles()

  const measurements = React.useMemo(() => {
    const def = sizeSpec.sizeDefs.find(
      (sizeDef) => String(sizeDef.size) === String(selectedSize)
    )

    const msmts = _.pickBy(def, (val, key) => {
      return _.endsWith(key, 'Min') || _.endsWith(key, 'Max')
    })

    const formattedMsmts = unflattenSizeDef(msmts)

    delete formattedMsmts.size

    return formattedMsmts
  }, [sizeSpec, selectedSize])

  return (
    <table className={classes.root}>
      <thead>
        <tr>
          <th className={classes.label}>Size Definition</th>
          <th className={classes.header}>Min</th>
          <th className={classes.header}>Mid</th>
          <th className={classes.header}>Max</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(measurements).map((measurement) => {
          const { min, max } = measurements[measurement]
          return (
            <tr key={measurement}>
              <td className={classes.label}>
                <Typography variant="caption">
                  {getMeasurementLabel(measurement)}
                </Typography>
              </td>
              <td>
                <Typography variant="caption">{min}</Typography>
              </td>
              <td>
                <Typography variant="caption">{(max + min) / 2}</Typography>
              </td>
              <td>
                <Typography variant="caption">{max}</Typography>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default SizeDefTable
