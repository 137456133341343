import * as Queries from 'lib/api/queries'
import { getUniquePairs } from 'lib/charts'
import { sortMeasurements } from 'lib/measurements'

export const FETCH_CORRELATION_DISTRIBUTION_START =
  'FETCH_CORRELATION_DISTRIBUTION_START'
export const FETCH_CORRELATION_DISTRIBUTION_SUCCESS =
  'FETCH_CORRELATION_DISTRIBUTION_SUCCESS'
export const FETCH_CORRELATION_DISTRIBUTION_FAIL =
  'FETCH_CORRELATION_DISTRIBUTION_FAIL'

export const fetchCorrelationDistribution = (
  population,
  audienceFilter,
  units,
  measurement,
  correlationMeasurement
) => (dispatch) => {
  return Queries.audienceDistribution(
    population,
    audienceFilter,
    units,
    measurement,
    correlationMeasurement
  )
    .then((response) => {
      const { data } = response

      if (data.errorMessage) {
        throw Error('correlation distribution fetch error')
      }

      dispatch({
        type: FETCH_CORRELATION_DISTRIBUTION_SUCCESS,
        measurement,
        correlationMeasurement,
        data,
      })
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: FETCH_CORRELATION_DISTRIBUTION_FAIL,
        error,
      })
    })
}

export const fetchAllCorrelationDistributions = ({
  population,
  audienceFilter,
  units,
  measurements,
}) => (dispatch) => {
  const uniquePairs = getUniquePairs(measurements)

  const promises = uniquePairs.map((pair) => {
    const [measurement, correlationMeasurement] = sortMeasurements(pair)

    dispatch({
      type: FETCH_CORRELATION_DISTRIBUTION_START,
      measurement,
      correlationMeasurement,
    })

    return dispatch(
      fetchCorrelationDistribution(
        population,
        audienceFilter,
        units,
        measurement,
        correlationMeasurement
      )
    )
  })

  return Promise.all(promises)
}
