import _ from 'lodash'
/* Array filtering/formatting */
// identifier allows us to distinguish btwn morphotype prefix and size name (i.e. A#S: morphotype === A, size === S)
const identifier = '#'

const color = [
  '#2b907f',
  '#ffac75',
  '#c1df90',
  '#79d9b7',
  '#ff6f66',
  '#8f9dd5',
  '#ff4e5c',
]
// const color = ['#FF9AA2', '#FFB7B2', '#FFDAC1', '#E2F0CB', '#B5EAD7', '#42c6b0', '#C7CEEA']

export const getMorphotypePrefix = (string) => {
  const chars = string.split(identifier)
  const prefix = chars.length > 1 ? chars[0] : 'no-prefix'

  return prefix
}

export const colorByMorphotype = (data) => {
  const colorMap = {} // store colors we've used

  let colorCount = 0 // idx of color array

  const result = []

  data.forEach(({ size, ...data }) => {
    const prefix = getMorphotypePrefix(size)
    if (!colorMap[prefix]) {
      colorMap[prefix] = color[colorCount]
      colorCount++
    }
    result.push({
      size,
      ...data,
      color: colorMap[prefix],
    })
  })
  return result.map((def) => ({ ...def, colorMap }))
}

// export const updateSizesList = (sizesList, currentNum, newNum) => {
//   if (currentNum > newNum) {
//     return sizesList.slice(0, newNum)
//   } else if (currentNum < newNum) {
//     // TODO: make this work for S/M/L/etc
//     const sizesListCopy = [...sizesList]
//     const diff = newNum - currentNum
//     const lastSize = sizesListCopy[sizesListCopy.length - 1]
//     console.log('diff', diff)
//     console.log('lastSize', lastSize)
//     for (let i = 0; i < diff; i++) {
//       sizesListCopy.push(lastSize + i + 1)
//     }
//     console.log(sizesListCopy)
//     return sizesListCopy
//   } else {
//     return sizesList
//   }
// }

/*
    'sizeDef': {
      'M': {
        'naturalWaistMax': {
          'min': 24,
          'max': 28
        }
      }
    }
*/
// export const createSizeDefTemplate = (sizesList, primaryMeasurements) => {
//   let sizeDef = {}
//   let measurements = {}
//   for (let i = 0; i < primaryMeasurements.length; i++) {
//     const measurement = primaryMeasurements[i]
//     measurements[measurement] = {
//       min: null,
//       max: null
//     }
//   }

//   for (let idx = 0; idx < sizesList.length; idx++) {
//     const size = sizesList[idx]

//     sizeDef[String(size)] = measurements
//   }
//   return sizeDef
// }

// TODO: Do a diff check to see if anything has changed from previous sizeList to new sizeList
export const updateSizeDef = (
  sizesList,
  sizeDef,
  bodyParts,
  minValues,
  ranges
) => {
  const sizeDefCopy = Object.assign({}, sizeDef)

  for (let idx = 0; idx < sizesList.length; idx++) {
    const size = sizesList[idx]
    for (let j = 0; j < bodyParts.length; j++) {
      const bodyPart = bodyParts[j]
      const definition = {
        min: null,
        max: null,
      }

      if (minValues[bodyPart] && ranges[bodyPart]) {
        const min = parseFloat(minValues[bodyPart])
        const range = parseFloat(ranges[bodyPart])
        const base = parseFloat(min) + idx * range

        definition.min = base
        definition.max = base + range
      }

      sizeDefCopy[String(size)] = {
        ...sizeDefCopy[String(size)],
        [bodyPart]: definition,
      }
    }
  }
  return sizeDefCopy
}

// TODO: FORMAT VALIDATION
export const unflattenSizeSpec = (sizespec) => {
  const spec = _.omit(sizespec, [
    'additionalFilters',
    'avatars',
    'referencePkgId',
    'size',
  ])
  const unflattenedSpec = {}

  Object.keys(spec).forEach((key) => {
    const characters = key.length
    const measurement = key.substring(0, characters - 3)
    const minMax = key.substring(characters - 3, characters).toLowerCase()

    unflattenedSpec[measurement] = {
      ...unflattenedSpec[measurement],
      [minMax]: spec[key],
    }
  })

  return unflattenedSpec
}

const removeDuplicateElements = (array) => {
  const result = []
  const cache = {}
  array.forEach((el) => {
    if (cache[el]) {
      return
    }
    cache[el] = true
    result.push(el)
  })
  return result
}

export const unflattenSizeDef = (sizeDef) => {
  const result = {
    size: sizeDef.size,
  }

  const keys = Object.keys(sizeDef).filter((key) => key !== 'size')
  let measurements = keys.map((key) => key.slice(0, -3))
  measurements = removeDuplicateElements(measurements)
  measurements.forEach((measurement) => {
    const minKey = `${measurement}Min`
    const maxKey = `${measurement}Max`
    result[measurement] = {}
    result[measurement].min = sizeDef[minKey]
    result[measurement].max = sizeDef[maxKey]
  })

  return result
}

const generateSizeTableData = (sizeDefs, fitCoverage) => {
  // fit coverage data structure for easier manipulation
  const fitCoverageMap = {}
  fitCoverage.forEach((datum) => {
    const { size, ...rest } = datum
    fitCoverageMap[size] = {
      ...rest,
    }
  })

  const result = []
  sizeDefs.forEach((sizeDef) => {
    const { size } = sizeDef
    const sizeDefAndCoverage = {
      ...unflattenSizeDef(sizeDef),
      ...fitCoverageMap[size],
    }
    result.push(sizeDefAndCoverage)
  })

  return result
}

export const generateSizeTableDataAll = (
  baselineSizeDefs,
  baselineCoverageData,
  potentialSizeDefs,
  potentialCoverageData
) => {
  const baselineSizeTableData = generateSizeTableData(
    baselineSizeDefs,
    baselineCoverageData
  )
  const potentialSizeTableData = generateSizeTableData(
    potentialSizeDefs,
    potentialCoverageData
  )

  const baselineSizeList = baselineSizeDefs.map((sizeDef) => {
    return sizeDef.size
  })
  const potentialSizeList = potentialSizeDefs.map((sizeDef) => {
    return sizeDef.size
  })
  const allSizeList = removeDuplicateElements([
    ...baselineSizeList,
    ...potentialSizeList,
  ])

  const result = []
  allSizeList.forEach((size) => {
    const baselineDatum = baselineSizeTableData.find(
      (datum) => datum.size === size
    )
    const potentialDatum = potentialSizeTableData.find(
      (datum) => datum.size === size
    )

    const combinedDatum = {
      baseline: { ...baselineDatum },
      potential: { ...potentialDatum },
    }

    result.push(combinedDatum)
  })

  return result
}

/*
const unflatten_sample_input = [
  {
    size: 'S',
    chestBustGirthMin: 30,
    chestBustGirthMax: 32,
    naturalWaistGirthMin: 24,
    naturalWaistGirthMax: 26,
    hipSeatGirthMin: 32,
    hipSeatGirthMax: 34
  }
]

const unflatten_sample_output = [
  {
    size: 'S',
    chestBustGirth: {
      min: 30,
      max: 32
    },
    naturalWaistGirth: {
      min: 24,
      max: 26
    },
    hipSeatGirth: {
      min: 32,
      max: 34
    }
  }
]
*/
