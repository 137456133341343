import React from 'react'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'

import ScatterPlot from 'components/size_explorer/charts/ScatterPlot'
import LoadingSpinner from 'components/size_explorer/lib/LoadingSpinner'

export const generateAnnotations = (
  mode,
  sizeDefs,
  measurement,
  correlationMeasurement
) => {
  return sizeDefs.map((sizeDef) => {
    const { size } = sizeDef
    const xMin = Number(sizeDef[`${measurement}Min`])
    const xMax = Number(sizeDef[`${measurement}Max`])
    const yMin = Number(sizeDef[`${correlationMeasurement}Min`])
    const yMax = Number(sizeDef[`${correlationMeasurement}Max`])

    return {
      type: 'myCustomType',
      x: xMin,
      y: yMax,
      xDiff: xMax - xMin,
      yDiff: yMax - yMin,
      label: size,
      mode, // 'baseline' or 'potential' (identifier needed for color)
    }
  })
}

export default ({ correlationPlot, baselineSpec, potentialSpec }) => {
  const gridSize = baselineSpec.primaryMeasurements.length < 3 ? 12 : 4

  return (
    <Grid container spacing={2}>
      {Object.values(correlationPlot).map((obj, idx) => {
        const { measurement, correlationMeasurement, data, isFetching } = obj

        const annotations = [
          ...generateAnnotations(
            'baseline',
            baselineSpec.sizeDefs,
            correlationMeasurement,
            measurement
          ),
          ...generateAnnotations(
            'potential',
            potentialSpec.sizeDefs,
            correlationMeasurement,
            measurement
          ),
        ]

        let body

        if (isFetching) {
          body = (
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{ height: 450 }}
            >
              <LoadingSpinner />
            </Grid>
          )
        } else {
          body = (
            <ScatterPlot
              data={data}
              xAccessor={correlationMeasurement}
              yAccessor={measurement}
              annotations={annotations}
              increaseRadius={baselineSpec.primaryMeasurements.length === 2}
            />
          )
        }

        return (
          <Grid
            item
            key={`compare-correlation-plot-${idx}`}
            xs={12}
            sm={gridSize}
          >
            <Card style={{ padding: '20px 28px 0px 28px', height: 450 }}>
              {body}
            </Card>
          </Grid>
        )
      })}
    </Grid>
  )
}
