import { formatNumber } from 'lib/format'

export const getTotalCoverage = (fitCoverage) => {
  if (!fitCoverage || fitCoverage.length === 0) {
    return 0
  }

  const callback = (acc, el) => {
    return acc + el.coverage
  }
  const total = fitCoverage.reduce(callback, 0) * 100
  return total.toFixed(1)
}

export const getSelectedSizeCoverage = (fitCoverage, selectedSize) => {
  if (!fitCoverage || fitCoverage.length === 0 || selectedSize === null) {
    return 0
  }

  const selected = fitCoverage.find(
    (obj) => String(obj.size) === String(selectedSize)
  )
  if (!selected) {
    return 0
  } // array.find returns 'undefined' if no match is found (this should never really happen, but just in case...)
  return (selected.coverage * 100).toFixed(1)
}

export const getTotalPopulation = (fitCoverage) => {
  if (!fitCoverage || fitCoverage.length === 0) {
    return 0
  }

  const callback = (acc, val) => {
    return acc + val.count
  }
  const total = fitCoverage.reduce(callback, 0)
  return formatNumber(total)
}

export const getSelectedSizePopulation = (fitCoverage, selectedSize) => {
  if (!fitCoverage || fitCoverage.length === 0 || selectedSize === null) {
    return 0
  }

  const selected = fitCoverage.find(
    (obj) => String(obj.size) === String(selectedSize)
  )
  if (!selected) {
    return 0
  } // array.find returns 'undefined' if no match is found (this should never really happen, but just inc case...)
  return formatNumber(selected.count)
}
