import * as Queries from 'lib/api/queries'

export const FETCH_AUDIENCE_START = 'FETCH_AUDIENCE_START'
export const FETCH_AUDIENCE_SUCCESS = 'FETCH_AUDIENCE_SUCCESS'
export const FETCH_AUDIENCE_FAIL = 'FETCH_AUDIENCE_FAIL'

export const fetchAudienceDistribution = (
  population,
  audienceFilter,
  units,
  measurement
) => (dispatch) => {
  dispatch({
    type: FETCH_AUDIENCE_START,
    measurement,
  })

  // each measurement needs to fetch data for both base population & audience
  const populationPromise = Queries.audienceDistribution(
    population,
    {},
    units,
    measurement
  )
  const audiencePromise = Queries.audienceDistribution(
    population,
    audienceFilter,
    units,
    measurement
  )

  return Promise.all([audiencePromise, populationPromise])
    .then(([audienceResponse, populationResponse]) => {
      if (audienceResponse.errorMessage || populationResponse.errorMessage) {
        dispatch({
          type: FETCH_AUDIENCE_FAIL,
          measurement,
        })
      } else {
        dispatch({
          type: FETCH_AUDIENCE_SUCCESS,
          audience: audienceResponse,
          population: populationResponse,
          measurement,
        })
      }
    })
    .catch((err) => {
      console.log('error fetching audience distributions', err)
      dispatch({
        type: FETCH_AUDIENCE_FAIL,
        measurement,
      })
    })
}
