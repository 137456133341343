import React from 'react'
import Grid from '@material-ui/core/Grid'
import FormLabel from '@material-ui/core/FormLabel'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

export default (props) => {
  const { audienceFilter, units } = props

  return (
    <Grid container>
      <Typography
        variant="subtitle1"
        gutterBottom
        style={{ fontWeight: 600, marginBottom: '1rem' }}
      >
        Demographic
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormLabel>
            Age <small>(yrs)</small>
          </FormLabel>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                id="input-ageMin"
                label="Min"
                value={audienceFilter.ageMin || ''}
                onChange={(e) => props.setAgeMin(e.target.value)}
                type="number"
                variant="outlined"
                margin="dense"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="input-ageMax"
                label="Max"
                value={audienceFilter.ageMax || ''}
                onChange={(e) => props.setAgeMax(e.target.value)}
                type="number"
                variant="outlined"
                margin="dense"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormLabel>
            Height <small>{units === 'metric' ? '(cm)' : '(inch)'}</small>
          </FormLabel>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                id="input-heightMin"
                label="Min" /* placeholder */
                value={audienceFilter.heightMin || ''}
                onChange={(e) => props.setHeightMin(e.target.value)}
                type="number" /* only allow numbers for valid input */
                variant="outlined" /* border box */
                margin="dense"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="input-heightMax"
                label="Max"
                value={audienceFilter.heightMax || ''}
                onChange={(e) => props.setHeightMax(e.target.value)}
                type="number"
                variant="outlined"
                margin="dense"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormLabel>
            Weight <small>{units === 'metric' ? '(kg)' : '(lbs)'}</small>
          </FormLabel>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                id="input-weightMin"
                label="Min"
                value={audienceFilter.weightMin || ''}
                onChange={(e) => props.setWeightMin(e.target.value)}
                type="number"
                variant="outlined"
                margin="dense"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="input-weightMax"
                label="Max"
                value={audienceFilter.weightMax || ''}
                onChange={(e) => props.setWeightMax(e.target.value)}
                type="number"
                variant="outlined"
                margin="dense"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormLabel style={{ whiteSpace: 'nowrap' }}>
            Body Composition <small>(%)</small>
          </FormLabel>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                id="input-bodyfatMin"
                label="Min"
                value={audienceFilter.bodyCompositionMin || ''}
                onChange={(e) => props.setBodyfatMin(e.target.value)}
                type="number"
                variant="outlined"
                margin="dense"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="input-bodyfatMax"
                label="Max"
                value={audienceFilter.bodyCompositionMax || ''}
                onChange={(e) => props.setBodyfatMax(e.target.value)}
                type="number"
                variant="outlined"
                margin="dense"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
