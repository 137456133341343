import auth0 from 'auth0-js'

// this will clear any local browser session state and send user to auth0 for logout
export const logout = () => {
  window.localStorage.clear()
  getWebAuth(window.location.origin).logout({
    returnTo: window.location.origin,
  })
}

export const getWebAuth = (redirectUri) => {
  return new auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENTID,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    responseType: 'token id_token',
    scope: 'openid profile',
    prompt: 'none',
    redirectUri,
  })
}

export const getSessionToken = () => {
  return window.localStorage.getItem('token')
}

export const hasSessionToken = () => {
  const isExpired =
    Date.now() > parseInt(window.localStorage.getItem('expires_at'))
  return !isExpired && getSessionToken()
}

export const setSession = (authResult) => {
  // NOTE: expiresIn value is in seconds and timestamp is in ms
  const expiration = Date.now() + authResult.expiresIn * 1000

  // always capture the token into local storage on a login
  window.localStorage.setItem('token', authResult.accessToken)
  window.localStorage.setItem('id_token', authResult.idToken)
  window.localStorage.setItem('expires_at', expiration)
}
