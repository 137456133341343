import React, { useState } from 'react'
import {
  generateToleranceAnnotations,
  generateToleranceAnnotationRules,
} from 'lib/charts'
import CurvyLineChart from 'components/size_explorer/charts/CurvyLineChart'

const Legend = ({
  setLines,
  histogramLines,
  selectedSize,
  setSelectedSize,
}) => {
  const [sizeList, setSizeList] = useState(
    histogramLines.map((obj) => obj.size)
  )

  React.useEffect(() => {
    if (selectedSize !== null) {
      setSizeList([selectedSize]) // update the checkbox in legend

      const timer = setTimeout(() => {
        setLines(histogramLines.filter((obj) => obj.size === selectedSize))
      }, 10)
      return () => clearTimeout(timer)
    }
  }, [histogramLines, selectedSize, setLines])

  const handleClick = (size) => {
    setSelectedSize(null) // remove highlighted border in secondary measurement table row

    let updated = sizeList
    if (sizeList.includes(size)) {
      updated = sizeList.filter((el) => el !== size)
      setSizeList(updated)
    } else {
      updated.push(size)
      setSizeList(updated)
    }

    const updatedLines = histogramLines.filter((obj) =>
      updated.includes(obj.size)
    )
    setLines(updatedLines)
  }

  const handleClickSelectAll = () => {
    const allSizes = histogramLines.map((obj) => obj.size)
    if (histogramLines.length === sizeList.length) {
      setSizeList([])
      setLines([])
    } else {
      setSizeList(allSizes)
      setLines(histogramLines)
    }
  }

  const legendItems = histogramLines.map((obj) => {
    const { size, color } = obj

    return (
      <div
        key={`legend-${size}`}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-right',
          marginBottom: '2px',
        }}
      >
        <input
          type="checkbox"
          checked={sizeList.includes(size)}
          onChange={() => handleClick(size)}
        />
        <span
          style={{ marginRight: 8, width: 10, height: 10, background: color }}
        />
        <span style={{ fontSize: '0.75rem' }}>{size}</span>
      </div>
    )
  })

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
      }}
    >
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          marginBottom: '2px',
        }}
      >
        <input
          type="checkbox"
          checked={sizeList.length === histogramLines.length}
          onChange={handleClickSelectAll}
        />
        <span style={{ fontSize: '0.75rem' }}>Select All</span>
      </span>
      <span>{legendItems}</span>
    </div>
  )
}

const Histogram = ({
  histogramLines,
  distributionData,
  measurementName,
  selectedSize,
  setSelectedSize,
  toleranceVal,
  minmaxPeg,
}) => {
  const [lines, setLines] = useState(histogramLines)

  let annotations
  if (selectedSize && toleranceVal) {
    annotations = generateToleranceAnnotations({
      measurementName,
      meanVal: distributionData[selectedSize].mean,
      toleranceVal,
    })
  }

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <div
        style={{
          position: 'absolute',
          width: '20%',
          top: 0,
          right: 0,
          zIndex: 2,
        }}
      >
        <Legend
          setLines={setLines}
          histogramLines={histogramLines}
          selectedSize={selectedSize}
          setSelectedSize={setSelectedSize}
        />
      </div>
      <CurvyLineChart
        lines={lines}
        xAccessor={measurementName}
        yAccessor="count"
        annotations={annotations}
        svgAnnotationRules={generateToleranceAnnotationRules}
        minmaxPeg={minmaxPeg}
      />
    </div>
  )
}

export default Histogram
