import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
})(Tabs)

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />)

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabsContainer: {
    position: 'relative',
  },
  uploadContainer: {
    position: 'absolute',
    top: 12,
    right: theme.spacing(1.25),
    cursor: 'pointer',
  },
}))

export default ({
  measurements,
  idx,
  onMeasurementChange,
  tableComponent,
  csvUploadComponent,
  additionalOptions,
}) => {
  const classes = useStyles()

  const handleChange = (event, newValue) => {
    onMeasurementChange(newValue)
  }

  return (
    <div className={classes.root}>
      <div className={classes.tabsContainer}>
        <AntTabs value={idx} onChange={handleChange} aria-label="ant example">
          {measurements.map((msmt) => (
            <AntTab key={msmt} label={msmt} />
          ))}
        </AntTabs>
        <div className={classes.uploadContainer}>{csvUploadComponent}</div>
      </div>
      {additionalOptions}
      {tableComponent}
    </div>
  )
}
