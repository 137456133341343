import React from 'react'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'

import { AUDIENCE_DISTRIBUTION_OPTIONS } from 'lib/options'

const useStyles = makeStyles((theme) => ({
  option: {
    backgroundColor: 'transparent',
    cursor: 'pointer',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    width: '100%',
    '&:hover': {
      backgroundColor: '#DEEBFF',
    },
  },
  disabled: {
    color: 'hsl(0,0%,80%)',
  },
}))

export default ({ anchorEl, onClose, onSelect, measurementList }) => {
  const classes = useStyles()

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        style: {
          maxHeight: '300px',
          overflowY: 'auto',
        },
      }}
    >
      {AUDIENCE_DISTRIBUTION_OPTIONS.map((option) => {
        const isDisabled = measurementList.includes(option.value)
        return (
          <div
            key={option.label}
            value={option.value}
            onClick={
              isDisabled
                ? null
                : (e) => onSelect(e.target.getAttribute('value'))
            }
            className={
              isDisabled
                ? clsx(classes.option, classes.disabled)
                : classes.option
            }
          >
            {option.label}
          </div>
        )
      })}
    </Popover>
  )
}
