import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import InfoIcon from '@material-ui/icons/Info'
import Popover from '@material-ui/core/Popover'

import Histogram from 'components/size_explorer/fit_coverage/spec_distributions/Histogram'
import LoadingSpinner from 'components/size_explorer/lib/LoadingSpinner'

const tableHeaderStyle = {
  fontWeight: 600,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px 12px',
}

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(2),
    width: 360,
  },
  tableRow: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.07)', // material-ui default
    },
  },
}))

const METADATA_KEYS = [
  { key: 'mean', display: 'Mean' },
  { key: 'std', display: 'Std Dev' },
  // { key: 'min', display: 'Minimum' },
  // { key: '5%', display: '5%' },
  // { key: '25%', display: '25%' },
  // { key: '50%', display: '50%' },
  // { key: '75%', display: '75%' },
  // { key: '95%', display: '95%' },
  // { key: 'max', display: 'Maximum' }
]

const SizeSpecDistributionTable = ({
  data = [],
  histogramLines,
  minmaxPeg,
  measurementName,
  isFetching,
  sizesList,
  selectedSize,
  setSelectedSize,
  toleranceVal,
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  let body
  if (isFetching) {
    body = (
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ padding: '18px', minHeight: '120px' }}
      >
        <LoadingSpinner />
      </Grid>
    )
  } else if (
    !Object.keys(data).length ||
    !histogramLines ||
    !histogramLines.length
  ) {
    body = (
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ padding: '18px', minHeight: '120px' }}
      >
        No results available
      </Grid>
    )
  } else {
    const distributionData = data
    body = (
      <>
        {METADATA_KEYS.map((el, idx) => {
          const { key, display } = el
          const isLastRow = idx === METADATA_KEYS.length - 1

          return (
            <Grid
              container
              key={`secondary-measurement-table-row-${key}`}
              style={{ borderBottom: '1px #ddd solid' }}
            >
              <Grid
                style={{ width: '15%', padding: '8px 12px' }}
                align="center"
              >
                {display}
              </Grid>
              {sizesList.map((size) => (
                <Grid
                  key={`row-${size}`}
                  align="center"
                  style={
                    size === selectedSize && isLastRow
                      ? {
                          padding: '12px 12px',
                          width: `${85 / sizesList.length}%`,
                          borderBottom: '2px solid #691b99',
                          borderLeft: '2px solid #691b99',
                          borderRight: '2px solid #691b99',
                        }
                      : size === selectedSize
                      ? {
                          padding: '12px 12px',
                          width: `${85 / sizesList.length}%`,
                          borderLeft: '2px solid #691b99',
                          borderRight: '2px solid #691b99',
                        }
                      : {
                          cursor: 'pointer',
                          padding: '12px 12px',
                          width: `${85 / sizesList.length}%`,
                        }
                  }
                  onClick={() => setSelectedSize(size)}
                >
                  {distributionData[size] && distributionData[size][key]
                    ? distributionData[size][key].toFixed(1)
                    : ''}
                </Grid>
              ))}
            </Grid>
          )
        })}
        <div style={{ padding: '12px' }}>
          <Histogram
            histogramLines={histogramLines}
            minmaxPeg={minmaxPeg}
            measurementName={measurementName}
            selectedSize={selectedSize}
            setSelectedSize={setSelectedSize}
            toleranceVal={toleranceVal}
            distributionData={distributionData} // this is needed to get the 'mean' value of selectedSize
          />
        </div>
      </>
    )
  }

  return (
    <Card>
      <Grid container style={{ borderBottom: '2px #00000054 solid' }}>
        <Grid style={{ ...tableHeaderStyle, width: '15%' }} align="center">
          Size
        </Grid>
        {sizesList.map((size) => (
          <Grid
            key={`secondary-measurement-table-header-${size}`}
            style={
              size === selectedSize
                ? {
                    ...tableHeaderStyle,
                    width: `${85 / sizesList.length}%`,
                    borderTop: '2px solid #691b99',
                    borderLeft: '2px solid #691b99',
                    borderRight: '2px solid #691b99',
                  }
                : {
                    ...tableHeaderStyle,
                    cursor: 'pointer',
                    width: `${85 / sizesList.length}%`,
                  }
            }
            align="center"
            item
          >
            {size}
          </Grid>
        ))}
        <InfoIcon
          aria-owns={open ? 'mouse-over-popover' : 'undefined'}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          style={{
            width: '1.15rem',
            height: '1.15rem',
            position: 'absolute',
            left: '3rem',
            marginTop: 8,
          }}
        />
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography style={{ fontWeight: 600 }}>
            What am I looking at?
          </Typography>
          <p>
            The distribution for the selected secondary measurement is shown per
            size.
          </p>
          Three use cases to identify:
          <ol style={{ listStyleType: 'decimal' }}>
            <li>
              Average that trends with size. I.e. the average value increases or
              decreases with each size.
            </li>
            <li>
              Average that is independent of size changes. I.e. the average
              value is the same across sizes or has no continuous trend.
            </li>
            <li>
              Flat distribution where the data is not concentrated around the
              average. This indicates the secondary measurement is highly
              variable across the total range.
            </li>
          </ol>
          These use cases are some exmamples of how secondary measurement
          analysis can help identify sizing decisions based on secondary
          measurements. I.e. should there be multiple lengths of pants for all
          sizes, some sizes, or no sizes.
        </Popover>
      </Grid>
      <div>{body}</div>
    </Card>
  )
}

export default SizeSpecDistributionTable
