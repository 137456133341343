import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'

import ScanImage from 'components/lib/ScanImage'

const styles = makeStyles((theme) => {
  return {
    default: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#ddd',
      padding: theme.spacing(1),
      fontWeight: 600,
    },
    primary: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
      padding: theme.spacing(1),
      fontWeight: 600,
    },
    secondary: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.getContrastText(theme.palette.secondary.main),
      padding: theme.spacing(1),
      fontWeight: 600,
    },
  }
})

const DEFAULT_SCAN_PROPS = {
  angle: '000',
}

function ScanTile({
  color = 'default',
  label,
  onClick,
  onMouseOver,
  onMouseOut,
  raised,
  scanImageProps,
}) {
  const classes = styles()

  const combinedScanProps = { ...DEFAULT_SCAN_PROPS, ...scanImageProps }

  const handleClick = () => {
    if (onClick) {
      onClick()
    }
  }

  return (
    <Card
      onClick={handleClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      raised={raised}
    >
      <ScanImage {...combinedScanProps} />
      <div className={classes[color] || classes.default}>
        {typeof label !== 'string' ? (
          label
        ) : (
          <Typography variant="body2" align="center">
            {label || 'Unknown'}
          </Typography>
        )}
      </div>
    </Card>
  )
}

export default ScanTile
