import _ from 'lodash'
import React from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CartIcon from '@material-ui/icons/ShoppingCart'
import DeleteIcon from '@material-ui/icons/Delete'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import * as AvatarsActions from 'actions/avatars'
import * as AvatarsApi from 'lib/api/avatars'
import * as BookmarksAPI from 'lib/api/bookmark'
import ConfirmationDialog from 'components/lib/ConfirmationDialog'
import PurchaseAvatarsDialog from 'components/avatars/PurchaseAvatarsDialog'

const styles = makeStyles((theme) => ({
  actionGroup: {
    paddingLeft: theme.spacing(2),
    '& hr': {
      margin: theme.spacing(0, 0.5),
    },
  },
}))

function AvatarCollectionPurchaseActions({
  onPurchase,
  onRemove,
  selectedAvatars,
}) {
  const classes = styles()
  const dispatch = useDispatch()

  const [purchaseStatus, setPurchaseStatus] = React.useState(null)
  const [removeStatus, setRemoveStatus] = React.useState(null)

  const hasSelection = React.useMemo(() => {
    return !_.isEmpty(selectedAvatars)
  }, [selectedAvatars])

  const handlePurchaseSelections = () => {
    const toPurchase = Object.values(selectedAvatars)

    setPurchaseStatus('working')
    AvatarsApi.createOrder(toPurchase)
      .then((order) => {
        dispatch(AvatarsActions.orderCreated(order))
        setPurchaseStatus(null)
        onPurchase(toPurchase)
      })
      .catch((error) => {
        console.log(error)
        setPurchaseStatus('error')
      })
  }

  const handleRemoveSelections = () => {
    const toRemove = Object.values(selectedAvatars)

    // delete all selected bookmarks!
    setRemoveStatus('working')
    Promise.all(
      toRemove.map((bmrk) => BookmarksAPI.removeBookmark(bmrk.packageId))
    )
      .then((result) => {
        dispatch(AvatarsActions.bookmarksRemoved(toRemove))
        setRemoveStatus(null)
        onRemove(toRemove)
      })
      .catch((error) => {
        // TODO: we need to do something more appropriate
        console.log(error)
        setRemoveStatus('error')
      })
  }

  return (
    <>
      <Grid container direction="row" alignItems="center" justify="flex-start">
        <Grid item>
          {!hasSelection ? (
            <Typography variant="subtitle1">No avatars selected</Typography>
          ) : (
            <Typography variant="subtitle1">
              Selected {Object.keys(selectedAvatars).length} avatars
            </Typography>
          )}
        </Grid>
        <Grid item style={{ paddingLeft: 8 }}>
          <Grid container spacing={2} className={classes.actionGroup}>
            <Button
              variant="outlined"
              color="primary"
              disabled={!hasSelection}
              startIcon={<CartIcon />}
              onClick={() => setPurchaseStatus('confirm')}
            >
              Purchase
            </Button>

            {onRemove ? (
              <Button
                variant="outlined"
                color="primary"
                disabled={!hasSelection}
                startIcon={<DeleteIcon />}
                style={{ marginLeft: 8 }}
                onClick={() => setRemoveStatus('confirm')}
              >
                Remove
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </Grid>

      <PurchaseAvatarsDialog
        count={Object.values(selectedAvatars).length}
        errorText={
          purchaseStatus === 'error' ? 'Purchase request failed' : null
        }
        isWorking={purchaseStatus === 'working'}
        onClose={() => setPurchaseStatus(null)}
        onConfirm={handlePurchaseSelections}
        open={purchaseStatus !== null}
      />

      <ConfirmationDialog
        confirmText={`Confirming will remove the selected ${
          Object.values(selectedAvatars).length
        } ${Object.values(selectedAvatars).length > 1 ? 'avatars' : 'avatar'}`}
        errorText={null}
        isWorking={removeStatus === 'working'}
        onClose={() => setRemoveStatus(null)}
        onConfirm={handleRemoveSelections}
        open={removeStatus !== null}
        title={`Remove ${
          Object.values(selectedAvatars).length > 1 ? 'avatars' : 'avatar'
        }?`}
      />
    </>
  )
}

export default AvatarCollectionPurchaseActions
