import React from 'react'
import { connect } from 'react-redux'
import BookmarkFullIcon from '@material-ui/icons/Bookmark'
import BookmarkEmptyIcon from '@material-ui/icons/BookmarkBorder'
import IconButton from '@material-ui/core/IconButton'

import * as AvatarsActions from 'actions/avatars'

function BookmarkIconButton({
  addBookmark,
  bookmarks,
  gender,
  packageId,
  removeBookmark,
}) {
  const isBookmarked =
    packageId && bookmarks ? Boolean(bookmarks[packageId]) : false

  const handleBookmark = (event) => {
    event.stopPropagation()

    if (isBookmarked) {
      removeBookmark(packageId)
    } else {
      addBookmark({ gender, packageId })
    }
  }

  return (
    <IconButton onClick={handleBookmark}>
      {isBookmarked ? <BookmarkFullIcon /> : <BookmarkEmptyIcon />}
    </IconButton>
  )
}

const mapStateToProps = (state) => ({
  bookmarks: state.avatars.bookmarks,
})

const mapDispatchToProps = (dispatch) => ({
  addBookmark: (bookmark) => dispatch(AvatarsActions.bookmarksAdd(bookmark)),
  removeBookmark: (packageId) =>
    dispatch(AvatarsActions.bookmarksRemove(packageId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BookmarkIconButton)
