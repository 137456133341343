import _ from 'lodash'
import React from 'react'
import { useDispatch } from 'react-redux'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'

import * as ProjectActions from 'actions/projects'
import * as ProjectAPI from 'lib/api/project'
import ButtonWithSpinner from 'components/lib/ButtonWithSpinner'
import Demographics from 'components/size_explorer/audience/filters/Demographics'
import PrimaryMeasurements from 'components/size_explorer/audience/filters/PrimaryMeasurements'

export const VALID_AUDIENCE_PARAMS = [
  'ageMin',
  'ageMax',
  'bodyCompositionMin',
  'bodyCompositionMax',
  'chestBustGirthMin',
  'chestBustGirthMax',
  'heightMin',
  'heightMax',
  'hipSeatGirthMin',
  'hipSeatGirthMax',
  'midNeckGirthMin',
  'midNeckGirthMax',
  'naturalWaistGirthMin',
  'naturalWaistGirthMax',
  'weightMin',
  'weightMax',
]

// 1. remove any keys which are empty
// 2. make sure all values are numbers
export const cleanseAudience = (audienceFilter) => {
  const filtered = _.pickBy(
    audienceFilter,
    (val, key) =>
      _.includes(VALID_AUDIENCE_PARAMS, key) && val !== null && val !== ''
  )
  return _.mapValues(filtered, (val) =>
    _.isString(val) ? parseFloat(val) : val
  )
}

export default ({ isLoading, project }) => {
  const dispatch = useDispatch()

  const [audienceFilters, setAudienceFilters] = React.useState({})
  const [saveAudience, setSaveAudience] = React.useState(null)

  React.useEffect(() => {
    setAudienceFilters({ ...project.audience })
  }, [project])

  const handleSave = () => {
    setSaveAudience('working')
    const newAudience = cleanseAudience(audienceFilters)

    ProjectAPI.updateAudience(project.id, newAudience)
      .then(() => {
        setSaveAudience(null)
        dispatch(ProjectActions.updatedAudience(project.id, newAudience))
      })
      .catch((err) => {
        // TODO: ui indicator needed here
        console.log('update audience fail: ', err)
        setSaveAudience('error')
      })
  }

  const handleSetFilter = (attr, val) => {
    setAudienceFilters({ ...audienceFilters, [attr]: val })
  }

  const formActions = {
    setAgeMin: (val) => handleSetFilter('ageMin', val),
    setAgeMax: (val) => handleSetFilter('ageMax', val),
    setBodyfatMin: (val) => handleSetFilter('bodyCompositionMin', val),
    setBodyfatMax: (val) => handleSetFilter('bodyCompositionMax', val),
    setHeightMin: (val) => handleSetFilter('heightMin', val),
    setHeightMax: (val) => handleSetFilter('heightMax', val),
    setWeightMin: (val) => handleSetFilter('weightMin', val),
    setWeightMax: (val) => handleSetFilter('weightMax', val),
    setBustMin: (val) => handleSetFilter('chestBustGirthMin', val),
    setBustMax: (val) => handleSetFilter('chestBustGirthMax', val),
    setHipsMin: (val) => handleSetFilter('hipSeatGirthMin', val),
    setHipsMax: (val) => handleSetFilter('hipSeatGirthMax', val),
    setMidNeckGirthMin: (val) => handleSetFilter('midNeckGirthMin', val),
    setMidNeckGirthMax: (val) => handleSetFilter('midNeckGirthMax', val),
    setWaistMin: (val) => handleSetFilter('naturalWaistGirthMin', val),
    setWaistMax: (val) => handleSetFilter('naturalWaistGirthMax', val),
  }

  return (
    <Card>
      <Grid container spacing={5} style={{ padding: '1rem' }}>
        <Grid item md={6}>
          <Demographics
            audienceFilter={audienceFilters}
            units={project.units}
            {...formActions}
          />
        </Grid>
        <Grid item md={6}>
          <PrimaryMeasurements
            audienceFilter={audienceFilters}
            units={project.units}
            {...formActions}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center">
            <ButtonWithSpinner
              variant="contained"
              color="primary"
              isSpinning={saveAudience === 'working'}
              disabled={saveAudience === 'working' || isLoading}
              onClick={handleSave}
            >
              Save & Refresh
            </ButtonWithSpinner>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}
