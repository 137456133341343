import React from 'react'
import { connect } from 'react-redux'

import ReferenceAvatars from 'components/size_explorer/visualize/avatars/reference/ReferenceAvatarsIndex'
import ScanProfileModal from 'components/size_explorer/visualize/avatars/modal/ReferenceAvatarModal'

import * as VisualizeActions from 'actions/visualize'
import * as QueriesApi from 'lib/api/queries'

export const AvatarsIndex = ({
  project,
  populations,
  fitCoverageData,
  selectedSizeSpecId,
  angle,
  onReferenceAvatarSave,
}) => {
  const selectedSpec = project.sizeSpecs[selectedSizeSpecId]

  const [searchResults, setSearchResults] = React.useState({})
  const [selectedSize, setSelectedSize] = React.useState(null)
  const [fetchingResults, setFetchingResults] = React.useState(false)
  const [open, setOpen] = React.useState(false)

  const handleSizeSelect = (size) => {
    setSelectedSize(size)

    // Don't fetch scan bucket data if we've already fetched before
    if (!searchResults[size]) {
      setFetchingResults(true)

      const sizeDef = selectedSpec.sizeDefs.find(
        (sizeDef) => String(sizeDef.size) === String(size)
      )

      const globalQuery = {
        audience: {
          filters: project.audience,
        },
        sizeDef: {
          ...sizeDef,
          additionalFilters: [],
        },
        gender: populations[project.population].filters.gender || 'male',
        units: project.units,
      }

      const geoQuery = {
        ...globalQuery,
        population: project.population,
      }

      QueriesApi.search(geoQuery)
        .then((geoResult) => {
          QueriesApi.search(globalQuery)
            .then((globalResult) => {
              setSearchResults({
                ...searchResults,
                [size]: {
                  geo: geoResult,
                  global: globalResult,
                },
              })
              setFetchingResults(false)
            })
            .catch((error) => {
              setFetchingResults(false)
              console.log('Global query error: ' + error)
            })
        })
        .catch((error) => {
          setFetchingResults(false)
          console.log('Geo query error: ' + error)
        })
    }
  }

  const handleAvatarSave = (packageId) => {
    const params = {
      [selectedSize]: packageId,
    }
    onReferenceAvatarSave(project.id, selectedSpec.id, params)
  }

  const handleClickOpen = (size) => {
    handleSizeSelect(size)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <ReferenceAvatars
        sizeSpec={selectedSpec}
        fitCoverageData={fitCoverageData}
        angle={angle}
        onClickOpen={handleClickOpen}
      />
      <ScanProfileModal
        sizeSpec={selectedSpec}
        selectedSize={selectedSize}
        searchResults={searchResults}
        fetchingResults={fetchingResults}
        open={open}
        onClose={handleClose}
        onStarSave={handleAvatarSave}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  project: state.project.entities[state.project.selected],
  populations: state.populations,
  fitCoverageData: state.fitCoverage.data,
  selectedSizeSpecId: state.project.selectedSizeSpecId,
})

const mapDispatchToProps = (dispatch) => ({
  onReferenceAvatarSave: (projectId, specId, packageId) =>
    dispatch(
      VisualizeActions.saveReferenceAvatars(projectId, specId, packageId)
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(AvatarsIndex)
