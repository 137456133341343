import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import DuplicateIcon from '@material-ui/icons/FileCopy'

export default ({ onClick }) => {
  return (
    <Tooltip title="Duplicate">
      <IconButton aria-label="duplicate" onClick={onClick}>
        <DuplicateIcon />
      </IconButton>
    </Tooltip>
  )
}
