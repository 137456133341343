import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
// import _ from 'lodash'

import ButtonWithSpinner from 'components/lib/ButtonWithSpinner'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  header: {
    fontWeight: 600,
  },
  paper: {
    border: '1px solid #ddd',
    width: '100%',
    padding: '16px 24px',
  },
  cancelButton: {
    marginLeft: 'auto',
    marginRight: theme.spacing(2),
  },
}))

export default ({
  spec,
  measurementValues,
  isDirty,
  isSubmitting,
  onSpecCreate,
  onSpecEdit,
  onReturnToList,
}) => {
  const classes = useStyles()

  const handleSubmit = () => {
    if (spec.id) {
      onSpecEdit()
    } else {
      onSpecCreate()
    }
  }

  // NOTE: eventually we want to improve logic to see if all fields are filled out, and this code is a start
  // const hasEnoughMeasurements = React.useMemo(() => {
  //  let fieldsCompleted = 0 // keep track of how many fields we've filled out

  //  Object.entries(values).forEach(([size, measurements]) => {
  //    // look at non empty string values -- might need to look out for 0s
  //    // fieldsCompleted += _.filter(Object.values(measurements), o => !!o).length

  //    sizeDef.push({ size, ...measurements })
  //  })

  //   if (spec.primaryMeasurements.length < 2) {
  //     return false
  //   }
  //
  //   let enoughMeasurements = true
  //
  //   spec.primaryMeasurements.forEach(measurement => {
  //     console.log(measurementValues[measurement])
  //     if (!measurementValues[measurement]) {
  //       enoughMeasurements = false
  //     }
  //   })
  //   console.log('are there enough measurements?', enoughMeasurements)
  //   return enoughMeasurements
  // }, [spec.primaryMeasurements, measurementValues])

  const isDisabled =
    spec.primaryMeasurements.length < 2 || !spec.name || !isDirty

  return (
    <div className={classes.root}>
      <div className={classes.cancelButton}>
        <Button size="small" onClick={onReturnToList} disabled={isSubmitting}>
          Cancel
        </Button>
      </div>
      <ButtonWithSpinner
        variant="contained"
        color="primary"
        size="small"
        disabled={isDisabled || isSubmitting}
        isSpinning={isSubmitting}
        spinnerProps={{ color: 'secondary' }}
        onClick={handleSubmit}
      >
        {spec.id ? 'Save' : 'Create'}
      </ButtonWithSpinner>
    </div>
  )
}
