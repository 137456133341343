import _ from 'lodash'
import numeral from 'numeral'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import * as Measurements from 'lib/measurements'

const styles = makeStyles((theme) => ({
  label: {
    paddingRight: theme.spacing(1),
    textAlign: 'right',
  },
}))

const SHORTLIST_MEASUREMENTS = [
  'age',
  'height',
  'weight',
  'midNeckGirth',
  'chestBustGirth',
  'naturalWaistGirth',
  'hipSeatGirth',
  'armLength',
  'inseam',
]

const FULL_MEASUREMENTS = [
  'age',
  'height',
  'weight',
  'bodyComposition',
  'bmi',
  'chestBustGirth',
  'hipSeatGirth',
  'midNeckGirth',
  'naturalWaistGirth',
  'acrossBackShoulderWidth',
  'acrossFrontShoulderWidth',
  'ankleGirth',
  'armLength',
  'armscyeGirth',
  'calfGirth',
  'cervicalToWristLength',
  'crotchHeight',
  'crotchLength',
  'forearmGirth',
  'kneeGirth',
  'inseam',
  'outseam',
  'preferredWaistGirth',
  'thighGirth',
  'trunkLength',
  'underBustGirth',
  'upperArmGirth',
]

export default ({ means, scan }) => {
  const classes = styles()

  const [showMore, setShowMore] = React.useState(false)

  if (!scan) {
    return null
  } else {
    const data = showMore
      ? _.pick(scan, FULL_MEASUREMENTS)
      : _.pick(scan, SHORTLIST_MEASUREMENTS)

    return (
      <table>
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>Avatar</th>
            <th>Mean</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(data).map((measurement) => (
            <tr key={measurement}>
              <td className={classes.label}>
                <Typography variant="caption">
                  {Measurements.getMeasurementLabel(measurement)}
                </Typography>
              </td>
              <td>
                <Typography variant="caption">
                  {numeral(data[measurement]).format('0,0.0')}
                </Typography>
              </td>
              <td>
                <Typography variant="caption">
                  {numeral(means[measurement]).format('0,0.0')}
                </Typography>
              </td>
              <td>
                <Typography variant="caption">
                  {numeral(data[measurement] - means[measurement]).format(
                    '0,0.0'
                  )}
                </Typography>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td
              colSpan="4"
              onClick={() => setShowMore(!showMore)}
              style={{
                textAlign: 'right',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              {showMore ? 'show less' : 'show more'}
            </td>
          </tr>
        </tfoot>
      </table>
    )
  }
}
