import * as PopulationAPI from 'lib/api/population'

export const FETCH_POPULATION_LIST_START = 'FETCH_POPULATION_LIST_START'
export const FETCH_POPULATION_LIST_SUCCESS = 'FETCH_POPULATION_LIST_SUCCESS'
export const FETCH_POPULATION_LIST_FAIL = 'FETCH_POPULATION_LIST_FAIL'

// THUNK
export const fetchPopulationList = () => (dispatch) => {
  dispatch({
    type: FETCH_POPULATION_LIST_START,
  })

  PopulationAPI.fetchPopulationList()
    .then((response) => {
      dispatch({
        type: FETCH_POPULATION_LIST_SUCCESS,
        payload: response,
      })
    })
    .catch((error) => {
      dispatch({
        type: FETCH_POPULATION_LIST_FAIL,
        error,
      })
    })
}
