import { createStore, applyMiddleware, compose } from 'redux'
import { enhancers, middlewares } from './middleware'
import rootReducer from '../reducers/root_reducer'
// import { cacheState, loadState } from 'lib/localStorage'
import { loadState } from 'lib/localStorage'

const composedEnhancers = compose(applyMiddleware(...middlewares), ...enhancers)

const initializeStore = () => {
  const persistedState = loadState()
  const store = createStore(rootReducer, persistedState, composedEnhancers)
  return store
}

const store = initializeStore()
// store.subscribe(() => {
//   cacheState({
//     reducerSlice: store.getState().reducerSlice
//   })
// })

export default store

/*
import project from 'mock_data/project'
import charts from 'mock_data/charts'
import sizeSpecs from 'mock_data/size_specs'
import sizeSpecDistributions from 'mock_data/size_spec_distributions'
import { scatterplots } from 'mock_data/audience_distribution'
import { distribution } from 'mock_data/area_chart'
import { countries, states } from 'mock_data/audience_by_region'
import { fitCoverage } from 'mock_data/fit_coverage'
import { scanBucket } from 'mock_data/scan_bucket'
import { finderScans } from 'mock_data/finder_result'
import { sizeSpecsList } from 'mock_data/size_specs'
import { compareSizeSpec } from 'mock_data/compare_sizeSpec'
import { compareFitCoverage } from 'mock_data/compare_fitCoverage'
import { compareCorrelation } from 'mock_data/compare_correlation'

const preloadedState = () => {
  return {
    audienceFilter: {
      ageMin: 25
    },
    charts,
    project,
    region: {
      countries,
      states
    },
    compare: {
      correlation: compareCorrelation,
      fitCoverage: compareFitCoverage,
      sizeSpec: compareSizeSpec
    },
    fitCoverage,
    sizeSpecs,
    sizeSpecDistributions,
    scanBucket,
    ui: {
      mode: {
        finder: false,
        explorer: false
      },
      isHidden: {
        audienceOverviewCharts: false,
        fitCoverageCharts: false
      }
    },
    units: 'us'
  }
}

const addPromiseSupportToDispatch = store => {
  const rawDispatch = store.dispatch
  return (action) => {
    if (typeof action.then === 'function') {
      return action.then(rawDispatch)
    }
    return rawDispatch(action)
  }
}

store.dispatch = addPromiseSupportToDispatch(store)
*/
