import clsx from 'clsx'
import React from 'react'
import { fade, makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

import ProjectFormDialog from 'components/homepage/ProjectFormDialog'

const styles = makeStyles((theme) => ({
  card: {
    minWidth: '320px',
    maxWidth: '436px',
    minHeight: '240px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: '#eee',
    cursor: 'pointer',
    color: theme.palette.text.secondary,
  },
  cardRaised: {
    backgroundColor: fade(theme.palette.secondary.main, 0.1),
  },
}))

export default ({ onNewProject, populations }) => {
  const classes = styles()

  const [isRaised, setIsRaised] = React.useState(false)
  const [isCreatingProject, setIsCreatingProject] = React.useState(false)

  const handleClose = () => {
    setIsCreatingProject(false)
    setIsRaised(false)
  }

  const handleCreate = (project) => {
    setIsCreatingProject(false)
    onNewProject(project)
  }

  return (
    <Card
      className={clsx(classes.card, isRaised ? classes.cardRaised : null)}
      raised={isRaised}
      onMouseEnter={() => setIsRaised(true)}
      onMouseLeave={() => setIsRaised(false)}
      onClick={() => (!isCreatingProject ? setIsCreatingProject(true) : null)}
    >
      <CardContent>
        <AddIcon style={{ fontSize: 40 }} />
        <Typography>Start a new project</Typography>
      </CardContent>

      <ProjectFormDialog
        open={isCreatingProject}
        onClose={handleClose}
        onSave={handleCreate}
        populations={populations}
      />
    </Card>
  )
}
