import _ from 'lodash'
import React from 'react'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

export default (props) => {
  return (
    <Button
      {..._.omit(props, ['isSpinning', 'spinnerProps'])}
      startIcon={
        props.isSpinning ? (
          <CircularProgress size={20} {...(props.spinnerProps || {})} />
        ) : null
      }
    >
      {props.children || 'Unknown'}
    </Button>
  )
}
