import React from 'react'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import AddIcon from '@material-ui/icons/Add'

export default ({ onClick }) => {
  return (
    <Tooltip title="Create New Spec">
      <Fab color="secondary" aria-label="create-spec" onClick={onClick}>
        <AddIcon />
      </Fab>
    </Tooltip>
  )
}
