import { combineReducers } from 'redux'

const isAuthenticated = (state = false, action) => {
  switch (action.type) {
    case 'VALIDATE_SESSION_SUCCESS':
      return true
    case 'VALIDATE_SESSION_FAIL':
      return false
    default:
      return state
  }
}

const NULL_USER = {
  valid: false,
  email: null,
  name: null,
  team: {
    populations: [],
    id: null,
    name: null,
  },
}

const user = (state = NULL_USER, action) => {
  switch (action.type) {
    case 'VALIDATE_SESSION_SUCCESS':
      return action.payload
    case 'VALIDATE_SESSION_FAIL':
      return NULL_USER
    default:
      return state
  }
}

export default combineReducers({
  isAuthenticated,
  user,
})
