import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

import SpecSummary from 'components/size_explorer/size_specs/list/SpecSummary'
import EditButton from 'components/size_explorer/size_specs/list/EditButton'
import DuplicateButton from 'components/size_explorer/size_specs/list/DuplicateButton'
import DeleteButton from 'components/size_explorer/size_specs/list/DeleteButton'

const useStyles = makeStyles((theme) => {
  return {
    listItem: {
      // height: 80
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    nameContainer: {
      width: '25%',
      minWidth: 200,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      paddingRight: theme.spacing(2),
    },
    deleteButtonContainer: {
      marginLeft: 'auto',
    },
  }
})

const SpecsList = ({ onDuplicate, onEdit, projectId, specsList }) => {
  const classes = useStyles()

  return (
    <List aria-label="main mailbox folders" disablePadding>
      {specsList.map((spec) => (
        <ListItem key={spec.id} className={classes.listItem} divider>
          <Grid container alignItems="center">
            <Typography className={classes.nameContainer} variant="subtitle1">
              {spec.name}
            </Typography>
            <SpecSummary spec={spec} />
            <div className={classes.deleteButtonContainer}>
              <EditButton onClick={() => onEdit(spec)} />
              <DuplicateButton onClick={() => onDuplicate(spec)} />
              <DeleteButton projectId={projectId} spec={spec} />
            </div>
          </Grid>
        </ListItem>
      ))}
    </List>
  )
}

export default SpecsList
