import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import ScanStats from 'components/size_explorer/visualize/avatars/reference/ScanStats'
import ScanTile from 'components/lib/ScanTile'

const useStyles = makeStyles((theme) => {
  return {
    avatar: {
      width: 'calc(20% - 16px)',
      margin: theme.spacing(1),
      height: '100%',
      cursor: 'pointer',
    },
    caption: {
      marginTop: theme.spacing(1),
      textAlign: 'center',
    },
  }
})

const ReferenceAvatars = ({
  sizeSpec,
  fitCoverageData,
  angle,
  onClickOpen,
}) => {
  const classes = useStyles()
  const [raisedAvatar, setRaisedAvatar] = React.useState(null)

  return (
    <Grid container alignItems="center">
      {sizeSpec.sizeDefs.map(({ size, referencePkgId }, idx) => (
        <div
          key={`reference-${referencePkgId}-${idx}`}
          className={classes.avatar}
        >
          <ScanTile
            label={size}
            onClick={() => onClickOpen(size)}
            onMouseOver={(e) => setRaisedAvatar(referencePkgId)}
            onMouseOut={(e) => setRaisedAvatar(null)}
            raised={referencePkgId && raisedAvatar === referencePkgId}
            scanImageProps={{
              angle,
              isFetching: typeof referencePkgId === 'undefined',
              pkgId: referencePkgId,
            }}
          />

          <div className={classes.caption}>
            <ScanStats
              count={
                fitCoverageData.sizes && fitCoverageData.sizes[idx]
                  ? fitCoverageData.sizes[idx].count
                  : 0
              }
              coverage={
                fitCoverageData.sizes && fitCoverageData.sizes[idx]
                  ? fitCoverageData.sizes[idx].coverage
                  : 0
              }
            />
          </div>
        </div>
      ))}
    </Grid>
  )
}

export default ReferenceAvatars
