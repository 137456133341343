import React from 'react'
import ReactGA from 'react-ga'
import { connect } from 'react-redux'
import {
  Redirect,
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom'
import { createMuiTheme, withStyles } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import * as AuthActions from 'actions/auth'
import * as auth from 'lib/auth'
import * as PopulationActions from 'actions/population'
import * as ProjectActions from 'actions/projects'
import * as ProjectAPI from 'lib/api/project'
import HeaderBar from 'components/header_bar/HeaderBar'
import HomepageIndex from 'components/homepage/HomepageIndex'
import ScrollToTop from 'components/lib/ScrollToTop'
import SizeExplorerIndex from 'components/size_explorer/SizeExplorerIndex'
import AvatarsIndex from 'components/avatars/AvatarsIndex'

const theme = createMuiTheme({
  palette: {
    background: {
      default: '#f3f3f5',
    },
    primary: {
      main: '#3033ff',
    },
    secondary: {
      main: '#F5F5FF',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        padding: '8px 36px',
        fontWeight: 600,
      },
      contained: {
        boxShadow: 'none',
      },
    },
    MuiExpansionPanel: {
      root: {
        '&:before': {
          display: 'none',
        },
        '&.Mui-expanded': {
          margin: 0, // https://material-ui.com/customization/components/#pseudo-classes
        },
        border: '1px #ddd solid',
        borderRadius: '4px !important', // !important to override default of first-child & last-child stylings
        marginBottom: '20px !important',
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        borderTop: '1px #ddd solid',
        padding: '24px',
        justifyContent: 'center',
      },
    },
    MuiExpansionPanelActions: {
      root: {
        justifyContent: 'center',
        padding: '24px',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '14px',
      },
    },
  },
})

const styles = {
  toolbar: {
    minHeight: 48, // dense toolbar height
  },
}

class App extends React.Component {
  componentDidMount() {
    // When the application loads, always check user authentication which is one of three states:
    //  1. user coming in with new session via oauth
    //  2. user loading app with existing session token in local storage
    //  3. unauthentic user needs to login

    if (window.location.hash.indexOf('access_token') !== -1) {
      // user coming in with a fresh auth token
      auth.getWebAuth(window.location.href).parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.props.oauth2Login(authResult)
          // remove the hash from the url completely
          window.history.replaceState(
            '',
            document.title,
            window.location.pathname + window.location.search
          )
        } else if (err) {
          console.log('Error with token parsing', err)
        }
      })
    } else if (auth.hasSessionToken()) {
      // app loading with auth token in local storage
      this.props.validateSession()
    } else {
      auth.getWebAuth(window.location.origin).authorize()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.isAuthenticated && this.props.isAuthenticated) {
      // indicates successful auth validation.  lets grab a couple things we know we'll need
      this.bootstrap()
    } else if (prevProps.isAuthenticated && !this.props.isAuthenticated) {
      // indicates user logout, thus send user to the oauth server for a full logout
      auth.logout()
    }

    // toggle hubspot display when entering fullscren mode
    if (!prevProps.fullscreen && this.props.fullscreen) {
      const hubspotWidget = document.getElementById(
        'hubspot-messages-iframe-container'
      )
      hubspotWidget.style.cssText = 'display: none !important'
    } else if (prevProps.fullscreen && !this.props.fullscreen) {
      const hubspotWidget = document.getElementById(
        'hubspot-messages-iframe-container'
      )
      hubspotWidget.style.cssText = 'width: 100px; height: 96px;' // default from hubspot, thinking about a cleaner way of storing this
    }
  }

  bootstrap() {
    // this is intended to be called exactly once per app load to do initial fetching of critical data
    this.props.fetchPopulations()

    ProjectAPI.fetchProjectList()
      .then(({ projects }) => this.props.setProjectsColl(projects))
      .catch((err) => console.log(err))

    // Google Analytics
    ReactGA.set({ page: '/' })
    ReactGA.pageview('/')
  }

  render() {
    const { classes, isAuthenticated } = this.props

    if (!isAuthenticated) {
      // we don't need to render anything in this situation because 1 of 3 things is happening ..
      //  A) we just got a new oauth2 token and fired a login() action so render() will be called again shortly
      //  B) user had existing session and we are validating it, so render() will be called again shortly
      //  C) no token available and the user needs to login, so we are redirecting them to the oauth2 login page
      return null
    } else {
      return (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <ScrollToTop>
              <HeaderBar />
              <div className={classes.toolbar} />

              <Switch>
                <Route exact path="/">
                  <Redirect to="/projects" />
                </Route>
                <Route exact path="/avatars">
                  <AvatarsIndex />
                </Route>
                <Route exact path="/projects">
                  <HomepageIndex />
                </Route>
                <Route exact path="/projects/:id">
                  <SizeExplorerIndex />
                </Route>
              </Switch>
            </ScrollToTop>
          </Router>
        </ThemeProvider>
      )
    }
  }
}

const mapStateToProps = (state, props) => {
  return {
    fullscreen: state.fullscreen,
    isAuthenticated: state.session.isAuthenticated,
    project: state.project.entities[state.project.selected],
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPopulations: () => dispatch(PopulationActions.fetchPopulationList()),
    oauth2Login: (token) => dispatch(AuthActions.oauth2Login(token)),
    setProjectsColl: (projects) =>
      dispatch(ProjectActions.setProjectsColl(projects)),
    validateSession: () => dispatch(AuthActions.validateSession()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(App))
