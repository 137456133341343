import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'

import ButtonWithSpinner from 'components/lib/ButtonWithSpinner'

const useStyles = makeStyles((theme) => ({
  confirmButton: {
    marginLeft: 8,
  },
  dialogActions: {
    minWidth: '400px',
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

const DownloadAvatarsDialog = ({
  count = 0,
  email = 'unknown',
  errorText,
  isWorking,
  onClose,
  onConfirm,
  open,
}) => {
  const classes = useStyles()

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="simple-dialog-title">Avatar download</DialogTitle>

      <DialogContent>
        <DialogContentText>
          <p style={{ marginTop: 0 }}>
            You are downloading {count} {count > 1 ? 'avatars' : 'avatar'}.
          </p>

          <p>
            We need a moment to prepare your download. When your{' '}
            {count > 1 ? 'avatars are' : 'avatar is'} ready we'll send you an
            email at <b>{email}</b> with a link to access your download.
          </p>
        </DialogContentText>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        {errorText ? <div>{errorText}</div> : <div />}
        <div>
          <Button size="small" onClick={onClose}>
            Cancel
          </Button>
          <ButtonWithSpinner
            className={classes.confirmButton}
            disabled={isWorking}
            isSpinning={isWorking}
            size="small"
            variant="contained"
            color="primary"
            onClick={onConfirm}
          >
            Confirm
          </ButtonWithSpinner>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default DownloadAvatarsDialog
