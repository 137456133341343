import _ from 'lodash'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import SearchIcon from '@material-ui/icons/Search'

import SearchSlider from 'components/avatars/search/SearchSlider'
import * as Measurements from 'lib/measurements'
import * as Units from 'lib/units'

const styles = makeStyles((theme) => ({
  filterButton: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    textAlign: 'center',
  },
  filterInput: {},
  filterLabel: {
    fontWeight: 600,
    textAlign: 'right',
  },
}))

const DEFAULT_FILTERS = [
  'age',
  'height',
  'weight',
  'chestBustGirth',
  'naturalWaistGirth',
  'hipSeatGirth',
]

function convertValues(measurement, val, toUnits) {
  const config = Measurements.MEASUREMENTS[measurement]

  // convert to US units
  const unitType = config.unitType || 'length'

  if (unitType === 'length') {
    // cm -> in
    return val.map((v) =>
      Math.round(toUnits === 'us' ? Units.cmToIn(v) : Units.inToCm(v))
    )
  } else if (unitType === 'mass') {
    // kg -> lb
    return val.map((v) =>
      Math.round(toUnits === 'us' ? Units.kgToLb(v) : Units.lbToKg(v))
    )
  } else {
    return val
  }
}

export default ({ isLoading, onSubmit, onSetGender, units }) => {
  const classes = styles()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [filters, setFilters] = React.useState({})
  const [gender, setGender] = React.useState('female')
  const [measurements, setMeasurements] = React.useState(DEFAULT_FILTERS)

  // this feels a little silly, but we need it to keep track of our previous 'units' value
  // we do this for the hook below which we only want to trigger if the units has changed
  // but the hook requires our filters so it needs a way to know when just the units has changed
  const prevUnits = React.useRef()
  React.useEffect(() => {
    if (_.isEmpty(filters)) {
      setFilters(
        Object.assign(
          {},
          ...DEFAULT_FILTERS.map((mm) => ({
            [mm]: Measurements.getRange90th(mm, units) || [0, 100],
          }))
        )
      )
    } else if (prevUnits.current !== units) {
      // change filter values to new unit system
      const convertedFilters = _.mapValues(filters, (val, measurement) =>
        convertValues(measurement, val, units)
      )
      setFilters(convertedFilters)
    }

    prevUnits.current = units
  }, [filters, units])

  const handleAddMeasurement = (measurement) => {
    setMeasurements([...measurements, measurement])
    setFilters({
      ...filters,
      [measurement]: Measurements.getRange90th(measurement, units) || [0, 100],
    })
    // after adding a measurement close the menu
    setAnchorEl(null)
  }

  const handleFilterChange = (measurement, value) => {
    setFilters({
      ...filters,
      [measurement]: value,
    })
  }

  const handleSearch = () => {
    // our range filters need to be built into our object style filters with Min/Max props
    const rangeFiltersArr = Object.keys(filters)
      .map((filter) => ({
        [`${filter}Min`]: filters[filter][0],
        [`${filter}Max`]: filters[filter][1],
      }))
      .flat()
    const rangeFiltersObj = Object.assign({}, ...rangeFiltersArr)

    const query = {
      audience: {
        filters: rangeFiltersObj,
      },
      gender,
      units,
    }

    onSubmit(query)
  }

  const handleSetGender = (gender) => {
    setGender(gender)
    onSetGender(gender)
  }

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={4} className={classes.filterLabel}>
        Gender
      </Grid>
      <Grid item xs={8} className={classes.filterInput}>
        <RadioGroup
          aria-label="gender"
          name="gender1"
          value={gender}
          onChange={(e) => handleSetGender(e.target.value)}
          row
          style={{ justifyContent: 'space-evenly' }}
        >
          <FormControlLabel value="male" label="Male" control={<Radio />} />
          <FormControlLabel value="female" label="Female" control={<Radio />} />
        </RadioGroup>
      </Grid>

      {measurements.map((id) => {
        const label = Measurements.getMeasurementLabel(id)
        const scale = Measurements.getScale(id, units) || [undefined, undefined]
        const range = filters[id] || [undefined, undefined]

        return (
          <React.Fragment key={`label-${id}`}>
            <Grid item xs={4} className={classes.filterLabel}>
              {label}
            </Grid>
            <Grid item xs={8} className={classes.filterInput}>
              <SearchSlider
                value={range}
                valueLabelDisplay="on"
                min={scale[0]}
                max={scale[1]}
                onChange={(e, value) => handleFilterChange(id, value)}
              />
            </Grid>
          </React.Fragment>
        )
      })}

      <Grid item xs={4}>
        &nbsp;
      </Grid>
      <Grid item xs={8}>
        <Grid container className={classes.filterButton} justify="space-evenly">
          <Button
            variant="contained"
            size="small"
            startIcon={<AddIcon />}
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            Add Filter
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            {_.difference(
              Object.keys(Measurements.MEASUREMENTS),
              measurements
            ).map((measurement) => (
              <MenuItem
                key={measurement}
                onClick={() => handleAddMeasurement(measurement)}
                value={measurement}
              >
                {Measurements.MEASUREMENTS[measurement].label}
              </MenuItem>
            ))}
          </Menu>

          <Button
            variant="contained"
            size="small"
            color="primary"
            startIcon={<SearchIcon />}
            disabled={isLoading}
            onClick={handleSearch}
          >
            Find Avatars
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
