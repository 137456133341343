import _ from 'lodash'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'

import NameInput from 'components/size_explorer/size_specs/forms/NameInput'
import ManualInputForm from 'components/size_explorer/size_specs/forms/ManualInputForm'
import SpecFormActions from 'components/size_explorer/size_specs/forms/FormActions'
import * as QueriesApi from 'lib/api/queries'
import { updateSizeSpec, createSizeSpec } from 'lib/api/size_specs'
import {
  formatSizeDefs,
  mergeSizeDefs,
  unflattenSizeDefs,
} from 'lib/size_specs_form'

const specTemplate = {
  id: '',
  name: '',
  sizeDefs: [],
  primaryMeasurements: [],
  secondaryMeasurements: [],
}

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    marginBottom: theme.spacing(2),
  },
  header: {
    fontWeight: 600,
  },
  paper: {
    border: '1px solid #ddd',
    width: '100%',
    padding: '16px 24px',
  },
  button: {
    padding: '8px 14px',
    fontSize: 10,
    marginRight: 16,
  },
  cancelButton: {
    marginLeft: 'auto',
  },
}))

export default ({ mode, onCancel, onSave, project, spec, specsList }) => {
  const classes = useStyles()

  const [isDirty, setIsDirty] = React.useState(false)
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [specEdit, setSpecEdit] = React.useState(specTemplate)

  // custom data structure we use to simplify data management, must be formatted before submitting spec
  const [measurementValues, setMeasurementValues] = React.useState({})
  const [additionalFilters, setAdditionalFilters] = React.useState({})

  React.useEffect(() => {
    // need better name for this, but basically re-format data structure b/c it's aesier to manage the form
    const formatValues = (sizeDefs) => {
      const { measurementValues, filters } = unflattenSizeDefs(sizeDefs)

      setMeasurementValues(measurementValues)
      setAdditionalFilters(filters)
    }

    if (mode === 'update' && spec) {
      // edit existing spec
      setSpecEdit({
        ...spec,
        primaryMeasurements: spec.primaryMeasurements,
        secondaryMeasurements: spec.secondaryMeasurements,
        sizes: spec.sizeDefs.map(({ size }) => size).join(', '),
      })

      formatValues(spec.sizeDefs)
    } else if (mode === 'duplicate' && spec) {
      // duplicate an existing spec and create a new one

      // NOTE: don't really love having to calculate something here, seems icky :/
      // plan on adding primaryMeasurements/secondaryMeasurements to backend, then moving the sizes string out to local state
      setSpecEdit({
        ...spec,
        id: '',
        name: `${spec.name} - Duplicate`,
        primaryMeasurements: spec.primaryMeasurements,
        secondaryMeasurements: spec.secondaryMeasurements,
        sizes: spec.sizeDefs.map(({ size }) => size).join(', '),
      })

      formatValues(spec.sizeDefs)
    } else {
      // create a brand new spec
      setSpecEdit(specTemplate)
    }
  }, [mode, spec])

  const onSpecCreate = () => {
    setIsSubmitting(true)

    const newSpec = {
      ...specEdit,
      sizeDefs: formatSizeDefs(
        specEdit.primaryMeasurements,
        specEdit.secondaryMeasurements,
        measurementValues,
        additionalFilters,
        specEdit.sizes
          .split(',')
          .map((size) => size.trim())
          .filter((o) => !!o)
      ),
    }

    const query = {
      population: project.population,
      units: project.units,
      audience: {
        filters: project.audience,
      },
      sizeDefs: newSpec.sizeDefs,
      primaryMeasurements: newSpec.primaryMeasurements,
      secondaryMeasurements: newSpec.secondaryMeasurements,
    }

    QueriesApi.getSizeSpecCentroids(query)
      .then((result) => {
        const completeSpec = {
          ...newSpec,
          sizeDefs: newSpec.sizeDefs.map((def, idx) => {
            const { centroids } = result.sizes[idx]
            return {
              ...def,
              referencePkgId:
                centroids && centroids.length > 0 ? centroids[0] : null,
            }
          }),
        }

        createSizeSpec(project.id, completeSpec)
          .then((result) => {
            setIsSubmitting(false)
            onSave(result)
          })
          .catch((error) => {
            setIsSubmitting(false)
            console.log(error)
          })
      })
      .catch((err) => {
        setIsSubmitting(false)
        console.log('Error looking up spec centroids', err)
      })
  }

  const onSpecEdit = () => {
    setIsSubmitting(true)

    const newSizeDefs = formatSizeDefs(
      specEdit.primaryMeasurements,
      specEdit.secondaryMeasurements,
      measurementValues,
      additionalFilters,
      specEdit.sizes
        .split(',')
        .map((size) => size.trim())
        .filter((o) => !!o) // sizes list in proper order
    )

    const editedSpec = {
      ...specEdit,
      sizeDefs: mergeSizeDefs(newSizeDefs, spec.sizeDefs),
    }

    const saveSpecEdits = (spec) => {
      updateSizeSpec(project.id, spec)
        .then((result) => {
          setIsSubmitting(false)
          onSave(result)
        })
        .catch((error) => {
          setIsSubmitting(false)
          console.log(error)
        })
    }

    // Check if any sizes don't have a reference avatar
    const needCentroids = _.some(
      editedSpec.sizeDefs,
      (sizeDef) => !sizeDef.referencePkgId
    )
    // const existingSizes = project.sizeSpecs[specEdit.id].sizeDefs.map(({ size }) => size)
    // const needCentroids = editedSpec.sizeDefs.filter(({ size }) => !existingSizes.includes(size)).length > 0

    if (needCentroids) {
      const query = {
        population: project.population,
        units: project.units,
        audience: {
          filters: project.audience,
        },
        sizeDefs: editedSpec.sizeDefs,
        primaryMeasurements: editedSpec.primaryMeasurements,
        secondaryMeasurements: editedSpec.secondaryMeasurements,
      }

      QueriesApi.getSizeSpecCentroids(query)
        .then((result) => {
          const completeSpec = {
            ...editedSpec,
            sizeDefs: editedSpec.sizeDefs.map((def, idx) => {
              if (def.referencePkgId) {
                return def
              } else {
                const { centroids } = result.sizes[idx]
                return {
                  ...def,
                  referencePkgId:
                    centroids && centroids.length > 0 ? centroids[0] : null,
                }
              }
            }),
          }

          saveSpecEdits(completeSpec)
        })
        .catch((err) => {
          setIsSubmitting(false)
          console.log('Error looking up spec centroids', err)
        })
    } else {
      saveSpecEdits(editedSpec)
    }
  }

  const onSpecUpdate = (param, val) => {
    setIsDirty(true)
    setSpecEdit((oldState) => ({
      ...oldState,
      [param]: val,
    }))
  }

  return (
    <Grid container spacing={1}>
      <Grid className={classes.headerContainer} item xs={12}>
        <Grid container justify="space-between" alignItems="center">
          <Typography className={classes.header} variant="h5">
            {mode === 'update' ? 'Edit Size Spec' : 'Create Size Spec'}
          </Typography>
          <SpecFormActions
            spec={specEdit}
            measurementValues={measurementValues}
            isDirty={isDirty}
            isSubmitting={isSubmitting}
            onSpecCreate={onSpecCreate}
            onSpecEdit={onSpecEdit}
            onReturnToList={onCancel}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <NameInput spec={specEdit} onSpecUpdate={onSpecUpdate} />
      </Grid>

      <Grid item xs={12}>
        <Paper style={{ padding: 24 }}>
          <ManualInputForm
            spec={specEdit}
            onSpecUpdate={onSpecUpdate}
            measurementValues={measurementValues}
            setMeasurementValues={setMeasurementValues}
            additionalFilters={additionalFilters}
            setAdditionalFilters={setAdditionalFilters}
            setIsDirty={setIsDirty}
          />
        </Paper>
      </Grid>
    </Grid>
  )
}
