import React from 'react'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import AccountManager from './AccountManager'

const useStyles = makeStyles((theme) => ({
  header: {
    background:
      'linear-gradient(26deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #EFEFFF',
    color: 'black',
    zIndex: theme.zIndex.drawer + 1,
    top: 0,
    width: '100%',
    borderBottom: '1px solid #ddd',
  },
  indicator: {
    background: theme.palette.primary.main,
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  logoImage: {
    width: 70,
    height: 24,
    marginRight: 16,
  },
  logoText: {
    color: 'black',
    opacity: 0.8,
    fontWeight: 700,
    fontSize: '.9rem',
    letterSpacing: '1.5px',
    marginTop: 5,
    marginLeft: -10,
    marginRight: 35,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

const TABS = [
  { label: 'Sizing', path: '/projects' },
  { label: 'Avatars', path: '/avatars' },
]

const HeaderBar = ({ history, location }) => {
  const classes = useStyles()
  const { pathname } = location

  const currentTab = TABS.find((i) => pathname.includes(i.path))
  const currentTabIndex = TABS.indexOf(currentTab)

  const handleLogoClick = () => {
    history.push('/projects')
  }
  const handleTab = (idx) => {
    const { path } = TABS[idx]
    history.push(path)
  }

  return (
    <AppBar className={classes.header} color="secondary" elevation={0}>
      <Toolbar variant="dense">
        <Grid container>
          <Grid item className={classes.logo} onClick={handleLogoClick}>
            <img
              className={classes.logoImage}
              src="/assets/wair-logo-black.svg"
              alt="logo"
            />
            <Typography
              variant="h6"
              component="span"
              className={classes.logoText}
            >
              INSIGHTS
            </Typography>
          </Grid>

          <Grid item>
            <Tabs
              classes={{ indicator: classes.indicator }}
              value={currentTabIndex}
              onChange={(e, idx) => handleTab(idx)}
            >
              {TABS.map((tab) => (
                <Tab key={tab.path} label={tab.label} />
              ))}
            </Tabs>
          </Grid>
        </Grid>

        <Grid item>
          <AccountManager />
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default withRouter(HeaderBar)
