import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import Avatars from 'components/size_explorer/visualize/avatars/AvatarsIndex'
import AngleSelect from 'components/lib/AngleSelect'

const useStyles = makeStyles((theme) => ({
  avatarsContainer: {
    padding: '20px 24px',
  },
  angleSelect: {
    width: 240,
    marginRight: theme.spacing(1),
  },
}))

export default function VisualizeIndex() {
  const classes = useStyles()
  const [angle, setAngle] = React.useState('000') // 000, 090, 180)

  const handleAngleSelect = (val) => {
    setAngle(val)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container justify="space-between">
          <Typography variant="h5" style={{ fontWeight: 600 }}>
            Reference Avatars
          </Typography>
          <div className={classes.angleSelect}>
            <AngleSelect angle={angle} onAngleSelect={handleAngleSelect} />
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.avatarsContainer}>
          <Avatars angle={angle} />
        </Paper>
      </Grid>
    </Grid>
  )
}
