import React from 'react'
import Typography from '@material-ui/core/Typography'

export default ({
  audienceMean,
  audienceStd,
  populationMean,
  populationStd,
}) => {
  return (
    <>
      {audienceMean && audienceStd && populationMean && populationStd && (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <section>
            <Typography
              variant="caption"
              display="block"
              style={{ fontWeight: 600, fontSize: '10px' }}
            >
              Audience
            </Typography>
            <Typography
              variant="caption"
              display="block"
              style={{ fontSize: '10px' }}
            >{`Mean=${Number(audienceMean).toFixed(1)}`}</Typography>
            <Typography
              variant="caption"
              display="block"
              style={{ fontSize: '10px' }}
            >{`Std=${Number(audienceStd).toFixed(1)}`}</Typography>
          </section>
          <section>
            <Typography
              variant="caption"
              display="block"
              style={{ fontWeight: 600, fontSize: '10px' }}
            >
              Population
            </Typography>
            <Typography
              variant="caption"
              display="block"
              style={{ fontSize: '10px' }}
            >{`Mean=${Number(populationMean).toFixed(1)}`}</Typography>
            <Typography
              variant="caption"
              display="block"
              style={{ fontSize: '10px' }}
            >{`Std=${Number(populationStd).toFixed(1)}`}</Typography>
          </section>
        </div>
      )}
    </>
  )
}
