import React from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

import LoadingSpinner from 'components/size_explorer/lib/LoadingSpinner'

import { getTotalCoverage, getTotalPopulation } from 'lib/fit_coverage'
import { generateSizeTableDataAll } from 'lib/size_definition'
import { getMeasurementLabel } from 'lib/measurements'
import { formatNumber } from 'lib/format'

const tableStyle = {
  header: {
    fontWeight: 'bold',
  },
  cell: {
    padding: 8,
    whiteSpace: 'nowrap',
  },
  baseline: {
    color: '#3033ff',
    fontWeight: 'bold',
  },
  potential: {
    color: '#691B99',
    fontWeight: 'bold',
  },
  border: {
    borderRight: '1px solid #E0E0E0',
  },
}

export default ({ fitCoverage, baselineSpec, potentialSpec }) => {
  const [baselineSizeDefs, setBaselineSizeDefs] = React.useState(
    baselineSpec.sizeDefs
  )
  const [potentialSizeDefs, setPotentialSizeDefs] = React.useState(
    potentialSpec.sizeDefs
  )

  React.useEffect(() => {
    setBaselineSizeDefs(baselineSpec.sizeDefs)
    setPotentialSizeDefs(potentialSpec.sizeDefs)
  }, [fitCoverage, baselineSpec, potentialSpec])

  const { primaryMeasurements } = baselineSpec // potentialSpec shares same set of primary measurements
  const columnLength = primaryMeasurements.length

  const baselineFitCoverageData = fitCoverage.baseline.data
  const potentialFitCoverageData = fitCoverage.potential.data

  const isFetching =
    fitCoverage.baseline.isFetching || fitCoverage.potential.isFetching

  const sizeTableData = generateSizeTableDataAll(
    baselineSizeDefs,
    baselineFitCoverageData,
    potentialSizeDefs,
    potentialFitCoverageData
  )

  return (
    <main style={{ marginTop: '2rem' }}>
      <Typography variant="h5" style={{ fontWeight: 600 }}>
        Baseline & Comparison Coverage
      </Typography>
      <Paper style={{ overflowX: 'auto', marginTop: '16px', width: '100%' }}>
        {isFetching ? (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ minHeight: '200px' }}
          >
            <Grid item>
              <LoadingSpinner />
            </Grid>
          </Grid>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ ...tableStyle.header }} />
                {primaryMeasurements.map((measurement, idx) => (
                  <TableCell
                    colSpan="2"
                    style={{
                      ...tableStyle.header,
                      ...tableStyle.cell,
                      ...tableStyle.border,
                    }}
                    align="center"
                    key={`primary-measurement-${idx}`}
                  >
                    {getMeasurementLabel(measurement)}
                  </TableCell>
                ))}
                <TableCell
                  colSpan="2"
                  style={{
                    ...tableStyle.header,
                    ...tableStyle.cell,
                    ...tableStyle.border,
                  }}
                  align="center"
                >
                  Population
                </TableCell>
                <TableCell
                  colSpan="2"
                  style={{
                    ...tableStyle.header,
                    ...tableStyle.cell,
                    ...tableStyle.border,
                  }}
                  align="center"
                >
                  Coverage (%)
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    ...tableStyle.header,
                    ...tableStyle.border,
                    ...tableStyle.cell,
                  }}
                  align="center"
                >
                  Size
                </TableCell>
                {Array(columnLength)
                  .fill('')
                  .map((measurement, idx) => (
                    <React.Fragment key={`compare-header-${idx}`}>
                      <TableCell
                        style={{ ...tableStyle.baseline, ...tableStyle.cell }}
                        align="center"
                      >
                        Baseline
                      </TableCell>
                      <TableCell
                        style={{
                          ...tableStyle.potential,
                          ...tableStyle.border,
                          ...tableStyle.cell,
                        }}
                        align="center"
                      >
                        Comparison
                      </TableCell>
                    </React.Fragment>
                  ))}
                <TableCell
                  style={{ ...tableStyle.baseline, ...tableStyle.cell }}
                  align="center"
                >
                  Baseline
                </TableCell>
                <TableCell
                  style={{
                    ...tableStyle.potential,
                    ...tableStyle.border,
                    ...tableStyle.cell,
                  }}
                  align="center"
                >
                  Comparison
                </TableCell>
                <TableCell
                  style={{ ...tableStyle.baseline, ...tableStyle.cell }}
                  align="center"
                >
                  Baseline
                </TableCell>
                <TableCell
                  style={{
                    ...tableStyle.potential,
                    ...tableStyle.border,
                    ...tableStyle.cell,
                  }}
                  align="center"
                >
                  Comparison
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sizeTableData.map((datum, idx) => {
                const { baseline, potential } = datum
                return (
                  <TableRow hover key={`coverage-${idx}`}>
                    <TableCell
                      align="center"
                      style={{ ...tableStyle.border, ...tableStyle.cell }}
                    >
                      {baseline.size || potential.size}
                    </TableCell>
                    {primaryMeasurements.map((measurement, idx) => (
                      <React.Fragment key={`minmax-range-${measurement}`}>
                        <TableCell
                          align="center"
                          style={{ ...tableStyle.cell }}
                        >
                          {baseline[measurement]
                            ? `${baseline[measurement].min} - ${baseline[measurement].max}`
                            : null}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ ...tableStyle.border, ...tableStyle.cell }}
                        >
                          {potential[measurement]
                            ? `${potential[measurement].min} - ${potential[measurement].max}`
                            : null}
                        </TableCell>
                      </React.Fragment>
                    ))}
                    <TableCell align="center" style={{ ...tableStyle.cell }}>
                      {isFetching
                        ? 'Loading...'
                        : baseline.count
                        ? formatNumber(baseline.count)
                        : null}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ ...tableStyle.cell, ...tableStyle.border }}
                    >
                      {isFetching
                        ? 'Loading...'
                        : potential.count
                        ? formatNumber(potential.count)
                        : null}
                    </TableCell>
                    <TableCell align="center" style={{ ...tableStyle.cell }}>
                      {isFetching
                        ? 'Loading...'
                        : baseline.coverage
                        ? (baseline.coverage * 100).toFixed(1)
                        : null}
                    </TableCell>
                    <TableCell align="center" style={{ ...tableStyle.cell }}>
                      {isFetching
                        ? 'Loading...'
                        : potential.coverage
                        ? (potential.coverage * 100).toFixed(1)
                        : null}
                    </TableCell>
                  </TableRow>
                )
              })}
              <TableRow>
                <TableCell
                  colSpan={columnLength * 2 + 1}
                  align="center"
                  style={{ fontWeight: 600, ...tableStyle.border }}
                >
                  Total
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontWeight: 600,
                    color: '#3033ff',
                    ...tableStyle.cell,
                  }}
                >
                  {isFetching
                    ? 'Loading...'
                    : getTotalPopulation(baselineFitCoverageData)}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontWeight: 600,
                    color: '#691B99',
                    ...tableStyle.border,
                    ...tableStyle.cell,
                  }}
                >
                  {isFetching
                    ? 'Loading...'
                    : getTotalPopulation(potentialFitCoverageData)}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontWeight: 600,
                    color: '#3033ff',
                    ...tableStyle.cell,
                  }}
                >
                  {isFetching
                    ? 'Loading...'
                    : `${getTotalCoverage(baselineFitCoverageData)}%`}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontWeight: 600,
                    color: '#691B99',
                    ...tableStyle.cell,
                  }}
                >
                  {isFetching
                    ? 'Loading...'
                    : `${getTotalCoverage(potentialFitCoverageData)}%`}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </Paper>
    </main>
  )
}
