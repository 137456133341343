import * as RestAPI from '../rest_api'

export const fetchProjectList = () => {
  return new Promise((resolve, reject) => {
    RestAPI.GET('/projects')
      .then((res) => resolve(res))
      .catch((err) => reject(err))
  })
}

export const fetchProject = (projectId) => {
  return new Promise((resolve, reject) => {
    RestAPI.GET(`/projects/${projectId}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err))
  })
}

export const createProject = (payload) => {
  return new Promise((resolve, reject) => {
    RestAPI.POST('/projects', payload)
      .then((res) => resolve(res))
      .catch((err) => reject(err))
  })
}

export const deleteProject = (projectId) => {
  return new Promise((resolve, reject) => {
    RestAPI.DELETE(`/projects/${projectId}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err))
  })
}

export const updateProject = (projectId, updates) => {
  return new Promise((resolve, reject) => {
    RestAPI.PUT(`/projects/${projectId}`, updates)
      .then((res) => resolve(res))
      .catch((err) => reject(err))
  })
}

export const updateAudience = (projectId, payload) => {
  return new Promise((resolve, reject) => {
    RestAPI.POST(`/projects/${projectId}/audience`, payload)
      .then((res) => resolve(res))
      .catch((err) => reject(err))
  })
}

export const setModelAvatars = (projectId, specId, modelAvatars) => {
  return RestAPI.POST(
    `/projects/${projectId}/sizespecs/${specId}/models`,
    modelAvatars
  )
}
