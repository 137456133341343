import * as RestAPI from '../rest_api'

export const createOrder = async (avatars) => {
  return RestAPI.POST('/orders', { avatars })
}

export const downloadAvatars = async (avatars) => {
  return RestAPI.POST('/avatars/download', { avatars })
}

export const listAvatars = async () => {
  return RestAPI.GET('/avatars')
}

export const listOrders = async () => {
  return RestAPI.GET('/orders')
}
