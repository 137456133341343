export const clearProject = () => ({
  type: 'PROJECT_CLEAR',
})

export const created = (project) => ({
  type: 'CREATE_PROJECT_SUCCESS',
  payload: project,
})

export const deleted = (project) => ({
  type: 'DELETE_PROJECT_SUCCESS',
  project,
})

export const setProject = (project) => ({
  type: 'PROJECT_SET',
  payload: project,
})

export const setProjectSection = (section) => ({
  type: 'PROJECT_SECTION',
  section,
})

export const setProjectsColl = (projects) => ({
  type: 'PROJECTS_COLL_SET',
  projects,
})

export const updated = (project) => ({
  type: 'PROJECT_UPDATED',
  project,
})

export const updatedAudience = (projectId, audience) => ({
  type: 'PROJECT_UPDATED_AUDIENCE',
  projectId,
  audience,
})

export const updatedSizingAvatars = (project) => ({
  type: 'PROJECT_UPDATED_SIZINGAVATARS',
  project,
})
