import { combineReducers } from 'redux'

import {
  FETCH_FIT_COVERAGE_START_COMPARE_BASELINE,
  FETCH_FIT_COVERAGE_SUCCESS_COMPARE_BASELINE,
  FETCH_FIT_COVERAGE_FAILURE_COMPARE_BASELINE,
  FETCH_FIT_COVERAGE_START_COMPARE_POTENTIAL,
  FETCH_FIT_COVERAGE_SUCCESS_COMPARE_POTENTIAL,
  FETCH_FIT_COVERAGE_FAILURE_COMPARE_POTENTIAL,
} from 'actions/fit_coverage'

const correlation = (state = {}, action) => {
  const key = `${action.measurement}-${action.correlationMeasurement}`

  switch (action.type) {
    case 'PROJECT_CLEAR':
    case 'SET_SIZE_SPEC':
    case 'SIZE_SPEC_UPDATED':
    case 'CLEAR_SIZE_SPEC_COMPARE':
    case 'COMPARE_BUTTON_CLICKED':
      return {}
    case 'COMPARE_FETCH_CORRELATION_DISTRIBUTION_START':
      return {
        ...state,
        [key]: {
          measurement: action.measurement,
          correlationMeasurement: action.correlationMeasurement,
          data: [],
          isFetching: true,
        },
      }
    case 'COMPARE_FETCH_CORRELATION_DISTRIBUTION_SUCCESS':
      return {
        ...state,
        [key]: {
          measurement: action.measurement,
          correlationMeasurement: action.correlationMeasurement,
          data: action.data,
          isFetching: false,
        },
      }
    case 'COMPARE_FETCH_CORRELATION_DISTRIBUTION_FAIL':
      return {
        ...state,
        [key]: {
          measurement: action.measurement,
          correlationMeasurement: action.correlationMeasurement,
          data: [],
          isFetching: false,
        },
      }
    default:
      return state
  }
}

const DEFAULT_FITCOVERAGE = {
  data: [],
  totalCount: 0,
  totalCoverage: 0,
  isFetching: false,
}

const baselineFitCoverage = (state = DEFAULT_FITCOVERAGE, action) => {
  switch (action.type) {
    case 'PROJECT_CLEAR':
    case 'SET_SIZE_SPEC':
    case 'SIZE_SPEC_UPDATED':
    case 'CLEAR_SIZE_SPEC_COMPARE':
      return DEFAULT_FITCOVERAGE
    case FETCH_FIT_COVERAGE_START_COMPARE_BASELINE:
      return {
        ...DEFAULT_FITCOVERAGE,
        isFetching: true,
      }
    case FETCH_FIT_COVERAGE_SUCCESS_COMPARE_BASELINE: {
      // const { total_count, total_coverage, sizes } = action.payload
      return {
        data: action.payload.sizes,
        totalCount: action.payload.total_count,
        totalCoverage: action.payload.total_coverage,
        isFetching: false,
      }
    }
    case FETCH_FIT_COVERAGE_FAILURE_COMPARE_BASELINE:
      return {
        ...DEFAULT_FITCOVERAGE,
        isFetching: false,
      }
    default:
      return state
  }
}

const potentialFitCoverage = (state = DEFAULT_FITCOVERAGE, action) => {
  switch (action.type) {
    case 'PROJECT_CLEAR':
    case 'SET_SIZE_SPEC':
    case 'SIZE_SPEC_UPDATED':
    case 'CLEAR_SIZE_SPEC_COMPARE':
      return DEFAULT_FITCOVERAGE
    case FETCH_FIT_COVERAGE_START_COMPARE_POTENTIAL:
      return {
        ...DEFAULT_FITCOVERAGE,
        isFetching: true,
      }
    case FETCH_FIT_COVERAGE_SUCCESS_COMPARE_POTENTIAL: {
      // const { total_count, total_coverage, sizes } = action.payload
      return {
        data: action.payload.sizes,
        totalCount: action.payload.total_count,
        totalCoverage: action.payload.total_coverage,
        isFetching: false,
      }
    }
    case FETCH_FIT_COVERAGE_FAILURE_COMPARE_POTENTIAL:
      return {
        ...DEFAULT_FITCOVERAGE,
        isFetching: false,
      }
    default:
      return state
  }
}

const potentialSizeSpec = (state = null, action) => {
  switch (action.type) {
    case 'PROJECT_CLEAR':
    case 'CLEAR_SIZE_SPEC_COMPARE':
    case 'SET_SIZE_SPEC':
    case 'SIZE_SPEC_UPDATED':
      return null
    case 'SET_SIZE_SPEC_COMPARE_POTENTIAL':
      return action.sizeSpecId
    default:
      return state
  }
}

export default combineReducers({
  correlation,
  fitCoverage: combineReducers({
    baseline: baselineFitCoverage,
    potential: potentialFitCoverage,
  }),
  sizeSpec: combineReducers({
    potential: potentialSizeSpec,
  }),
})
