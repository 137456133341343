import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Select from 'react-select'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

import * as SizeSpecDistributionActions from 'actions/sizespec_distribution'
import { MEASUREMENTS } from 'lib/measurements'
import { getOptions, selectify } from 'lib/options'
import { histogramSelector, minmaxPegSelector } from 'selectors/charts'
import { extendedSizesSelector, sizeListSelector } from 'selectors/size_specs'

import SizeSpecDistributionTable from 'components/size_explorer/fit_coverage/spec_distributions/Table'

const customSelectStyles = {
  container: (provided, state) => ({
    ...provided,
    width: '250px',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    fontWeight: 600,
    justifyContent: 'center', // centers the selected item text
  }),
  menuList: (provided, state) => ({
    ...provided,
    textAlign: 'center', // centers the menu list item text
  }),
}

const useStyles = makeStyles((theme) => ({
  textField: {
    width: '80px',
    margin: 0,
  },
}))

const secondaryMeasurements = [
  ...Object.values(MEASUREMENTS)
    .filter((m) => m.isBodyMeasurement && !m.isCoreMeasurement)
    .map((m) => m.id),
  'height',
]
const options = getOptions(secondaryMeasurements)

const SizeSpecDistributions = ({
  project,
  selectedSpecId,
  extendedSizes,
  sizesList,
  histogramLines,
  minmaxPeg,
  specDistributions,
  fetchSizeSpecDistributions,
}) => {
  const [measurement, setMeasurement] = React.useState(secondaryMeasurements[0])
  const [selectedSize, setSelectedSize] = React.useState(null)
  const [toleranceVal, setToleranceVal] = React.useState(null)

  const classes = useStyles()

  React.useEffect(() => {
    if (!specDistributions.isFetching && specDistributions.data === null) {
      fetchSizeSpecDistributions({
        population: project.population,
        audience: {
          filters: project.audience,
        },
        sizeDefs: project.sizeSpecs[selectedSpecId].sizeDefs,
        measure: measurement,
        units: project.units,
      })
    }
  }, [
    fetchSizeSpecDistributions,
    measurement,
    project,
    selectedSpecId,
    specDistributions,
  ])

  const handleSelect = ({ value }) => {
    setMeasurement(value)
    fetchSizeSpecDistributions({
      population: project.population,
      audience: {
        filters: project.audience,
      },
      sizeDefs: project.sizeSpecs[selectedSpecId].sizeDefs,
      measure: value,
      units: project.units,
    })
  }

  return (
    <main>
      <header>
        <Typography variant="h5" style={{ fontWeight: 600 }}>
          Secondary Measurements
        </Typography>
      </header>

      <Grid container alignItems="center" style={{ marginTop: '12px' }}>
        <Grid item s={12} style={{ marginRight: '20px' }}>
          <Select
            options={options}
            value={selectify(measurement)}
            onChange={handleSelect}
            styles={customSelectStyles}
            isSearchable={false}
            isDisabled={specDistributions.isFetching}
          />
        </Grid>
        <Grid item s={12} style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle1" component="span">
            tolerance:&nbsp;&nbsp;
          </Typography>
          <TextField
            variant="outlined"
            margin="dense"
            type="number"
            value={toleranceVal || ''}
            onChange={(e) => setToleranceVal(e.target.value)}
            className={classes.textField}
            label={project.units === 'us' ? 'inch' : 'cm'}
          />
        </Grid>
      </Grid>

      <section style={{ marginTop: '12px' }}>
        <SizeSpecDistributionTable
          isFetching={specDistributions.isFetching}
          data={(extendedSizes && extendedSizes[measurement]) || []}
          histogramLines={histogramLines}
          minmaxPeg={minmaxPeg}
          measurementName={measurement}
          toleranceVal={toleranceVal}
          sizesList={sizesList}
          selectedSize={selectedSize}
          setSelectedSize={setSelectedSize}
        />
      </section>
    </main>
  )
}

const mapStateToProps = (state) => {
  return {
    project: state.project.entities[state.project.selected],
    selectedSpecId: state.project.selectedSizeSpecId,
    extendedSizes: extendedSizesSelector(state),
    sizesList: sizeListSelector(state),
    specDistributions: state.sizeSpecDistributions.distributions,
    histogramLines: histogramSelector(state),
    minmaxPeg: minmaxPegSelector(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchSizeSpecDistributions: (params) =>
    dispatch(SizeSpecDistributionActions.fetchSizeSpecDistributions(params)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SizeSpecDistributions)
