import React from 'react'
// Size Spec Template,,,,,,,, Units,
//   Size,,,,,,,, Gender,
// , Min, Max, Min, Max, Min, Max,, Sizing Category Name,

export default () => {
  const template = `Spec Name,,,,,,,, Units,
Size,Measurement 1,,Measurement 2,,Measurement 3,,, Gender,
, Min, Max, Min, Max, Min, Max,, Sizing Category Name,
Size A,,,,,,,,,
  `
  return (
    <a
      href={`data:text/csv;charset=utf-8,${template}`}
      target="_blank"
      rel="noopener noreferrer"
      download="spec_template.csv"
    >
      Download CSV template
    </a>
  )
}
