import { combineReducers } from 'redux'
import {
  FETCH_FIT_COVERAGE_START,
  FETCH_FIT_COVERAGE_SUCCESS,
  FETCH_FIT_COVERAGE_FAILURE,
} from 'actions/fit_coverage'

import compare from 'reducers/fit_coverage_compare'

const isFetching = (state = false, action) => {
  switch (action.type) {
    case 'PROJECT_CLEAR':
      return false
    case FETCH_FIT_COVERAGE_START:
      return true
    case FETCH_FIT_COVERAGE_SUCCESS:
    case FETCH_FIT_COVERAGE_FAILURE:
      return false
    default:
      return state
  }
}

const data = (state = {}, action) => {
  switch (action.type) {
    case 'PROJECT_CLEAR':
    case 'SET_SIZE_SPEC':
    case 'SIZE_SPEC_UPDATED':
      return {}
    case FETCH_FIT_COVERAGE_SUCCESS:
      return action.payload
    default:
      return state
  }
}

const error = (state = null, action) => {
  switch (action.type) {
    case 'FETCH_FIT_COVERAGE_FAILURE':
      return action.error
    default:
      return state
  }
}

export default combineReducers({
  isFetching,
  data,
  error,
  compare,
})
