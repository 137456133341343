import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import CreateSpecButton from 'components/size_explorer/size_specs/list/CreateSpecButton'
import SpecsList from 'components/size_explorer/size_specs/list/SpecsListIndex'
import SpecForm from 'components/size_explorer/size_specs/forms/SpecFormIndex'

const styles = makeStyles((theme) => {
  return {
    header: {
      fontWeight: 600,
    },
    list: {
      width: '100%',
      // maxWidth: 900,
      backgroundColor: theme.palette.background.paper,
    },
  }
})

const SizeSpecs = ({ project, specsList }) => {
  const classes = styles()
  const dispatch = useDispatch()

  const [formConfig, setFormConfig] = React.useState({ spec: null, mode: null })

  const handleSpecSaved = (spec) => {
    if (formConfig.mode === 'update') {
      dispatch({
        type: 'SIZE_SPEC_UPDATED',
        payload: spec,
        projectId: project.id,
      })
    } else {
      dispatch({
        type: 'SIZE_SPEC_CREATED',
        payload: spec,
        projectId: project.id,
      })
    }

    handleFormCancel()
  }

  const handleFormCancel = () => {
    setFormConfig({ spec: null, mode: null })
  }

  const handleStartCreate = () => {
    setFormConfig({ spec: null, mode: 'create' })
  }

  const handleStartDuplicate = (spec) => {
    setFormConfig({ spec, mode: 'duplicate' })
  }

  const handleStartUpdate = (spec) => {
    setFormConfig({ spec, mode: 'update' })
  }

  if (formConfig.mode) {
    return (
      <SpecForm
        project={project}
        specsList={specsList}
        spec={formConfig.spec}
        mode={formConfig.mode}
        onCancel={handleFormCancel}
        onSave={handleSpecSaved}
      />
    )
  } else {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justify="space-between" alignItems="center">
            <Typography className={classes.header} variant="h5">
              Size Specs
            </Typography>
            <CreateSpecButton onClick={handleStartCreate} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.list}>
            <SpecsList
              onDuplicate={handleStartDuplicate}
              onEdit={handleStartUpdate}
              projectId={project.id}
              specsList={specsList}
            />
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state) => ({
  project: state.project.entities[state.project.selected],
  specsList: Object.values(
    state.project.entities[state.project.selected].sizeSpecs || {}
  ),
})

export default connect(mapStateToProps)(SizeSpecs)
