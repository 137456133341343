import React from 'react'
import { connect } from 'react-redux'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import * as SizeSpecActions from 'actions/size_specs'

import FitCoverageOverview from 'components/size_explorer/fit_coverage/Overview'
import SizeCoverage from 'components/size_explorer/fit_coverage/SizeCoverage'
import ScatterPlots from 'components/size_explorer/fit_coverage/ScatterPlots'
import SizeChart from 'components/size_explorer/fit_coverage/size_chart/SizeChartIndex'
import SizeSpecDistributions from 'components/size_explorer/fit_coverage/spec_distributions'
import ExtendedSizeChart from 'components/size_explorer/fit_coverage/ExtendedSizeChart'
import CompareSelect from 'components/size_explorer/fit_coverage/CompareSelect'
import CompareCoverageSummaryChart from 'components/size_explorer/fit_coverage/compare/CoverageSummary'
import CompareCoverageBySizeChart from 'components/size_explorer/fit_coverage/compare/CoverageBySize'
import CompareCorrelationPlots from 'components/size_explorer/fit_coverage/compare/CorrelationPlots'
import ComparisonTable from 'components/size_explorer/fit_coverage/compare/ComparisonTable'
import FitCoverageCSVDownloadButton from 'components/size_explorer/fit_coverage/size_chart/CSVDownloadButton'

import * as AudienceActions from 'actions/audience_distribution_correlation'
import * as FitCoverageActions from 'actions/fit_coverage'
import { scatterplotSelector } from 'selectors/charts'
import {
  fitCoverageTotalsSelector,
  sizeDataSelector,
} from 'selectors/fit_coverage'
import { comparableSizeSpecs } from 'selectors/size_specs'

const useStyles = makeStyles((theme) => ({
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  cardContainer: {
    padding: '20px 32px',
  },
}))

export const FitCoverageContainer = ({
  compareData,
  comparableSizeSpecs,
  isComparingSpecs,
  fitCoverage,
  fitCoverageScatterplots,
  fitCoverageSizeData,
  fitCoverageTotals,
  onFetchFitCoverage,
  onSetCompareSpec,
  project,
  selectedSpecId,
}) => {
  const classes = useStyles()
  const theme = useTheme()

  const selectedSizeSpec = project.sizeSpecs[selectedSpecId]
  const comparisonSizeSpec = project.sizeSpecs[compareData.sizeSpec.potential]

  React.useEffect(() => {
    if (
      !fitCoverage.isFetching &&
      !fitCoverage.error &&
      !fitCoverage.data.sizes
    ) {
      onFetchFitCoverage(project, selectedSpecId)
    }
  })

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h5" style={{ fontWeight: 600 }}>
            Fit Coverage {isComparingSpecs ? 'Comparison' : null}
          </Typography>
          <div className={classes.actionsContainer}>
            <CompareSelect
              compareSpecs={comparableSizeSpecs}
              disabled={false}
              onCancel={() => onSetCompareSpec(null)}
              onSubmit={(specId) =>
                onSetCompareSpec(comparableSizeSpecs[specId])
              }
              selectedSpecId={comparisonSizeSpec ? comparisonSizeSpec.id : null}
            />
            <div style={{ marginLeft: theme.spacing(1) }}>
              <FitCoverageCSVDownloadButton
                fitCoverageTotals={fitCoverageTotals}
                primaryMeasurements={selectedSizeSpec.primaryMeasurements}
                sizeData={fitCoverageSizeData}
                isLoading={fitCoverage.isFetching}
              />
            </div>
          </div>
        </Grid>
      </Grid>

      {isComparingSpecs ? (
        <Grid item xs={12}>
          <section style={{ marginTop: theme.spacing(2) }}>
            <main>
              <section>
                <Grid
                  container
                  spacing={2}
                  style={{ marginBottom: theme.spacing(1) }}
                >
                  <Grid item xs={6}>
                    <CompareCoverageSummaryChart
                      baseline={compareData.fitCoverage.baseline}
                      potential={compareData.fitCoverage.potential}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CompareCoverageBySizeChart
                      baseline={compareData.fitCoverage.baseline}
                      potential={compareData.fitCoverage.potential}
                      baselineSpec={selectedSizeSpec}
                      potentialSpec={comparisonSizeSpec}
                    />
                  </Grid>
                </Grid>
              </section>
              <section>
                <CompareCorrelationPlots
                  correlationPlot={compareData.correlation}
                  baselineSpec={selectedSizeSpec}
                  potentialSpec={comparisonSizeSpec}
                />
              </section>
            </main>
          </section>
          <section style={{ marginTop: theme.spacing(2) }}>
            <ComparisonTable
              fitCoverage={compareData.fitCoverage}
              baselineSpec={selectedSizeSpec}
              potentialSpec={comparisonSizeSpec}
            />
          </section>
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="stretch">
              <Grid item xs={6}>
                <Card className={classes.cardContainer}>
                  <FitCoverageOverview
                    fitCoverageTotals={fitCoverageTotals}
                    isLoading={fitCoverage.isFetching}
                  />
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card className={classes.cardContainer}>
                  <SizeCoverage
                    isLoading={fitCoverage.isFetching}
                    sizeData={fitCoverageSizeData}
                  />
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <ScatterPlots
              primaryMeasurements={selectedSizeSpec.primaryMeasurements}
              scatterplots={fitCoverageScatterplots}
              sizeData={fitCoverageSizeData}
            />
          </Grid>

          <Grid item xs={12}>
            <SizeChart
              fitCoverageTotals={fitCoverageTotals}
              primaryMeasurements={selectedSizeSpec.primaryMeasurements}
              fitCoverageData={fitCoverage.data}
              sizeData={fitCoverageSizeData}
              isLoading={fitCoverage.isFetching}
            />
          </Grid>

          <Grid item xs={12} style={{ marginTop: theme.spacing(4) }}>
            <SizeSpecDistributions />
          </Grid>

          <Grid item xs={12} style={{ marginTop: theme.spacing(4) }}>
            <ExtendedSizeChart />
          </Grid>
        </>
      )}
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  compareData: state.fitCoverage.compare,
  comparableSizeSpecs: comparableSizeSpecs(state),
  isComparingSpecs: state.fitCoverage.compare.sizeSpec.potential !== null,
  fitCoverage: state.fitCoverage,
  fitCoverageScatterplots: scatterplotSelector('fitCoverage')(state),
  fitCoverageSizeData: sizeDataSelector(state),
  fitCoverageTotals: fitCoverageTotalsSelector(state),
  project: state.project.entities[state.project.selected],
  selectedSpecId: state.project.selectedSizeSpecId,
})

const mapDispatchToProps = (dispatch) => ({
  onFetchFitCoverage: (project, selectedSpecId) => {
    // fetch fit coverage data for Context Bar
    dispatch(
      FitCoverageActions.fetchFitCoverage({
        units: project.units,
        population: project.population,
        audienceFilter: project.audience,
        sizeDefs: project.sizeSpecs[selectedSpecId].sizeDefs,
      })
    )

    // also grab scatterplot data
    dispatch(
      AudienceActions.fetchAllCorrelationDistributions({
        units: project.units,
        population: project.population,
        audienceFilter: project.audience,
        measurements: project.sizeSpecs[selectedSpecId].primaryMeasurements,
      })
    )
  },
  onSetCompareSpec: (spec) => {
    if (spec) {
      dispatch(SizeSpecActions.setSizeSpecCompareMode(spec))
    } else {
      dispatch(SizeSpecActions.clearSizeSpecCompareMode())
    }
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FitCoverageContainer)
