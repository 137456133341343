import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import PieChart from 'components/size_explorer/charts/PieChart'
import LoadingSpinner from 'components/size_explorer/lib/LoadingSpinner'
import Reload from 'components/size_explorer/lib/Reload'
import { parseCamelCase } from 'lib/format'

export default ({ isLoading, fitCoverageTotals: { sizes, totalCoverage } }) => {
  const pieChartData = [
    { type: 'uncovered', count: 100 - totalCoverage },
    { type: 'covered', count: totalCoverage },
  ]

  const styles = {
    fill: {
      covered: '#3033ff',
      uncovered: '#888790de',
    },
  }

  return (
    <div>
      <Typography variant="subtitle2" align="center">
        <b>Total Coverage</b>
      </Typography>
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ height: 260 }}
      >
        {isLoading ? (
          <LoadingSpinner />
        ) : sizes && sizes.length !== 0 ? (
          <Grid container direction="row" spacing={0}>
            <Grid container alignItems="center" style={{ width: '40%' }}>
              <div>
                <div
                  style={{ color: styles.fill.covered, marginBottom: '1rem' }}
                >
                  <div style={{ fontWeight: 600 }}>Covered</div>
                  <div
                    style={{ fontWeight: 600, fontSize: '2.5rem' }}
                  >{`${totalCoverage}%`}</div>
                </div>
                <div style={{ color: styles.fill.uncovered }}>
                  <div style={{ fontWeight: 600 }}>Not covered</div>
                  <div style={{ fontWeight: 600, fontSize: '2.5rem' }}>{`${(
                    100 - totalCoverage
                  ).toFixed(1)}%`}</div>
                </div>
              </div>
            </Grid>
            <div style={{ width: '60%' }}>
              <PieChart
                data={pieChartData}
                styles={styles}
                tooltipContent={(d) => {
                  return (
                    <div
                      className="tooltip-content"
                      style={{
                        border: '1px #ccc solid',
                        borderRadius: '2px',
                        background: 'rgba(255, 255, 255, 0.8)',
                        padding: '0 12px',
                      }}
                    >
                      <p style={{ whiteSpace: 'nowrap', fontWeight: '700' }}>
                        {`${parseCamelCase(d.column)}`}
                      </p>
                      <p
                        style={{ whiteSpace: 'nowrap' }}
                      >{`Coverage: ${d.count}%`}</p>
                    </div>
                  )
                }}
              />
            </div>
          </Grid>
        ) : (
          <Reload errorMessage="Failed to load size coverage" />
        )}
      </Grid>
    </div>
  )
}
