import React from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import ScansIcon from '@material-ui/icons/AccessibilityNew'
import Snackbar from '@material-ui/core/Snackbar'

import AngleSelect from 'components/lib/AngleSelect'
import AvatarCollection from 'components/avatars/AvatarCollection'
import AvatarCollectionPurchaseActions from 'components/avatars/AvatarCollectionPurchaseActions'
import * as AvatarsUtil from 'lib/avatars'
import Notification from 'components/lib/Notification'
import PageContent from 'components/lib/PageContent'
import PageTitle from 'components/lib/PageTitle'
import SizingRunModal from 'components/avatars/sizing/modal/SizingSearchModal'

const styles = makeStyles((theme) => ({
  avatars: {
    marginTop: theme.spacing(2),
  },
  emptyState: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    minHeight: '400px',
    textAlign: 'center',
  },
  legend: {
    marginLeft: theme.spacing(2),
  },
  select: {
    minWidth: 400,
  },
}))

const AvatarSizingIndex = ({ populations, projects }) => {
  const classes = styles()

  const [angle, setAngle] = React.useState('000')
  const [modalOpen, setModalOpen] = React.useState(false)
  const [selectedAvatars, setSelectedAvatars] = React.useState({})
  const [selectedSpecOption, setSelectedSpecOption] = React.useState(null)
  const [snackbar, setSnackbar] = React.useState(null)

  // this should be a single collection for our selected project / spec
  const collection = React.useMemo(() => {
    if (selectedSpecOption) {
      const project = projects[selectedSpecOption.projectId]
      const population = populations[project.population]
      const collection = AvatarsUtil.makeAvatars(
        project,
        population,
        selectedSpecOption.value
      )
      if (collection) {
        return collection
      } else {
        return { avatars: [] }
      }
    } else {
      return { avatars: [] }
    }
  }, [populations, projects, selectedSpecOption])

  // The population associated with the project / spec we are working with (if selected)
  const selectedPopulation = selectedSpecOption
    ? populations[projects[selectedSpecOption.projectId].population]
    : null

  // The size spec we are working with (if selected)
  const selectedSpec = selectedSpecOption
    ? projects[selectedSpecOption.projectId].sizeSpecs[selectedSpecOption.value]
    : null

  // The size spec options, grouped by project
  const specOptions = React.useMemo(() => {
    if (projects) {
      return Object.values(projects)
        .map((project) => {
          if (project.sizeSpecs) {
            return {
              label: project.name,
              options: Object.values(project.sizeSpecs).map((spec) => ({
                label: spec.name,
                projectId: project.id, // we put this in here to make referencing selected spec & project simple
                value: spec.id,
              })),
            }
          } else {
            return null
          }
        })
        .filter((opt) => opt !== null)
    } else {
      return []
    }
  }, [projects])

  const handleAvatarsPurchased = (purchased) => {
    setSelectedAvatars({})
    setSnackbar(
      `purchase requested for ${purchased.length} ${
        purchased.length > 1 ? 'avatars' : 'avatar'
      }`
    )
  }

  const handleSelectSpec = (choice) => {
    setSelectedSpecOption(choice)
  }

  return (
    <div>
      <PageTitle
        title="Sizing Search"
        adornment={
          <div className={classes.select}>
            <Select
              options={specOptions}
              value={selectedSpecOption}
              onChange={handleSelectSpec}
              styles={{
                container: (provided, state) => ({
                  ...provided,
                  maxWidth: '400px',
                }),
                groupHeading: (provided, state) => ({
                  ...provided,
                  color: '#aaa',
                  fontWeight: 600,
                }),
                menuList: (provided, state) => ({
                  ...provided,
                  zIndex: 999,
                }),
                valueContainer: (provided, state) => ({
                  ...provided,
                  fontWeight: 600,
                }),
              }}
              isSearchable={false}
              // isDisabled={Object.values(project.sizeSpecs).length === 0}
              placeholder="Project & Size Spec"
            />
          </div>
        }
      />

      <PageContent isLoading={!projects}>
        <Grid container alignItems="center">
          <Grid item>
            <AvatarCollectionPurchaseActions
              onPurchase={handleAvatarsPurchased}
              selectedAvatars={selectedAvatars}
            />
          </Grid>
          <Grid item style={{ marginLeft: 20 }}>
            <Button
              variant="contained"
              color="primary"
              disabled={!selectedSpecOption}
              onClick={() => setModalOpen(true)}
              startIcon={<ScansIcon />}
            >
              Choose Avatars
            </Button>
          </Grid>
          <Grid item style={{ flex: 1, marginLeft: 20 }}>
            <span>
              <Notification />{' '}
              <Typography component="span" variant="caption">
                indicates variant avatar for size
              </Typography>
            </span>
            <br />
            <span>
              <Notification style={{ background: '#2b907f' }} />{' '}
              <Typography component="span" variant="caption">
                avatar has been purchased
              </Typography>
            </span>
          </Grid>
          <Grid item style={{ minWidth: 250, marginLeft: 'auto' }}>
            <AngleSelect
              angle={angle}
              onAngleSelect={(angle) => setAngle(angle)}
            />
          </Grid>
        </Grid>

        {selectedSpec && collection && collection.avatars.length > 0 ? (
          <div className={classes.avatars}>
            <AvatarCollection
              angle={angle}
              collections={[collection]}
              onSelect={(selected) => setSelectedAvatars(selected)}
              selectedAvatars={selectedAvatars}
            />
          </div>
        ) : selectedSpec && collection ? (
          <div className={classes.emptyState}>
            <Typography variant="h5" style={{ lineHeight: 2 }}>
              You have not chosen any avatars yet. <br /> Use the Choose Avatars
              button to select avatars
            </Typography>
          </div>
        ) : (
          <div className={classes.emptyState}>
            <Typography variant="h5">
              Select one of your Size Specs from the dropdown to get started
            </Typography>
          </div>
        )}
      </PageContent>

      {modalOpen ? (
        <SizingRunModal
          onClose={() => setModalOpen(false)}
          open={modalOpen}
          population={selectedPopulation}
          project={projects[selectedSpecOption.projectId]}
          sizeSpec={selectedSpec}
        />
      ) : null}

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={Boolean(snackbar)}
        autoHideDuration={6000}
        onClose={() => setSnackbar(null)}
        message={snackbar}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setSnackbar(null)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  populations: state.populations,
  projects: state.project.entities,
})

export default connect(mapStateToProps)(AvatarSizingIndex)
