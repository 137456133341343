/* global Blob */

import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import FileDownloadIcon from 'mdi-material-ui/FileDownload'

const CSVDownloadButton = ({ fileName, canDownload, createCSVContent }) => {
  const handleCsvDownload = () => {
    const downloadAnchor = document.createElement('a')
    const blob = new Blob([createCSVContent()], {
      type: 'text/csv;charset=utf-8;',
    }) // eslint-disable-line no-undef
    const url = URL.createObjectURL(blob)
    downloadAnchor.href = url
    downloadAnchor.setAttribute('download', fileName)
    downloadAnchor.click()
  }

  return (
    <Tooltip title={canDownload ? 'Export CSV' : 'Export (awaiting data)'}>
      <span>
        {' '}
        {/* need this span so that tooltip works on disabled icon */}
        <IconButton onClick={handleCsvDownload} disabled={!canDownload}>
          <FileDownloadIcon />
        </IconButton>
      </span>
    </Tooltip>
  )
}

export default CSVDownloadButton
