import _ from 'lodash'
import { combineReducers } from 'redux'

import avatars from 'reducers/avatars'
import project from 'reducers/project'
import context from 'reducers/context'
import audienceDistribution from 'reducers/audience_distribution'
import fitCoverage from 'reducers/fit_coverage'
import session from 'reducers/session'
import sizeSpecDistributions from 'reducers/size_spec_distributions'

const detailsAngle = (state = '', action) => {
  switch (action.type) {
    case 'DETAILS_ANGLE':
      return action.view
    default:
      return state
  }
}

const detailsSort = (state = 'measurement', action) => {
  switch (action.type) {
    case 'DETAILS_SORT':
      return action.sort
    default:
      return state
  }
}

const fullscreen = (state = false, action) => {
  switch (action.type) {
    case 'TOGGLE_FULLSCREEN':
      return !state
    default:
      return state
  }
}

const populations = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_POPULATION_LIST_SUCCESS':
      return _.keyBy(action.payload, 'id')
    default:
      return state
  }
}

const profile = (state = null, action) => {
  switch (action.type) {
    case 'AUTH_PROFILE_SUCCESS':
      return action.profile
    case 'AUTH_PROFILE_FAIL':
      return null
    default:
      return state
  }
}

// this is a cache of the fetched binary data for a specific scan image (as png)
const scanImages = (state = {}, action) => {
  switch (action.type) {
    case 'SCANPNG_LOADING':
      return { ...state, [`${action.pkgId}-${action.angle}`]: 'working' }
    case 'SCANPNG_FAIL':
      return { ...state, [`${action.pkgId}-${action.angle}`]: 'error' }
    case 'SCANPNG_CACHE':
      return { ...state, [`${action.pkgId}-${action.angle}`]: action.scanPng }
    default:
      return state
  }
}

const selectedScanTree = (state = null, action) => {
  switch (action.type) {
    case 'SEARCH_START':
      return null
    case 'SCAN_TREE_SELECTED':
      return action.scan
    default:
      return state
  }
}

const selectedScanGrid = (state = {}, action) => {
  switch (action.type) {
    case 'SCAN_GRID_SELECTED':
      return action.scan
    default:
      return state
  }
}

export default combineReducers({
  avatars,
  charts: audienceDistribution,
  context,
  fullscreen,
  detailsAngle,
  detailsSort,
  fitCoverage,
  populations,
  profile,
  project,
  scanImages,
  selectedScanGrid,
  selectedScanTree,
  session,
  sizeSpecDistributions,
})
