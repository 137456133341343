import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Slide from '@material-ui/core/Slide'
import clsx from 'clsx'
import { setProject } from 'actions/projects'
import LoadingSpinner from 'components/size_explorer/lib/LoadingSpinner'
import AudienceIndex from 'components/size_explorer/audience/AudienceIndex'
import SizeSpecs from 'components/size_explorer/size_specs/SizeSpecsIndex'
import FitCoverage from 'components/size_explorer/fit_coverage'
import VisualizeIndex from 'components/size_explorer/visualize/VisualizeIndex'
import Presentation from 'components/size_explorer/presentation/PresentationIndex'
import Navbar from 'components/size_explorer/Navbar'
import ContextBarContainer from 'components/size_explorer/ContextBarContainer'

const useStyles = makeStyles((theme) => ({
  loading: {
    height: 'calc(100vh - 64px)',
  },
  nav: {
    position: 'fixed',
    width: 200,
  },
  section: {
    padding: '28px 32px',
    position: 'absolute',
    left: 200,
    width: 'calc(100vw - 400px)',
    height: 'calc(100vh - 48px)',
    overflowY: 'scroll',
  },
  context: {
    position: 'fixed',
    width: 200,
    right: 0,
  },
  fullWidth: {
    width: '100%',
    left: 0,
  },
  hidden: {
    display: 'none',
  },
}))

function SizeExplorerIndex({
  fullscreen,
  projects,
  setProject,
  section,
  selectedSizeSpecId,
  setSection,
}) {
  const classes = useStyles()
  const { id } = useParams()
  const project = projects[id]

  const [isLoading, setIsLoading] = React.useState(true)

  useEffect(() => {
    if (project) {
      setIsLoading(false)
      setProject(project)
    }
  }, [project])

  return (
    <main style={{ width: '100%', height: '100%' }}>
      {isLoading ? (
        <Grid
          className={classes.loading}
          container
          justify="center"
          alignItems="center"
        >
          <LoadingSpinner />
        </Grid>
      ) : (
        <Slide in={Boolean(project)} direction="up">
          <Grid container style={{ width: '100%', height: '100%' }}>
            <Grid className={fullscreen ? classes.hidden : classes.nav} item>
              <Navbar
                onChange={(section) => setSection(section)}
                project={project}
                section={section}
                selectedSizeSpecId={selectedSizeSpecId}
              />
            </Grid>

            <Grid
              className={clsx(
                classes.section,
                fullscreen ? classes.fullWidth : null
              )}
              item
            >
              {section === 'audience' ? (
                <AudienceIndex />
              ) : section === 'specs' ? (
                <SizeSpecs />
              ) : section === 'fitcoverage' ? (
                <FitCoverage />
              ) : section === 'visualize' ? (
                <VisualizeIndex />
              ) : section === 'presentation' ? (
                <Presentation />
              ) : null}
            </Grid>

            <Grid
              className={fullscreen ? classes.hidden : classes.context}
              item
            >
              <ContextBarContainer />
            </Grid>
          </Grid>
        </Slide>
      )}
    </main>
  )
}

const mapStateToProps = (state) => ({
  fullscreen: state.fullscreen,
  projects: state.project.entities,
  section: state.project.section,
  selectedSizeSpecId: state.project.selectedSizeSpecId,
})

const mapDispatchToProps = (dispatch) => ({
  setProject: (project) => dispatch(setProject(project)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SizeExplorerIndex)
