import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import CloseIcon from '@material-ui/icons/Close'
import _ from 'lodash'

import MeasurementsTable from 'components/size_explorer/visualize/avatars/modal/MeasurementsTable'
import SizeDefTable from 'components/size_explorer/visualize/avatars/modal/SizeDefTable'
import GlobalSwitch from 'components/size_explorer/visualize/avatars/modal/GlobalSwitch'
import AngleSelect from 'components/lib/AngleSelect'
import ScanImage from 'components/lib/ScanImage'
import StarIcon from 'components/size_explorer/visualize/lib/StarIcon'
import Notification from 'components/lib/Notification'

const useStyles = makeStyles((theme) => ({
  content: {
    width: 1200,
    paddingBottom: theme.spacing(3),
  },
  infoContainer: {
    margin: '0 auto',
    maxWidth: 600,
  },
  imageContainer: {
    marginBottom: theme.spacing(2),
  },
  switchContainer: {
    width: 'calc(100% - 70px)',
    margin: '0 auto',
    marginBottom: theme.spacing(2),
  },
  angleSelect: {
    // marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  bodiesContainer: {
    width: 'calc(100% - 70px)',
  },
  closeIconContainer: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    cursor: 'pointer',
  },
  icon: {
    cursor: 'pointer',
    alignSelf: 'center',
  },
  starIcon: {
    margin: theme.spacing(1),
  },
}))

const NUM_SCANS = 12
const SCANS_LIMIT = 24
const LAST_PAGE = 1 // pages start at 0, so this is 2 pages

const ReferenceAvatarModal = ({
  sizeSpec,
  searchResults,
  selectedSize,
  onStarSave,
  fetchingResults,
  open,
  onClose,
}) => {
  const classes = useStyles()

  const [page, setPage] = React.useState(0)
  const [selectedPkgId, setSelectedPkgId] = React.useState(null)
  const [suggestedPackageId, setSuggestedPackageId] = React.useState(null)
  const [displayGlobal, toggleDisplayGlobal] = React.useState(true)
  const [angle, setAngle] = React.useState('000')

  const referencePackageId = React.useMemo(() => {
    const def = sizeSpec.sizeDefs.find(
      (def) => String(def.size) === String(selectedSize)
    )
    return def && def.referencePkgId
  }, [sizeSpec, selectedSize])

  React.useEffect(() => {
    setSelectedPkgId(referencePackageId)
  }, [referencePackageId])

  const bodies = React.useMemo(() => {
    const result = searchResults[selectedSize]
    if (!result) {
      const result = []
      for (var i = 0; i < NUM_SCANS; i++) {
        result.push({ packageId: null })
      }
      return result
    } else if (!displayGlobal) {
      return result.geo.scans
        .map(({ packageId, ...measurements }) => ({ packageId, measurements }))
        .slice(0, SCANS_LIMIT)
    } else if (displayGlobal) {
      if (!result || !result.global || !result.global.scans) {
        return []
      } else {
        const scans = result.global.scans
          .map(({ packageId, ...measurements }) => {
            return {
              packageId,
              measurements,
              isGlobal: !_.find(
                result.geo.scans,
                (scan) => scan.packageId === packageId
              ),
            }
          })
          .slice(0, SCANS_LIMIT)

        if (scans[0]) {
          setSuggestedPackageId(scans[0].packageId)
        }

        return displayGlobal ? scans : scans.filter(({ isGlobal }) => !isGlobal)
      }
    } else {
      return []
    }
  }, [searchResults, selectedSize, displayGlobal])

  const scan = React.useMemo(() => {
    return _.find(bodies, ({ packageId }) => packageId === selectedPkgId)
  }, [bodies, selectedPkgId])

  const onNextPage = () => {
    if (page < LAST_PAGE) {
      setPage(page + 1)
    }
  }

  const onPrevPage = () => {
    if (page > 0) {
      setPage(page - 1)
    }
  }

  const handleScanClick = (pkgId) => {
    setSelectedPkgId(pkgId)
  }

  const handleGlobalToggle = () => {
    toggleDisplayGlobal(!displayGlobal)
  }

  const handleModalClose = () => {
    // reset modal state
    setPage(0)
    toggleDisplayGlobal(true)

    onClose()
  }

  const handleAngleSelect = (angle) => {
    setAngle(angle)
  }

  return (
    <Dialog
      onClose={handleModalClose}
      aria-labelledby="scan-profile"
      open={open}
      maxWidth="xl"
    >
      <DialogTitle id="scan-profile" align="center">
        Select reference avatar for size {selectedSize}
      </DialogTitle>
      <div className={classes.closeIconContainer}>
        <CloseIcon onClick={handleModalClose} />
      </div>
      <DialogContent className={classes.content}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <div className={classes.angleSelect}>
              <AngleSelect
                angle={angle}
                onAngleSelect={handleAngleSelect}
                padRight
              />
            </div>
            <div className={classes.imageContainer}>
              <Card>
                <ScanImage
                  pkgId={selectedPkgId}
                  angle={angle}
                  upperLeftAdornment={
                    selectedPkgId && (
                      <div className={classes.starIcon}>
                        <StarIcon
                          referencePackageId={referencePackageId}
                          packageId={selectedPkgId}
                          onClick={onStarSave}
                        />
                      </div>
                    )
                  }
                />
              </Card>
            </div>
            <div style={{ marginBottom: 16 }}>
              <SizeDefTable sizeSpec={sizeSpec} selectedSize={selectedSize} />
            </div>
            {searchResults[selectedSize] && (
              <MeasurementsTable
                means={
                  displayGlobal
                    ? searchResults[selectedSize].global.means
                    : searchResults[selectedSize].geo.means
                }
                measurements={scan && scan.measurements}
              />
            )}
          </Grid>
          <Grid item xs={9}>
            <div className={classes.switchContainer}>
              <GlobalSwitch
                displayGlobal={displayGlobal}
                onGlobalToggle={handleGlobalToggle}
              />
            </div>
            <Grid container style={{ height: 'calc(100% - 46px)' }}>
              <ArrowLeftIcon
                className={classes.icon}
                fontSize="large"
                color={page === 0 ? 'disabled' : 'inherit'}
                onClick={onPrevPage}
              />
              <Grid className={classes.bodiesContainer} container spacing={2}>
                {bodies.length ? (
                  bodies
                    .slice(page * NUM_SCANS, (page + 1) * NUM_SCANS)
                    .map(({ packageId, isGlobal }, idx) => {
                      return (
                        <Grid
                          item
                          key={`${selectedSize}-alternate-${idx}`}
                          xs={3}
                        >
                          <Card
                            raised={packageId && packageId === selectedPkgId}
                            onClick={() => handleScanClick(packageId)}
                          >
                            <ScanImage
                              angle={angle}
                              isFetching={fetchingResults}
                              pkgId={packageId}
                              upperLeftAdornment={
                                packageId &&
                                packageId === referencePackageId && (
                                  <div className={classes.starIcon}>
                                    <StarIcon
                                      referencePackageId={referencePackageId}
                                      packageId={selectedPkgId}
                                    />
                                  </div>
                                )
                              }
                              lowerRightAdornment={
                                <div
                                  className={classes.starIcon}
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  {suggestedPackageId &&
                                    packageId === suggestedPackageId && (
                                      <Notification title="This avatar is the suggested centroid body. Please save a reference avatar" />
                                    )}
                                  {isGlobal && (
                                    <Notification
                                      title="This avatar is from the global population"
                                      style={{ background: '#2b907f' }}
                                    />
                                  )}
                                </div>
                              }
                            />
                          </Card>
                        </Grid>
                      )
                    })
                ) : (
                  <Grid container justify="center" alignItems="center">
                    <Typography variant="subtitle1">
                      No scans available for size {selectedSize}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <ArrowRightIcon
                className={classes.icon}
                fontSize="large"
                color={
                  (LAST_PAGE && page === LAST_PAGE) || fetchingResults
                    ? 'disabled'
                    : 'inherit'
                }
                onClick={onNextPage}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default ReferenceAvatarModal
