import React from 'react'
import Papa from 'papaparse'
import moment from 'moment'

import { getMeasurementLabel } from 'lib/measurements'

import CSVDownloadButton from 'components/size_explorer/lib/CSVDownloadButton'

const makeFitCoverageCsv = (sizeData, coverageData, primaryMeasurements) => {
  const measurements = primaryMeasurements
    .map((measurement) => {
      return [
        [
          `${getMeasurementLabel(measurement)} (Min)`,
          ...sizeData.map((data) => data.measurements[measurement].min),
        ],
        [
          `${getMeasurementLabel(measurement)} (Mid)`,
          ...sizeData.map(
            (data) =>
              (data.measurements[measurement].min +
                data.measurements[measurement].max) /
              2
          ),
        ],
        [
          `${getMeasurementLabel(measurement)} (Max)`,
          ...sizeData.map((data) => data.measurements[measurement].max),
        ],
      ]
    })
    .flat()

  const json = [
    ['', ...sizeData.map(({ size }) => size)],
    ...measurements,
    ['Scans', ...coverageData.map(({ count }) => count)],
    ['Coverage', ...coverageData.map(({ coverage }) => coverage)],
  ]

  return Papa.unparse(json)
}

export default ({
  fitCoverageTotals,
  primaryMeasurements,
  sizeData,
  isLoading,
}) => {
  const createCSVContent = () => {
    return makeFitCoverageCsv(
      sizeData,
      fitCoverageTotals.sizes,
      primaryMeasurements
    )
  }

  return (
    <CSVDownloadButton
      fileName={`fit-coverage-${moment().format()}.csv`}
      canDownload={!isLoading}
      createCSVContent={createCSVContent}
    />
  )
}
