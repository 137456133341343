import React from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Typography from '@material-ui/core/Typography'
import { parseCamelCase } from 'lib/format'

// const pvh = ['chestBustGirth', 'naturalWaistGirth', 'hipSeatGirth', 'midNeckGirth']
const PRIMARY_MEASUREMENTS = [
  'chestBustGirth',
  'naturalWaistGirth',
  'hipSeatGirth',
]

export default ({ audienceFilter, units, ...props }) => {
  return (
    <Grid container>
      <Typography
        variant="subtitle1"
        gutterBottom
        style={{ fontWeight: 600, marginBottom: '1rem' }}
      >
        Core Body Measurements
      </Typography>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          {PRIMARY_MEASUREMENTS.map((measurement) => {
            // The switch statements feels a little hacky. Any suggestion for improvement?
            const value = (measurement, minmax) => {
              const measurementMinMax =
                measurement.toString() + minmax.toString() // toString() is not required

              switch (measurementMinMax) {
                case 'chestBustGirthMin': // please check name (chestBustWaist vs. chestBustGirth)
                  return audienceFilter.chestBustGirthMin || ''
                case 'chestBustGirthMax':
                  return audienceFilter.chestBustGirthMax || ''
                case 'naturalWaistGirthMin':
                  return audienceFilter.naturalWaistGirthMin || ''
                case 'naturalWaistGirthMax':
                  return audienceFilter.naturalWaistGirthMax || ''
                case 'hipSeatGirthMin':
                  return audienceFilter.hipSeatGirthMin || ''
                case 'hipSeatGirthMax':
                  return audienceFilter.hipSeatGirthMax || ''
                case 'midNeckGirthMin':
                  return audienceFilter.midNeckGirthMin || ''
                case 'midNeckGirthMax':
                  return audienceFilter.midNeckGirthMax || ''
                default:
                  return ''
              }
            }

            const onChange = (measurement, minmax) => {
              const measurementMinMax =
                measurement.toString() + minmax.toString()

              switch (measurementMinMax) {
                case 'chestBustGirthMin':
                  return (e) => props.setBustMin(e.target.value)
                case 'chestBustGirthMax':
                  return (e) => props.setBustMax(e.target.value)
                case 'naturalWaistGirthMin':
                  return (e) => props.setWaistMin(e.target.value)
                case 'naturalWaistGirthMax':
                  return (e) => props.setWaistMax(e.target.value)
                case 'hipSeatGirthMin':
                  return (e) => props.setHipsMin(e.target.value)
                case 'hipSeatGirthMax':
                  return (e) => props.setHipsMax(e.target.value)
                case 'midNeckGirthMin':
                  return (e) => props.setMidNeckGirthMin(e.target.value)
                case 'midNeckGirthMax':
                  return (e) => props.setMidNeckGirthMax(e.target.value)
                default:
                  return () => {}
              }
            }

            return (
              <Grid
                key={`primaryMeasurements-${measurement}`}
                item
                xs={12}
                md={8}
                lg={6}
                xl={4}
              >
                <FormControl>
                  <FormLabel style={{ whiteSpace: 'nowrap' }}>
                    {parseCamelCase(measurement)}{' '}
                    <small>{units === 'metric' ? '(cm)' : '(inch)'}</small>
                  </FormLabel>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <TextField
                        id={`input-${measurement}Min`}
                        label="Min"
                        // value={audienceFilter.chestBustGirthMin || ''}
                        value={value(measurement, 'Min')}
                        onChange={onChange(measurement, 'Min')}
                        type="number"
                        variant="outlined"
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id={`input-${measurement}Max`}
                        label="Max"
                        value={value(measurement, 'Max')}
                        onChange={onChange(measurement, 'Max')}
                        type="number"
                        variant="outlined"
                        margin="dense"
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    </Grid>
  )
}
